import { Editor } from 'components/Editor/Editor'
import { EditorRHF } from 'components/Editor/EditorRHF'
import FileContainer from 'components/FileContainer/FileContainer'
import { INote } from 'models/contact_companies'
import { useRef, useState } from 'react'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import { CgAttachment } from 'react-icons/cg'
import { MdClose } from 'react-icons/md'
import { toast } from 'react-toastify'
import apiService from 'services/api'
import { API_HOST } from 'utils/http-client'
const ALLOWED_FILE_EXTENSIONS = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'svg',
  'webp',
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
]
const ALLOWED_FILE_SIZE = 4 * 1024 * 1024

interface NoteManagerProps<T extends FieldValues> {
  control: Control<T>
  noteName: Path<T>
  attachmentsName: Path<T>
  label?: string
  placeholder?: string
  existingNote?: INote
  viewMode?: boolean
  editorClassName?: string
}

const AttachmentContainer = ({
  file,
  handleRemoveFile,
  viewMode,
  href,
}: {
  file: { name: string }
  handleRemoveFile?: () => void
  viewMode?: boolean
  href?: string
}) => {
  return (
    <FileContainer
      file={file}
      RemoveFileIcon={viewMode ? undefined : MdClose}
      noOutline
      handleRemoveFile={handleRemoveFile}
      href={href}
    />
  )
}

export const NoteManager = <T extends FieldValues>(
  props: NoteManagerProps<T>
) => {
  const {
    control,
    noteName,
    attachmentsName,
    label,
    placeholder,
    existingNote,
    viewMode,
    editorClassName = 'h-28',
  } = {
    placeholder: 'Start writing note here',
    label: 'New Note',
    ...props,
  }

  const api = apiService()

  const [existingAttachments, setExistingAttachments] = useState(
    existingNote?.attachments
  )

  const {
    field: { onChange: attachmentsOnChange, value: attachmentsValue },
  } = useController({ name: attachmentsName, control }) as {
    field: { onChange: (value: File[]) => void; value: File[] }
  }

  const inputRef = useRef<HTMLInputElement>(null)

  const openFileSelect = () => {
    inputRef.current?.click()
  }

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const files = e.target.files
    if (!files) return
    const filesArr = Array.from(files)
    const invalidFilesExtensions = filesArr.filter(
      (file) =>
        !ALLOWED_FILE_EXTENSIONS.includes(
          file.name.split('.').pop()?.toLowerCase() || ''
        )
    )
    if (invalidFilesExtensions.length > 0) {
      toast.error(
        `Only the following file types are allowed: ${ALLOWED_FILE_EXTENSIONS.join(
          ', '
        )}`
      )
      return
    }
    const invalidFilesSize = filesArr.filter(
      (file) => file.size > ALLOWED_FILE_SIZE
    )
    if (invalidFilesSize.length > 0) {
      toast.error(
        `The maximum file size allowed is ${ALLOWED_FILE_SIZE / 1024 / 1024}MB`
      )
      return
    }
    attachmentsOnChange([...attachmentsValue, ...(filesArr as File[])])
    e.target.value = '' // reset input value to allow re-selecting the same file
  }

  const handleRemoveExistingAttachment = async (index: number) => {
    if (!existingAttachments) return
    const attachment = existingAttachments[index]
    if (attachment.id && existingNote?.id) {
      await api.deleteNoteAttachment(existingNote.id, attachment.id)
    }
    setExistingAttachments((existingAttachments) =>
      existingAttachments?.filter((_, i) => i !== index)
    )
  }

  return (
    <div>
      {viewMode ? (
        <Editor
          value={existingNote?.content || ''}
          readOnly
          disableToolbar
          noBorder
        />
      ) : (
        <EditorRHF
          name={noteName}
          className={editorClassName}
          control={control}
          label={label}
          placeholder={placeholder}
          additionalToolbarButtons={[
            <CgAttachment
              key="attachments"
              size={24}
              className="cursor-pointer p-1 w-8 hover:text-[#1E734C]"
              onClick={openFileSelect}
            />,
          ]}
        />
      )}

      <div className="mt-1 gap-1 flex flex-column">
        {existingAttachments?.map((attachment, index) => {
          const href = attachment?.file?.startsWith('/')
            ? `${API_HOST}${attachment.file}`
            : attachment.file
          return (
            <AttachmentContainer
              key={index}
              file={{ name: attachment.original_filename }}
              handleRemoveFile={handleRemoveExistingAttachment.bind(
                null,
                index
              )}
              viewMode={viewMode}
              href={href}
            />
          )
        })}
        {!viewMode &&
          attachmentsValue.map((file: File, index) => (
            <AttachmentContainer
              key={index}
              file={file}
              handleRemoveFile={() => {
                const newAttachments = attachmentsValue.filter(
                  (_, i) => i !== index
                )
                attachmentsOnChange(newAttachments)
              }}
            />
          ))}
      </div>

      {!viewMode && (
        <input
          type="file"
          hidden
          multiple
          ref={inputRef}
          onChange={handleFileSelect}
          accept={ALLOWED_FILE_EXTENSIONS.join(',')}
        />
      )}
    </div>
  )
}
