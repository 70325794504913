import { TextInput } from 'components/FormUtilsV2/TextInput/text-input'
import { ChevronDown, ChevronUp, Search, X } from 'lucide-react'
import { Cuisine50Options } from 'models/cuisine_50'
import React, { useEffect, useMemo, useState } from 'react'
import { Control, useWatch } from 'react-hook-form'
import SimpleBar from 'simplebar-react'
import styled from 'styled-components/macro'
import BaseStep from './BaseStep'
import { CuisineSortDropdown } from './CuisineSortDropdown'
import { useProductModalContext } from './ProductModalContext'
import * as S from './styles'

const CuisineInputRow = React.memo(
  ({
    cuisine,
    control,
    defaultValue,
    unit,
    onModified,
    isModified,
  }: {
    cuisine: (typeof Cuisine50Options)[0]
    control: Control<any>
    defaultValue: number
    unit: string
    onModified: (cuisineId: string, value: number) => void
    isModified: boolean
  }) => {
    // Only track user-initiated changes
    const handleValueChange = (value: number) => {
      if (value !== defaultValue) {
        onModified(cuisine.value, value)
      }
    }

    return (
      <CuisineRow className="flex justify-between">
        <div className="flex items-center justify-between flex-1 mr-4">
          <span>{cuisine.label}</span>
          {isModified && <S.ModifiedBadge>Modified</S.ModifiedBadge>}
        </div>
        <TextInput
          noMargin
          name={`cuisine_sales.${cuisine.value}`}
          control={control}
          type="number"
          min={0}
          placeholder={defaultValue?.toString() || '0'}
          suffix={unit || 'lbs'}
          className="w-32"
          onChange={handleValueChange}
        />
      </CuisineRow>
    )
  }
)

CuisineInputRow.displayName = 'CuisineInputRow'

const SortIndicator = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 2px 8px;
  background-color: #f3f4f6;
  border-radius: 4px;
  font-size: 0.75rem;
  color: #6b7280;
`

const ClearButton = styled.button`
  display: flex;
  align-items: center;
  padding: 2px;
  color: #6b7280;
  &:hover {
    color: #374151;
  }
`

export function SalesPotentialStep({ onBack }: { onBack: () => void }) {
  const {
    methods: { handleClose, espForm, productForm, onSubmit },
    state: { selectedProductID },
  } = useProductModalContext()

  const {
    control,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = espForm

  // Add loading state for submit button
  const [isSubmitting, setIsSubmitting] = useState(false)

  // Handle submit with loading state
  const handleSubmit = async () => {
    setIsSubmitting(true)
    await onSubmit()
    setIsSubmitting(false)
  }

  const [isCuisineExpanded, setIsCuisineExpanded] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [sortOption, setSortOption] = useState<
    'overrides' | 'asc' | 'desc' | null
  >(null)

  // Initialize modifiedCuisines based on existing values
  const [modifiedCuisines, setModifiedCuisines] = useState<Set<string>>(() => {
    if (selectedProductID && espForm?.formState?.defaultValues?.cuisine_sales) {
      const defaultSalesValue = espForm?.formState?.defaultValues?.default_sales
      const modifiedSet = new Set<string>()

      // Check all cuisines against the default value
      Cuisine50Options.forEach((cuisine) => {
        const cuisineValue =
          espForm?.formState?.defaultValues?.cuisine_sales[cuisine.value]
        if (cuisineValue !== undefined && cuisineValue !== defaultSalesValue) {
          modifiedSet.add(cuisine.value)
        }
      })

      return modifiedSet
    }
    return new Set()
  })

  // Watch default sales
  const defaultSales = useWatch({
    control,
    name: 'default_sales',
    defaultValue: 0,
  })

  // Add form validation
  const isFormValid = useMemo(() => {
    const defaultSalesValue = getValues('default_sales')

    // Check if default sales is valid
    const isDefaultSalesValid =
      defaultSalesValue !== undefined &&
      defaultSalesValue !== null &&
      !isNaN(Number(defaultSalesValue)) &&
      Number(defaultSalesValue) >= 0

    // Check if there are any validation errors
    const hasNoErrors = Object.keys(errors).length === 0

    return isDefaultSalesValid && isValid && hasNoErrors
  }, [getValues, isValid, errors])

  // Update unmodified cuisine values when default changes
  useEffect(() => {
    const defaultValue = Number(defaultSales)
    if (!isNaN(defaultValue)) {
      Cuisine50Options.forEach((cuisine) => {
        if (!modifiedCuisines.has(cuisine.value)) {
          setValue(`cuisine_sales.${cuisine.value}`, defaultValue, {
            shouldDirty: false,
            shouldTouch: false,
          })
        }
      })
    }
  }, [defaultSales, setValue, modifiedCuisines, selectedProductID])

  const handleCuisineModified = React.useCallback(
    (cuisineId: string, value: number) => {
      const defaultValue = Number(defaultSales)

      if (value !== defaultValue) {
        setModifiedCuisines((prev) => new Set([...prev, cuisineId]))
      } else {
        setModifiedCuisines((prev) => {
          const newSet = new Set(prev)
          newSet.delete(cuisineId)
          return newSet
        })
      }
    },
    [defaultSales]
  )

  const clearSearch = React.useCallback(() => {
    setSearchTerm('')
  }, [])

  // Filter and sort cuisines
  const filteredAndSortedCuisines = useMemo(() => {
    let cuisines = Cuisine50Options.filter((cuisine) =>
      cuisine.label.toLowerCase().includes(searchTerm.toLowerCase())
    )

    if (sortOption) {
      cuisines = [...cuisines].sort((a, b) => {
        const aValue = espForm.getValues(`cuisine_sales.${a.value}`) ?? 0
        const bValue = espForm.getValues(`cuisine_sales.${b.value}`) ?? 0
        let aModified: boolean, bModified: boolean

        switch (sortOption) {
          case 'asc':
            return aValue - bValue
          case 'desc':
            return bValue - aValue
          case 'overrides':
            aModified = aValue !== defaultSales
            bModified = bValue !== defaultSales
            return Number(bModified) - Number(aModified)
          default:
            return 0
        }
      })
    }

    return cuisines
  }, [searchTerm, sortOption, defaultSales, espForm])

  const clearSort = React.useCallback(() => {
    setSortOption(null)
  }, [])

  const getSortLabel = (sort: 'overrides' | 'asc' | 'desc'): string => {
    switch (sort) {
      case 'overrides':
        return 'Showing overrides first'
      case 'asc':
        return 'Sorted by value (low to high)'
      case 'desc':
        return 'Sorted by value (high to low)'
      default:
        return ''
    }
  }

  // Get unit from product form
  const unit = productForm.watch('unit')

  return (
    <BaseStep
      handleClose={handleClose}
      onBack={onBack}
      onContinue={handleSubmit}
      title={selectedProductID ? 'Update Product' : 'Create Product'}
      description="Enter the average monthly revenue made per unit sold to a given location. If certain cuisines are significantly higher or lower, you can override this average below."
      continueButtonText="Save"
      currentStep={1}
      totalSteps={2}
      continueDisabled={!isFormValid}
      isContinueLoading={isSubmitting}
    >
      <div className="space-y-4">
        <S.Card>
          <h3 className="text-sm font-medium text-gray-900 mb-4">
            Default product sales
          </h3>
          <TextInput
            min={0}
            name="default_sales"
            control={control}
            type="number"
            label="Avg. sales per month"
            placeholder="Enter average sales"
            suffix={unit || 'lbs'}
          />
        </S.Card>

        <S.Card>
          <button
            onClick={() => setIsCuisineExpanded(!isCuisineExpanded)}
            className="w-full flex items-center justify-between"
          >
            <div className="flex items-center gap-2">
              <h3 className="text-sm font-medium text-gray-900">
                Cuisine-specific product sales
              </h3>
              <S.Badge>Optional</S.Badge>
            </div>
            {isCuisineExpanded ? (
              <ChevronUp className="h-5 w-5 text-gray-500" />
            ) : (
              <ChevronDown className="h-5 w-5 text-gray-500" />
            )}
          </button>

          {isCuisineExpanded && (
            <div className="mt-4 space-y-4">
              <div className="space-y-2">
                <div className="flex items-center gap-4">
                  <SearchContainer>
                    <SearchInput
                      type="text"
                      placeholder="Search cuisine types"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {searchTerm && (
                      <button onClick={clearSearch}>
                        <X className="h-4 w-4 text-gray-400" />
                      </button>
                    )}
                    <Search className="h-4 w-4 text-gray-400" />
                  </SearchContainer>
                  <CuisineSortDropdown
                    selected={sortOption}
                    onSelect={setSortOption}
                  />
                </div>
                {sortOption && (
                  <div className="flex items-center justify-end gap-2">
                    <SortIndicator>
                      {getSortLabel(sortOption)}
                      <ClearButton onClick={clearSort}>
                        <X className="h-3 w-3" />
                      </ClearButton>
                    </SortIndicator>
                  </div>
                )}
              </div>

              <div className="space-y-2 max-h-[400px] bg-white rounded-lg px-4 py-1">
                <StyledSimpleBar>
                  {filteredAndSortedCuisines.map((cuisine) => (
                    <CuisineInputRow
                      key={cuisine.value}
                      cuisine={cuisine}
                      control={control}
                      defaultValue={Number(defaultSales)}
                      unit={unit || 'lbs'}
                      onModified={handleCuisineModified}
                      isModified={modifiedCuisines.has(cuisine.value)}
                    />
                  ))}
                </StyledSimpleBar>
              </div>
            </div>
          )}
        </S.Card>
      </div>
    </BaseStep>
  )
}

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  background-color: white;
  border-radius: 24px;
  padding: 8px 12px;
`

const SearchInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  background: transparent;
  font-size: 14px;
  &::placeholder {
    color: #9ca3af;
  }
`

const CuisineRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #e5e7eb;
  &:last-child {
    border-bottom: none;
  }
`
const StyledSimpleBar = styled(SimpleBar)`
  max-height: 300px;
  margin-right: -16px;
  padding-right: 16px;

  .simplebar-scrollbar::before {
    background-color: #94a3b8;
  }

  .simplebar-track.simplebar-vertical {
    width: 8px;
    right: 0;
  }
`
