import { restaurantsFilterStore } from 'components/Filters/FilterStore'
import FilterModal, {
  FilterGroup,
  QuickFilterGroup,
} from 'components/Filters/components/FilterModal'
import {
  QuickRangeFilter,
  QuickValueFilter,
} from 'components/Filters/fields/QuickFilter'
import {
  AvgRatingRangeFilter,
  InstagramFollowersRangeFilter,
  RangeFilter,
  RangeFilterWithMinMax,
  ReviewCountRangeFilter,
} from 'components/Filters/fields/RangeFilter'
import { SelectFilter } from 'components/Filters/fields/SelectFilter'
import { ZipcodeFilter } from 'components/Filters/fields/ZipcodeFilter'
import { DELIVERY_PLATFORMS_OPTIONS } from 'models/delivery_platforms'
import { DMA_OPTIONS } from 'models/dma_options'
import { REPUTATION_PLATFORMS_OPTIONS } from 'models/reputation_platforms'
import { VelocityGroupOptions } from 'models/velocity_groups'
import { useEffect, useState } from 'react'
import apiService from 'services/api'
import { ALL_COUNTRIES } from 'utils/countries'
import { featureFlagService } from 'utils/featureFlagService'
import { US_CITIES } from 'utils/us_cities'
import { Cuisine50Options } from '../../../models/cuisine_50'
import { menu_ingredients } from '../../../models/menu_ingredients'
import { US_STATES } from '../../../utils/us_states'

import { COMMON_COLUMNS } from 'components/DataExpansionComponents/Common/CommonColumnsInfo'
import { CommonFiltersetAccountGroup } from 'components/DataExpansionComponents/Common/CommonTable/CommonFiltersetAccountGroup'
import { createFilterColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { DOORS_COLUMNS } from 'components/DataExpansionComponents/Doors/doorsColumnsInfo'
import { InstagramTextPercentileOptions } from 'models/instagram_text_percentile'
import { FilterIdentifier } from 'models/saved_view'

const IDENTIFIER: FilterIdentifier = 'DOORS_TABLE'

export function DoorsFilterset() {
  const featureFlags = featureFlagService()
  const api = apiService()
  const { data: campaignOptions } = api.useGetCampaignsOptions(
    'restaurants-bars',
    undefined,
    true
  )

  const { pendingValueFilters, setPendingValueFilters } =
    restaurantsFilterStore()

  const [usCities, setUsCities] = useState<{ value: string; label: string }[]>()

  useEffect(() => {
    const selectedStates = pendingValueFilters.state?.map((f) => f.value)
    if (!selectedStates?.length) {
      setUsCities(US_CITIES)
      return
    }

    const availableCities = US_CITIES.filter((c) =>
      selectedStates.some((s) => s === c.value.split('_')[1])
    )

    const selectedCities = pendingValueFilters?.city?.map((f) => f.value)

    if (selectedCities) {
      const citiesToRemove: string[] = []
      for (const city of selectedCities) {
        if (!availableCities.some((c) => c.value === city)) {
          citiesToRemove.push(city as string)
        }
      }
      if (citiesToRemove.length) {
        setPendingValueFilters(
          'city',
          pendingValueFilters.city.filter(
            (f) => !citiesToRemove.some((c) => c === f.value)
          )
        )
      }
    }

    setUsCities(availableCities)
  }, [pendingValueFilters])

  const doorsFilterHelper = createFilterColumnHelper({ columns: DOORS_COLUMNS })
  const commonFilterHelper = createFilterColumnHelper({
    columns: COMMON_COLUMNS,
  })

  return (
    <FilterModal identifier={IDENTIFIER} store={restaurantsFilterStore}>
      <QuickFilterGroup>
        <QuickRangeFilter
          {...doorsFilterHelper.retrieveProps('one_year_total_value')}
          valueLabel="≥1"
          predefinedValue={{ min: 1 }}
          filterStore={restaurantsFilterStore}
        />
        <QuickRangeFilter
          {...doorsFilterHelper.retrieveProps('chain_count')}
          valueLabel="2+"
          predefinedValue={{ min: 2 }}
          filterStore={restaurantsFilterStore}
        />
        <QuickValueFilter
          {...commonFilterHelper.retrieveProps('sales_stages')}
          valueLabel="Empty"
          predefinedValue={[{ value: '-1', label: 'Empty' }]}
          filterStore={restaurantsFilterStore}
        />
        <QuickRangeFilter
          {...commonFilterHelper.retrieveProps('contact_count')}
          valueLabel="≤0"
          predefinedValue={{ max: 0 }}
          filterStore={restaurantsFilterStore}
        />
      </QuickFilterGroup>
      <FilterGroup title="Lifetime Values">
        <RangeFilter
          {...doorsFilterHelper.retrieveProps('total_ltv')}
          store={restaurantsFilterStore}
        />
        <RangeFilter
          {...doorsFilterHelper.retrieveProps('brand_ltv')}
          store={restaurantsFilterStore}
        />
        <RangeFilter
          {...doorsFilterHelper.retrieveProps('revenue_ltv')}
          store={restaurantsFilterStore}
        />
      </FilterGroup>
      <FilterGroup title="Predictive Annual Values">
        <RangeFilter
          {...doorsFilterHelper.retrieveProps('one_year_total_value')}
          store={restaurantsFilterStore}
        />
        <RangeFilter
          {...doorsFilterHelper.retrieveProps('taro')}
          store={restaurantsFilterStore}
        />
        <RangeFilter
          {...doorsFilterHelper.retrieveProps('tabo')}
          store={restaurantsFilterStore}
        />
      </FilterGroup>
      <FilterGroup title="Menu">
        <SelectFilter
          {...doorsFilterHelper.retrieveProps('cuisine_50')}
          options={Cuisine50Options}
          filterStore={restaurantsFilterStore}
        />
        <RangeFilter
          {...doorsFilterHelper.retrieveProps('pounds_per_year')}
          store={restaurantsFilterStore}
        />
        <SelectFilter
          {...doorsFilterHelper.retrieveProps('ingredients_matrix')}
          options={menu_ingredients}
          filterStore={restaurantsFilterStore}
        />
        {featureFlags.shouldShowMenuSize && (
          <RangeFilter
            {...doorsFilterHelper.retrieveProps('menu_size')}
            store={restaurantsFilterStore}
          />
        )}
        <RangeFilter
          {...doorsFilterHelper.retrieveProps('menu_matches')}
          store={restaurantsFilterStore}
        />
      </FilterGroup>
      <FilterGroup title="Features">
        <RangeFilter
          {...doorsFilterHelper.retrieveProps('chain_count')}
          store={restaurantsFilterStore}
        />
        <SelectFilter
          {...doorsFilterHelper.retrieveProps('delivery_platforms')}
          options={DELIVERY_PLATFORMS_OPTIONS}
          filterStore={restaurantsFilterStore}
        />
        <SelectFilter
          {...doorsFilterHelper.retrieveProps('expense_category')}
          options={[
            { label: '$', value: '1' },
            { label: '$$', value: '2' },
            { label: '$$$', value: '3' },
            { label: '$$$$', value: '4' },
          ]}
          filterStore={restaurantsFilterStore}
        />
        {featureFlags.shouldShowReputationData && (
          <>
            <SelectFilter
              {...doorsFilterHelper.retrieveProps('reputation_data')}
              options={REPUTATION_PLATFORMS_OPTIONS}
              filterStore={restaurantsFilterStore}
            />
            <RangeFilterWithMinMax
              {...doorsFilterHelper.retrieveProps('michelin_stars')}
              store={restaurantsFilterStore}
              min={0}
              max={3}
            />
          </>
        )}
        <AvgRatingRangeFilter store={restaurantsFilterStore} />
        <ReviewCountRangeFilter store={restaurantsFilterStore} />
        <SelectFilter
          {...doorsFilterHelper.retrieveProps('velocity_group')}
          options={VelocityGroupOptions}
          filterStore={restaurantsFilterStore}
        />
        {featureFlags.enableInstagremData && (
          <InstagramFollowersRangeFilter store={restaurantsFilterStore} />
        )}
        {featureFlags.enableInstagramPercentile && (
          <SelectFilter
            {...doorsFilterHelper.retrieveProps('instagram_text_percentile')}
            options={InstagramTextPercentileOptions}
            filterStore={restaurantsFilterStore}
          />
        )}
      </FilterGroup>
      <FilterGroup title="Location">
        {featureFlags.enableRestaurantsCountryFilter && (
          <SelectFilter
            {...doorsFilterHelper.retrieveProps('country')}
            options={ALL_COUNTRIES}
            filterStore={restaurantsFilterStore}
          />
        )}
        <SelectFilter
          {...doorsFilterHelper.retrieveProps('state')}
          options={US_STATES}
          filterStore={restaurantsFilterStore}
        />
        <SelectFilter
          {...doorsFilterHelper.retrieveProps('dmanamecbsa')}
          options={DMA_OPTIONS}
          filterStore={restaurantsFilterStore}
        />
        <SelectFilter
          {...doorsFilterHelper.retrieveProps('city')}
          options={usCities}
          filterStore={restaurantsFilterStore}
        />
        <ZipcodeFilter filterStore={restaurantsFilterStore} />
      </FilterGroup>
      <CommonFiltersetAccountGroup
        filterStore={restaurantsFilterStore}
        campaignOptions={campaignOptions}
      />
      <FilterGroup title="ZIP Code Demographics">
        <RangeFilter
          {...doorsFilterHelper.retrieveProps(
            'income_medianhouseholdincome_hh_cnt'
          )}
          store={restaurantsFilterStore}
        />
        <RangeFilter
          {...doorsFilterHelper.retrieveProps('pop_sqmi')}
          store={restaurantsFilterStore}
        />
        <RangeFilter
          {...doorsFilterHelper.retrieveProps('hh_gt100k')}
          store={restaurantsFilterStore}
          decimalScale={2}
        />
      </FilterGroup>
    </FilterModal>
  )
}
