import { useMemo } from 'react'
import * as S from 'components/Tables/CommonTable.styles'
import apiService from '../../services/api'
import dialogConfirm from '../../components/dialogConfirm'
import { ColumnDef } from '@tanstack/react-table'
import { ActionButtons } from '../../components/Buttons/ActionButtons'
import { OverflownText } from '../../components/OverflownText'
import { Campaign } from '../../models/campaign'
import { CampaignsCell } from '../../components/FbUI/StagePill'
import { ClientDataTable } from '../../components/ClientDataTable/ClientDataTable'
import { DataTableContainer } from '../../components/DataTable/UI'
import {
  filterStoreRepo,
  useFilterParams,
} from 'components/Filters/FilterStore'
import { TableSearch } from 'components/Filters/TableSearch'
import { DateCell } from 'components/Tables/CommonTableCells/DateCell'
import { CAMPAIGNS_COLUMNS } from './campaignsColumns'
import { usePreferences } from 'context/preferences/PreferencesContext'
import { createTableColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { CampaignsFilterset } from './CampaignsFilterset'
import { USDCell } from 'components/Tables/CommonTableCells/USDCell'
import { formatInteger } from 'utils/formatting'

export function CampaignsTable(props: {
  tableKey?: string
  onEditCampaign?: (campaign: Campaign) => void
}) {
  const api = apiService()
  const IDENTIFIER = 'ALL_CAMPAIGNS_TABLE'
  const campaignsFilterStore = filterStoreRepo.getStore(IDENTIFIER)
  const filterParams = useFilterParams(campaignsFilterStore)
  const abortController = new AbortController()
  const { data: campaignData, isFetching } = api.useGetCampaigns(
    abortController.signal,
    false,
    filterParams
  )
  const deleteCampaignMutation = api.useDeleteCampaign()

  async function handleRowDelete(index: number) {
    const id = campaignData?.[index]?.id
    if (!id) return

    if (
      await dialogConfirm(
        'Are you sure you want to delete this campaign?',
        'Delete Campaign'
      )
    ) {
      await deleteCampaignMutation.mutateAsync(id)
    }
  }

  const { preferences } = usePreferences()
  const tooltips: Record<string, any> = preferences?.tooltips || {}
  const campaignsColumnHelper = createTableColumnHelper({
    columns: CAMPAIGNS_COLUMNS,
    tooltips,
  })

  const columns = useMemo<ColumnDef<Campaign, any>[]>(
    () => [
      {
        ...campaignsColumnHelper.retrieveColumn('name'),
        accessorKey: 'name',
        size: 150,
        cell: (info) => {
          return <CampaignsCell campaigns={[info.row.original]} />
        },
      },
      {
        ...campaignsColumnHelper.retrieveColumn('description'),
        accessorKey: 'description',
        size: 200,
      },
      {
        ...campaignsColumnHelper.retrieveColumn('company_type'),
        accessorKey: 'type.name',
        size: 150,
      },
      {
        ...campaignsColumnHelper.retrieveColumn('product'),
        accessorKey: 'product.name',
        size: 250,
        cell: (info) => {
          const value = info.getValue()
          return value ? (
            <OverflownText maxLines={2}>{value}</OverflownText>
          ) : (
            'N/A'
          )
        },
      },
      {
        ...campaignsColumnHelper.retrieveColumn('created'),
        accessorKey: 'created',
        size: 200,
        cell: DateCell,
      },
      {
        ...campaignsColumnHelper.retrieveColumn('modified'),
        accessorKey: 'modified',
        size: 200,
        cell: DateCell,
      },
      {
        ...campaignsColumnHelper.retrieveColumn('days_since_last_update'),
        accessorKey: 'days_since_last_update',
        size: 200,
        cell: (info) => {
          return info.getValue() ? formatInteger(info.getValue()) : '-'
        },
      },
      {
        ...campaignsColumnHelper.retrieveColumn('contact_company_count'),
        accessorKey: 'contact_company_count',
        cell: (info) => {
          return info.getValue() ? formatInteger(info.getValue()) : '-'
        },
      },
      {
        ...campaignsColumnHelper.retrieveColumn('door_count'),
        accessorKey: 'door_count',
        cell: (info) => {
          return info.getValue() ? formatInteger(info.getValue()) : '-'
        },
      },
      {
        ...campaignsColumnHelper.retrieveColumn('deal_count'),
        accessorKey: 'deal_count',
        cell: (info) => {
          return info.getValue() ? formatInteger(info.getValue()) : '-'
        },
      },
      {
        ...campaignsColumnHelper.retrieveColumn('deal_count_won'),
        accessorKey: 'deal_count_won',
        cell: (info) => {
          return info.getValue() ? formatInteger(info.getValue()) : '-'
        },
      },
      {
        ...campaignsColumnHelper.retrieveColumn('deal_perc'),
        accessorKey: 'deal_perc',
        cell: (info) => {
          return info.getValue() ? info.getValue() : '-'
        },
      },
      {
        ...campaignsColumnHelper.retrieveColumn('deal_perc_won'),
        accessorKey: 'deal_perc_won',
        cell: (info) => {
          return info.getValue() ? info.getValue() : '-'
        },
      },
      {
        ...campaignsColumnHelper.retrieveColumn('contact_count'),
        accessorKey: 'contact_count',
        cell: (info) => {
          return info.getValue() ? formatInteger(info.getValue()) : '-'
        },
      },
      {
        ...campaignsColumnHelper.retrieveColumn('companies_with_contacts'),
        accessorKey: 'companies_with_contacts',
        cell: (info) => {
          return info.getValue() ? formatInteger(info.getValue()) : '-'
        },
      },
      {
        ...campaignsColumnHelper.retrieveColumn('companies_with_contacts_perc'),
        accessorKey: 'companies_with_contacts_perc',
        cell: (info) => {
          return info.getValue() ? info.getValue() : '-'
        },
      },
      {
        ...campaignsColumnHelper.retrieveColumn('taro'),
        accessorKey: 'taro',

        cell: USDCell,
      },
      {
        ...campaignsColumnHelper.retrieveColumn('taro_closed'),
        accessorKey: 'taro_closed',

        cell: USDCell,
      },
      {
        ...campaignsColumnHelper.retrieveColumn('est_meals_day'),
        accessorKey: 'est_meals_day',
        cell: (info) => {
          return info.getValue() ? formatInteger(info.getValue()) : '-'
        },
      },
      {
        id: 'edit',
        header: 'Actions',
        size: 130,
        meta: { rightAlign: true },
        cell: ({ row }) => (
          <ActionButtons
            onDelete={() => handleRowDelete(row.index)}
            onEdit={() => props.onEditCampaign?.(row.original)}
          />
        ),
      },
    ],
    [campaignData]
  )

  return (
    <DataTableContainer key={props.tableKey + '-container'}>
      <S.SearchContainer>
        <div className="flex w-full gap-2 items-center">
          <TableSearch
            className={'flex-1'}
            filterStore={campaignsFilterStore}
            searchPlaceholder="Search by campaign name"
          />
          <CampaignsFilterset filterIdentifier={IDENTIFIER} />
        </div>
      </S.SearchContainer>
      <ClientDataTable
        stickyLastColumn={true}
        stickyFirstColumn={true}
        loading={isFetching}
        height={'100%'}
        data={campaignData ?? []}
        columns={columns}
      />
    </DataTableContainer>
  )
}
