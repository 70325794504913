import apiService from 'services/api'
import ExportModal, { ExportModalProps } from './ExportModal'
import { downloadFile } from 'utils/csvDownloader'
import { K12_COLUMNS } from 'components/DataExpansionComponents/K12/K12ColumnsInfo'
import { createExportColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { getCommonChainProxyExportColumns } from 'components/DataExpansionComponents/Common/commonExportColumns'

export function K12DistrictExportModal(props: ExportModalProps) {
  const api = apiService()

  const exportK12Districts = async ({
    filter,
    selectedColumns,
    exportType,
    ids,
    excludeIds,
    controller,
  }: {
    filter: Record<string, unknown>
    selectedColumns: string[]
    exportType: 'csv' | 'xlsx'
    ids?: number[] | undefined
    excludeIds?: number[] | undefined
    controller?: AbortController
  }) => {
    const res = await api.exportK12Districts(
      filter,
      selectedColumns,
      exportType,
      ids,
      excludeIds,
      controller?.signal
    )
    downloadFile(
      res,
      `First Bite Export: K12 Districts - ${new Date().toLocaleDateString()}.${exportType}`
    )
  }

  const k12ColumnHelper = createExportColumnHelper({
    columns: K12_COLUMNS,
  })

  const columns = [
    {
      title: 'Opportunity Attributes',
      elements: [
        k12ColumnHelper.retrieveColumn('lea_name'),
        k12ColumnHelper.retrieveColumn('address'),
        k12ColumnHelper.retrieveColumn('lcity'),
        k12ColumnHelper.retrieveColumn('lstate'),
        k12ColumnHelper.retrieveColumn('zipcode'),
        k12ColumnHelper.retrieveColumn('phone'),
        k12ColumnHelper.retrieveColumn('domain'),
        k12ColumnHelper.retrieveColumn('gslo'),
        k12ColumnHelper.retrieveColumn('gshi'),
        k12ColumnHelper.retrieveColumn('student_count'),
        k12ColumnHelper.retrieveColumn('teachers_count'),
        k12ColumnHelper.retrieveColumn('operational_schools'),
        k12ColumnHelper.retrieveColumn('revenue'),
        k12ColumnHelper.retrieveColumn('google_place_url'),
      ],
    },
    {
      title: 'Dining Services',
      elements: [
        k12ColumnHelper.retrieveColumn('food_service_expenditures'),
        k12ColumnHelper.retrieveColumn('free_lunch'),
        k12ColumnHelper.retrieveColumn('reduced_lunch'),
        k12ColumnHelper.retrieveColumn('total_supplemented_lunch'),
        k12ColumnHelper.retrieveColumn('uncategorized_lunch'),
        k12ColumnHelper.retrieveColumn('free_lunch_perc'),
        k12ColumnHelper.retrieveColumn('reduced_lunch_perc'),
        k12ColumnHelper.retrieveColumn('total_supplemented_lunch_perc'),
        k12ColumnHelper.retrieveColumn('uncategorized_lunch_perc'),
        k12ColumnHelper.retrieveColumn('estimated_meals_per_day'),
      ],
    },
    {
      title: 'Predicted Values and Deals',
      elements: getCommonChainProxyExportColumns(),
    },
  ]

  return (
    <ExportModal
      title="Select Columns for K-12 Districts Download"
      subTitle="Download up to 10,000 K-12 Districts at a time, with any of the following columns. Any filters already applied will remain active."
      submitText="Export K-12 Districts"
      columns={columns}
      exportAPIAction={exportK12Districts}
      {...props}
    />
  )
}
