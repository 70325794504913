import React from 'react'
import { ZipcodeFilter } from 'components/Filters/fields/ZipcodeFilter'
import apiService from 'services/api'
import { US_CITIES } from 'utils/us_cities'
import { US_STATES } from 'utils/us_states'
import { filterStoreRepo } from '../../../Filters/FilterStore'
import FilterModal, {
  FilterGroup,
} from '../../../Filters/components/FilterModal'
import { RangeFilter } from '../../../Filters/fields/RangeFilter'
import { SelectFilter } from '../../../Filters/fields/SelectFilter'
import { FilterIdentifier } from 'models/saved_view'
import { createFilterColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { HOSPITAL_COLUMNS } from '../hospitalColumnsInfo'
import { CommonFiltersetAccountGroup } from 'components/DataExpansionComponents/Common/CommonTable/CommonFiltersetAccountGroup'

export function HospitalsFilterset({
  filterIdentifier,
  hideCampaignsFilter,
}: {
  filterIdentifier: FilterIdentifier
  hideCampaignsFilter?: boolean
}) {
  const api = apiService()
  const { data: campaignOptions } = api.useGetCampaignsOptions(
    'healthcare-hospitals'
  )

  const hospitalsFilterStore = filterStoreRepo.getStore(filterIdentifier)

  const hospitalFilterHelper = createFilterColumnHelper({
    columns: HOSPITAL_COLUMNS,
  })

  return (
    <FilterModal identifier={filterIdentifier} store={hospitalsFilterStore}>
      <CommonFiltersetAccountGroup
        filterStore={hospitalsFilterStore}
        campaignOptions={campaignOptions}
        hideCampaignsFilter={hideCampaignsFilter}
      />
      <FilterGroup title="Hospital Insights">
        <RangeFilter
          {...hospitalFilterHelper.retrieveProps('total_hospital_days')}
          store={hospitalsFilterStore}
        />
        <RangeFilter
          {...hospitalFilterHelper.retrieveProps('total_hospital_beds')}
          store={hospitalsFilterStore}
        />
      </FilterGroup>
      <FilterGroup title="Dining Services">
        <RangeFilter
          {...hospitalFilterHelper.retrieveProps('meals_per_day')}
          store={hospitalsFilterStore}
        />
        <SelectFilter
          {...hospitalFilterHelper.retrieveProps('cafeteria')}
          options={[
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
          ]}
          filterStore={hospitalsFilterStore}
        />
        <SelectFilter
          {...hospitalFilterHelper.retrieveProps('dietary')}
          options={[
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
          ]}
          filterStore={hospitalsFilterStore}
        />
        <SelectFilter
          {...hospitalFilterHelper.retrieveProps('dietary_under_contract')}
          options={[
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
          ]}
          filterStore={hospitalsFilterStore}
        />
      </FilterGroup>
      <FilterGroup title="Location">
        <SelectFilter
          {...hospitalFilterHelper.retrieveProps('city')}
          options={US_CITIES}
          filterStore={hospitalsFilterStore}
        />
        <SelectFilter
          {...hospitalFilterHelper.retrieveProps('state')}
          options={US_STATES}
          filterStore={hospitalsFilterStore}
        />
        <ZipcodeFilter filterStore={hospitalsFilterStore} />
      </FilterGroup>
    </FilterModal>
  )
}
