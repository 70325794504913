import { ActivityV2 } from 'models/activityV2'
import { DataTypeColumn } from '../Common/commonColumnHelpers'

// Fields that are calculated, or not present in the model
type CustomFields = 'event' | 'company'

export const ACTIVITY_V2_COLUMNS = {
  user: {
    header: 'Team Member',
    group: 'Activity Details',
  },
  event: {
    header: 'Event',
    group: 'Activity Details',
  },
  bulk_size: {
    header: 'Bulk Size',
    group: 'Activity Details',
  },
  content_type: {
    header: 'Type',
    group: 'Activity Details',
  },
  company: {
    header: 'Company',
    group: 'Activity Details',
  },
  created_at: {
    header: 'Date',
    group: 'Activity Details',
  },
  event_type: {
    header: 'Event Type',
    group: 'Activity Details',
  },
} satisfies Partial<Record<keyof ActivityV2 & CustomFields, DataTypeColumn>>
