import styled from 'styled-components/macro'

export const TopBarTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #ffffff;
  margin-right: 16px;
`
export const Wrapper = styled.div`
  background: #054628;
  min-height: var(--footer-height);
  height: var(--footer-height);
`

export const Content = styled.div`
  color: white;
  display: flex;
  align-items: center;
  padding: 0 56px;
  height: 100%;
  @media (max-width: 600px) {
    padding: 0 16px;
    justify-content: center;
  }
`

export const FooterMenu = styled.div`
  margin-left: auto;

  a {
    font-size: 14px;
  }

  a:hover {
    background: #04331d;
  }

  a:last-child {
    @media (max-width: 600px) {
      display: none;
    }
  }
`
