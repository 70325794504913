import { useQuery } from '@tanstack/react-query'
import {
  DataTable,
  DataTableProvider,
  useDataTableContext,
} from 'components/DataTable'
import FbButton from 'components/FbUI/FbButton'
import {
  activityV2FilterStore,
  useFilterParams,
} from 'components/Filters/FilterStore'
import { ColumnSelectorRecipient } from 'components/Modals/ColumnModal/ColumnModal'
import { ContactResponse } from 'models/contacts'
import { useEffect, useMemo, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BsDownload } from 'react-icons/bs'
import { toast } from 'react-toastify'
import apiService from 'services/api'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import styled from 'styled-components'
import { downloadFile } from 'utils/csvDownloader'
import { usePaginationURLParams } from 'utils/usePaginationURLParams'
import { getTableQueryKey } from '../../../utils/getTableQueryKey'
import { dataTableSortingStoreRepo } from '../../DataTable/DataTableSorting/DataTableSortingStore'
import { DataTableContainer } from '../../DataTable/UI'
import { IColumnVisibility } from '../../DataTable/types'
import { TableSearch } from '../../Filters/TableSearch'
import { FilterChips } from '../../Filters/components/FilterChips/FilterChips'
import * as S from '../CommonTable.styles'
import ActivityV2Filterset from './ActivityV2Filterset'
import { useActivityTableColumnsV2 } from './useActivityTableColumnsV2'

type SortableFields = (keyof ContactResponse | string)[]

const sortableFields: SortableFields = [
  'user',
  'content_type',
  'created_at',
  'bulk_size',
]

const PAGE_SIZE = 100

interface ActivityTableV2Props {
  setTotalRowsCount?: (count: number) => void
  myActivityOnly?: boolean
  tableKey: string
}

function ActivityTableV2Component(props: ActivityTableV2Props) {
  const [isDownloading, setIsDownloading] = useState(false)
  const {
    methods: { clearSelectedRows, setTotalRowsInBackend },
  } = useDataTableContext()
  const { sorting } = dataTableSortingStoreRepo.getStore(props.tableKey)()

  const [pagination, setPagination] = usePaginationURLParams(PAGE_SIZE)
  const api = apiService()

  const filterParams = useFilterParams(activityV2FilterStore)

  const sortParams = useMemo(() => {
    const params: Record<string, unknown> = {}

    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }

    return params
  }, [sorting])

  const filterAndSortParams = useMemo(
    () => ({
      ...filterParams,
      ...sortParams,
    }),
    [filterParams, sortParams]
  )

  const TABLE_QUERY_KEY = getTableQueryKey({
    tableKey: props.tableKey,
    filterParams: filterAndSortParams,
    page: pagination.pageIndex + 1,
  })

  // DATA FETCHING
  const { isFetching, data } = useQuery({
    queryKey: TABLE_QUERY_KEY,
    queryFn: async ({ signal }) => {
      clearSelectedRows()

      if (props.myActivityOnly) {
        return await api.getMyActivityListV2(
          { ...filterAndSortParams, page: pagination.pageIndex + 1 },
          signal
        )
      } else {
        return await api.getActivityListV2(
          { ...filterAndSortParams, page: pagination.pageIndex + 1 },
          signal
        )
      }
    },
  })
  // END DATA FETCHING

  const columns = useActivityTableColumnsV2()

  const downloadCsv = async () => {
    const params = {
      ...filterAndSortParams,
    }

    try {
      setIsDownloading(true)
      if (props.myActivityOnly) {
        const res = await api.downloadMyActivityExportV2(params)
        downloadFile(
          res,
          `First Bite Export: My Activity - ${new Date().toLocaleDateString()}.csv`
        )
      } else {
        const res = await api.downloadActivityExportV2(params)
        downloadFile(
          res,
          `First Bite Export: Activity - ${new Date().toLocaleDateString()}.csv`
        )
      }
      toast.success('CSV downloaded successfully')
    } catch (e) {
      toast.error('Error downloading CSV')
    } finally {
      setIsDownloading(false)
    }
  }

  useEffect(() => {
    const totalCount = data?.count ?? 0
    props.setTotalRowsCount?.(totalCount)
    setTotalRowsInBackend?.(totalCount)
  }, [data?.count])

  const tooltip = (
    <Tooltip id="tooltip">Download limited to 10,000 rows</Tooltip>
  )

  return (
    <DataTableContainer>
      <DataTable
        tableHeader={
          <S.SearchContainer>
            <S.SearchContainerFilters>
              <S.TableSearchContainer>
                <TableSearch
                  filterStore={activityV2FilterStore}
                  searchPlaceholder="Search by company or email address"
                />
              </S.TableSearchContainer>
              <S.TableButtonsContainer>
                <ActivityV2Filterset />
                <ColumnSelectorRecipient tableKey={props.tableKey} />
              </S.TableButtonsContainer>
            </S.SearchContainerFilters>
            <FilterChips
              identifier={'ACTIVITY_TABLE_V2'}
              store={activityV2FilterStore}
              clearAllButton
              showActive
            />
          </S.SearchContainer>
        }
        key={props.tableKey}
        loading={isFetching}
        tableKey={props.tableKey}
        data={data?.results ?? []}
        columns={columns}
        sortableFields={sortableFields}
        virtualizeRows={true}
        isPaginationEnabled={true}
        stickyLastColumn
        paginationOptions={{
          pageCount: Math.ceil((data?.count ?? 0) / PAGE_SIZE),
          setPagination: setPagination,
          pagination: pagination,
          isPaginationLoading: isFetching,
        }}
        footerControls={
          <OverlayTrigger placement="top" overlay={tooltip}>
            <div>
              <IconMiniButton
                onClick={() => downloadCsv()}
                loading={isDownloading}
              >
                <BsDownload size={18} />
              </IconMiniButton>
            </div>
          </OverlayTrigger>
        }
      />
    </DataTableContainer>
  )
}

export function ActivityTableV2(
  props: ActivityTableV2Props & {
    defaultColumnVisibility?: IColumnVisibility<any>
  }
) {
  const { tableKey, defaultColumnVisibility, ...rest } = props

  return (
    <ColumnsStoreProvider
      tableKey={props.tableKey}
      savedPresetsIdentifier={'ACTIVITY_TABLE_V2'}
      defaultColumnVisibility={defaultColumnVisibility}
    >
      <DataTableProvider tableKey={tableKey}>
        <ActivityTableV2Component tableKey={tableKey} {...rest} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}

const IconMiniButton = styled(FbButton)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;

  gap: 8px;

  height: 44px;

  border-radius: 8px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`
