import { useQueryClient } from '@tanstack/react-query'
import FbButton from 'components/FbUI/FbButton'
import { FBPage } from 'components/FbUI/Page/FBPage'
import ImportContactModal from 'components/ImportContactFlow/ImportContactModal'
import { ContactRequestsTable } from 'components/Tables/ContactRequestsTable/ContactRequestsTable'
import { ContactsTable } from 'components/Tables/ContactsTable/ContactsTable'
import useDocumentTitle from 'components/useDocumentTitle'
import React from 'react'
import { CgSoftwareUpload } from 'react-icons/cg'
import { FaPlus } from 'react-icons/fa'
import styled from 'styled-components/macro'
import { featureFlagService } from 'utils/featureFlagService'
import { Tabs } from '../../../components/FbUI/Tabs'
import NewContactModal from '../../../components/Modals/NewContactModal'
import {
  CONTACT_REQUEST_JOB_TABLE_KEY,
  CONTACTS_TABLE_KEY,
  MY_CONTACTS_TABLE_KEY,
} from '../../../constants/tableQueryKeys'
import { useNavigate, useParams } from 'react-router-dom'
import { useNavbarStore } from '../../../components/Layout/navbar-store'
import {
  FBPageHeader,
  FBPageInnerTabsContainer,
  FBPageTableContainer,
  FBPageTitle,
  FBPageTitleRow,
} from '../../../components/FbUI/Page/styles'

export function ContactsPage() {
  const queryClient = useQueryClient()
  const featureFlag = featureFlagService()
  const navigate = useNavigate()
  const params = useParams()
  const selectedTab = params?.type ?? 'all'
  const store = useNavbarStore()

  const [totalContacts, setTotalContacts] = React.useState<number | null>(null)
  const [totalMyContacts, setTotalMyContacts] = React.useState<number | null>(
    null
  )
  const [showNewContactModal, setShowNewContactModal] = React.useState(false)
  const [showImportContactModal, setShowImportContactModal] =
    React.useState(false)

  const handleContactCreated = () => {
    queryClient.invalidateQueries({ queryKey: ['tags', 'contacts'] })
    queryClient.refetchQueries({ queryKey: [MY_CONTACTS_TABLE_KEY] })
    queryClient.refetchQueries({ queryKey: [CONTACTS_TABLE_KEY] })
  }

  const handleContactUpdated = () => {
    queryClient.refetchQueries({ queryKey: [MY_CONTACTS_TABLE_KEY] })
    queryClient.refetchQueries({ queryKey: [CONTACTS_TABLE_KEY] })
  }

  useDocumentTitle(
    `${
      selectedTab === 'my' ? 'My' : selectedTab === 'all' ? 'All' : 'Requested'
    } Contacts - Contacts - First Bite`
  )

  return (
    <FBPage>
      <FBPageHeader>
        <FBPageTitleRow>
          <FBPageTitle>Contacts</FBPageTitle>

          <div className={'flex flex-row gap-2.5 ml-auto'}>
            <Button onClick={() => setShowNewContactModal(true)}>
              <FaPlus size={14} /> Add Contact
            </Button>
            <Button
              onClick={() => setShowImportContactModal(true)}
              variant="secondary"
            >
              <CgSoftwareUpload size={18} /> <span>Upload Contacts</span>
            </Button>
          </div>
        </FBPageTitleRow>

        {showImportContactModal && (
          <ImportContactModal
            show={showImportContactModal}
            handleClose={() => setShowImportContactModal(false)}
            onContactsImported={handleContactCreated}
          />
        )}

        {showNewContactModal && (
          <NewContactModal
            show={showNewContactModal}
            handleClose={() => setShowNewContactModal(false)}
            onContactCreated={handleContactCreated}
          />
        )}
      </FBPageHeader>

      <FBPageInnerTabsContainer>
        <Tabs
          tabs={[
            {
              key: 'all',
              title: (
                <div>
                  <b>All contacts</b>{' '}
                  {totalContacts ? `(${totalContacts.toLocaleString()})` : ''}
                </div>
              ),
            },
            {
              key: 'my',
              title: (
                <div>
                  <b>My contacts</b>{' '}
                  {totalMyContacts
                    ? `(${totalMyContacts.toLocaleString()})`
                    : ''}
                </div>
              ),
            },
            ...(featureFlag.enableContactRequests
              ? [
                  {
                    key: 'requests' as const,
                    title: (
                      <div>
                        <b>Contact Requests</b>{' '}
                      </div>
                    ),
                  },
                ]
              : []),
          ]}
          selectedTab={selectedTab}
          setSelectedTab={(value) => {
            const url = `/contacts/${value}`
            navigate(url)
            store.setLastContactVisited(url)
          }}
          tabStyle={{ width: '180px' }}
        />
      </FBPageInnerTabsContainer>

      <FBPageTableContainer
        style={{
          height: 'calc(var(--base-page-table-height) - 111px)',
        }}
      >
        {selectedTab === 'all' && (
          <ContactsTable
            defaultColumnVisibility={{
              requested_contact__campaign__name: false,
            }}
            tableKey={CONTACTS_TABLE_KEY}
            setTotalRowsCount={setTotalContacts}
            handleContactUpdated={handleContactUpdated}
          />
        )}

        {selectedTab === 'my' && (
          <ContactsTable
            defaultColumnVisibility={{
              requested_contact__campaign__name: false,
            }}
            tableKey={MY_CONTACTS_TABLE_KEY}
            setTotalRowsCount={setTotalMyContacts}
            myContactsOnly
            handleContactUpdated={handleContactUpdated}
          />
        )}

        {selectedTab === 'requests' && (
          <ContactRequestsTable tableKey={CONTACT_REQUEST_JOB_TABLE_KEY} />
        )}
      </FBPageTableContainer>
    </FBPage>
  )
}

const Button = styled(FbButton)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;

  gap: 8px;

  height: 44px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`
