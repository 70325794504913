import { useState } from 'react'
import { toast } from 'react-toastify'
import { IContactCompany, IDoorProxy } from '../../models/contact_companies'
import apiService from '../../services/api'
import { formatInteger } from '../../utils/formatting'
import { ActionButtons } from '../Buttons/ActionButtons'
import { ClientDataTable } from '../ClientDataTable/ClientDataTable'
import confirm from '../dialogConfirm'
import { CreateDoorProxyModal } from '../Modals/CompanyModal/CreateDoorProxyModal'
import { EditDoorProxyModal } from '../Modals/CompanyModal/EditDoorProxyModal'
import { Anchor } from '../UI/Anchor'
import { CollapsibleSection } from '../UI/CollapsibleSection/CollapsibleSection'

interface DoorMenuSectionProps {
  chainProxy?: IContactCompany
}

export function DoorProxySection(props: DoorMenuSectionProps) {
  const api = apiService()
  const [doorToEdit, setDoorToEdit] = useState<IDoorProxy | null>(null)
  const { data, isLoading, refetch } = api.useDoorProxiesOfChainProxy(
    props.chainProxy?.id
  )

  async function handleNoteDelete(doorProxyId: number) {
    const res = await confirm(
      'Are you sure you want to delete this door?',
      'Delete Door',
      undefined,
      {
        buttonVariant: 'danger',
        confirmLabel: 'Delete Door',
      }
    )

    if (res) {
      await api.deleteDoorProxy(doorProxyId)
      await refetch()
      toast.success('Door deleted seuccesfully.')
    }
  }

  if (isLoading || !data) {
    return null
  }

  return (
    <>
      <CollapsibleSection
        forceClose={data.results.length < 1}
        summary={
          <div className={'w-full flex justify-between items-center'}>
            <div>
              Doors{' '}
              <span className={'font-thin'}>
                ({formatInteger(data.results.length)})
              </span>
            </div>
            <CreateDoorProxyModal chainProxy={props.chainProxy} />
          </div>
        }
      >
        <ClientDataTable
          stickyLastColumn
          data={data.results}
          columns={[
            {
              accessorKey: 'name',
              header: 'Name',
              cell: (info) => info.getValue() || '-',
              size: 150,
            },
            {
              accessorKey: 'domain',
              header: 'Domain',
              cell: (info) =>
                info.getValue() ? (
                  <Anchor href={'//' + info.getValue()}>
                    {info.getValue()}
                  </Anchor>
                ) : (
                  '-'
                ),
              size: 200,
            },
            {
              accessorKey: 'address',
              header: 'Address',
              cell: (info) => info.getValue() || '-',
              size: 100,
            },
            {
              accessorKey: 'city',
              header: 'City',
              cell: (info) => info.getValue() || '-',
              size: 100,
            },
            {
              accessorKey: 'state',
              header: 'State',
              cell: (info) => info.getValue() || '-',
              size: 100,
            },
            {
              accessorKey: 'zipcode',
              header: 'Zip Code',
              cell: (info) => info.getValue() || '-',
              size: 100,
            },
            {
              accessorKey: 'country',
              header: 'Country',
              cell: (info) => info.getValue() || '-',
              size: 100,
            },
            {
              accessorKey: 'phone_number',
              header: 'Phone Number',
              cell: (info) => info.getValue() || '-',
              size: 100,
            },
            {
              accessorKey: 'extension',
              header: 'Extension',
              cell: (info) => info.getValue() || '-',
              size: 100,
            },
            {
              id: 'actions',
              header: 'Actions',
              size: 100,
              meta: {
                isEditable: false,
                isLastColumn: true,
              },
              cell: (info) => {
                const door = info.row.original

                return (
                  <ActionButtons
                    className={'justify-start'}
                    onDelete={() => handleNoteDelete(door.id)}
                    onEdit={() => setDoorToEdit(door)}
                  />
                )
              },
            },
          ]}
          enableSorting={true}
          enableGlobalSearch={true}
          searchPlaceholder={'Search doors by name...'}
        />
      </CollapsibleSection>
      <EditDoorProxyModal
        doorProxy={doorToEdit}
        show={!!doorToEdit}
        setShow={(show) => {
          if (!show) {
            setDoorToEdit(null)
          }
        }}
      />
    </>
  )
}
