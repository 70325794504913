import styled from 'styled-components/macro'

export const Label = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #475467;
  display: flex;
  align-items: center;
  gap: 4px;
`
export const ValueBig = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 38px;
  color: #101828;
`
export const PopIncomeInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-right: 50px;
  min-width: 300px;
`

export const CardTitle = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 38px;
  /* identical to box height, or 173% */

  /* Gray/900 */

  color: #101828;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
