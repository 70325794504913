import { zodResolver } from '@hookform/resolvers/zod'
import FbButton from 'components/FbUI/FbButton'
import { TextInput } from 'components/FormUtils/TextInput'
import { Modal } from 'components/UI/Modal/Modal'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import apiService from 'services/api'
import { z } from 'zod'

export interface InviteUserModalProps {
  existingUserEmails: string[]
  companyDomain: string
}

const createEmailSchema = (existingEmails: string[], companyDomain: string) =>
  z
    .string()
    .email()
    .refine((email) => email.endsWith(companyDomain), {
      message: `Email must match your team's work email (${companyDomain})`,
    })
    .refine((email) => !existingEmails.includes(email.toLowerCase()), {
      message: 'User is already part of your team',
    })

export function InviteUserModal(props: InviteUserModalProps) {
  const api = apiService()
  const [showModal, setShowModal] = useState<boolean>(false)

  const formSchema = z.object({
    email: createEmailSchema(props.existingUserEmails, props.companyDomain),
  })

  type FormSchema = z.infer<typeof formSchema>

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<FormSchema>({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(formSchema),
  })

  function openModal() {
    setShowModal(true)
  }

  function hideModal() {
    setShowModal(false)
    reset()
  }

  const onSubmit = handleSubmit(async (values) => {
    await api.inviteUser(values.email)
    toast.success('Invite sent succesfully!')
    hideModal()
  })

  return (
    <>
      <FbButton onClick={() => openModal()}>Invite User</FbButton>
      <Modal
        open={showModal}
        title={'Invite User'}
        description={
          "Enter your teammate's work email to invite them to join you on First Bite."
        }
        onOpenChange={(open) => !open && hideModal()}
        loading={isSubmitting}
        onAccept={onSubmit}
        acceptButtonText={'Invite'}
      >
        <TextInput
          name="email"
          control={control}
          type="text"
          label={'Work Email'}
          placeholder="name@company.com"
        />
      </Modal>
    </>
  )
}
