import { Column as RTColumn } from '@tanstack/react-table'
import { IColumnVisibility } from 'components/DataTable/types'
import { useAuth } from 'context/authentication/useAuth'
import { createStore } from 'zustand'
import { persist } from 'zustand/middleware'
import { useColumnStoreContext } from './useColumnsStoreContext'

export interface Column {
  id: string
  title: string
  isEditable: boolean
  isHidden: boolean
  isFirstColumn?: boolean
  isLastColumn?: boolean
}

export interface ColumnGroup {
  id: string
  title: string
  columns: Column[]
}

interface ColumnState {
  groups: ColumnGroup[]
  tableKey: string
  savedPresetsIdentifier?: string
  defaultColumnVisibility?: IColumnVisibility<unknown>
  selectedColumns?: Column[]
  defaultSelectedColumns?: Column[]
  preferredGroupOrder?: string[]
}

interface ColumnActions {
  setSelectedColumns: (columns: Column[]) => void
  initializeTableColumns: (columns: RTColumn<unknown, unknown>[]) => void
}

export type ColumnStoreInternal = ColumnState & ColumnActions

export const createColumnsStore = <T>({
  tableKey,
  savedPresetsIdentifier,
  defaultColumnVisibility,
  preferredGroupOrder,
}: {
  tableKey: string
  savedPresetsIdentifier?: string
  defaultColumnVisibility?: IColumnVisibility<T>
  preferredGroupOrder?: string[]
}) => {
  const { user } = useAuth()
  const LOCAL_STORAGE_KEY_PREFIX = `${user?.id}-columns-${tableKey}`
  return createStore<ColumnStoreInternal>()(
    persist(
      (set) => ({
        groups: [] as ColumnGroup[],
        defaultColumnVisibility,
        tableKey,
        preferredGroupOrder,
        savedPresetsIdentifier: savedPresetsIdentifier,
        selectedColumns: undefined,
        defaultSelectedColumns: undefined,
        setSelectedColumns: (selectedColumns) => set({ selectedColumns }),
        initializeTableColumns: (columns) => {
          const columnGroups = columns.reduce(
            (acc, column) => {
              const meta = (column.columnDef.meta || {}) as {
                headerTitle?: string
                headerGroup?: string
                headerDisableOrdering?: boolean
                isEditable?: boolean
                isFirstColumn?: boolean
                isLastColumn?: boolean
              }

              const { headerGroup } = {
                headerGroup: 'Ungrouped', // Default group name
                ...meta,
              }
              if (headerGroup) {
                if (!acc[headerGroup]) {
                  acc[headerGroup] = {
                    id: headerGroup,
                    title: headerGroup,
                    columns: [],
                  }
                }
                const columnTitle =
                  typeof column.columnDef.header === 'function'
                    ? meta.headerTitle
                    : column.columnDef.header

                acc[headerGroup].columns.push({
                  id: column.id ?? columnTitle!,
                  title: columnTitle!,
                  isEditable: meta?.isEditable ?? true,
                  isHidden:
                    column.columnDef.id === 'expanded-row' ||
                    meta?.headerDisableOrdering ||
                    false,
                  isFirstColumn: meta?.isFirstColumn ?? false,
                  isLastColumn: meta?.isLastColumn ?? false,
                })
              }
              return acc
            },
            {} as Record<string, ColumnGroup>
          )

          let sortedGroups = Object.values(columnGroups)
          if (preferredGroupOrder) {
            // Order following the preferredGroupOrder, if not found, add to the end
            sortedGroups = sortedGroups.sort((a, b) => {
              const aIndex = preferredGroupOrder.indexOf(a.id)
              const bIndex = preferredGroupOrder.indexOf(b.id)
              return (
                (aIndex === -1 ? Infinity : aIndex) -
                (bIndex === -1 ? Infinity : bIndex)
              )
            })
          }

          set({
            defaultSelectedColumns: sortedGroups
              .flatMap((group) => group.columns)
              .filter((column) => {
                const hideColumn =
                  defaultColumnVisibility?.[
                    column.id as keyof typeof defaultColumnVisibility
                  ] === false || column.isHidden
                return !hideColumn
              }),
            groups: sortedGroups,
          })
        },
      }),
      {
        name: LOCAL_STORAGE_KEY_PREFIX,
        partialize: (state) => ({
          // We only want to persist the column order and visibility
          selectedColumns: state.selectedColumns,
        }),
      }
    )
  )
}

export type ColumnStore = ReturnType<typeof createColumnsStore>

export function getDefaultSelectedColumns() {
  const groups = useColumnStoreContext((state) => state.groups)
  const defaultColumnVisibility = useColumnStoreContext(
    (state) => state.defaultColumnVisibility
  )
  const selectedColumns = useColumnStoreContext(
    (state) => state.selectedColumns
  )

  if (selectedColumns) {
    return selectedColumns
  }

  return groups
    .flatMap((group) => group.columns)
    .filter((column) => {
      const hideColumn =
        defaultColumnVisibility?.[
          column.id as keyof typeof defaultColumnVisibility
        ] === false || column.isHidden
      return !hideColumn
    })
}

export function getColumnVisibility() {
  const defaultColumnVisibility = useColumnStoreContext(
    (state) => state.defaultColumnVisibility
  )
  const groups = useColumnStoreContext((state) => state.groups)
  const selectedColumns = useColumnStoreContext(
    (state) => state.selectedColumns
  )
  const allColumns = groups.flatMap((group) => group.columns)
  if (!selectedColumns) {
    return defaultColumnVisibility
  }

  return (
    allColumns.reduce(
      (acc, column) => {
        return {
          ...acc,
          [column.id]:
            selectedColumns?.some(
              (selectedColumn) => selectedColumn.id === column.id
            ) ?? false,
        }
      },
      {} as Record<string, boolean>
    ) ?? defaultColumnVisibility
  )
}

export function getColumnOrder() {
  const groups = useColumnStoreContext((state) => state.groups)
  const selectedColumns = useColumnStoreContext(
    (state) => state.selectedColumns
  )

  // Keep the order of all columns, only updating the order of selected columns
  const allColumns = groups.flatMap((group) => group.columns)

  const sortedColumns = allColumns.sort((a, b) => {
    // Check for isFirstColumn attribute
    if (a.isFirstColumn) return -1
    if (b.isFirstColumn) return 1

    if (a.isLastColumn) return 1
    if (b.isLastColumn) return -1

    const aIndex = selectedColumns?.findIndex(
      (selectedColumn) => selectedColumn.id === a.id
    )
    const bIndex = selectedColumns?.findIndex(
      (selectedColumn) => selectedColumn.id === b.id
    )
    return (aIndex ?? -1) - (bIndex ?? -1)
  })

  return sortedColumns.map((column) => column.id)
}

export function getReadOnlyColumns() {
  const groups = useColumnStoreContext((state) => state.groups)

  return groups
    .flatMap((group) => group.columns)
    .filter((column) => !column.isEditable && !column.isHidden)
}

export function getHiddenColumns() {
  const groups = useColumnStoreContext((state) => state.groups)

  return groups
    .flatMap((group) => group.columns)
    .filter((column) => column.isHidden)
}

export function getAllColumns() {
  const groups = useColumnStoreContext((state) => state.groups)
  return groups.flatMap((group) => group.columns)
}
