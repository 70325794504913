import { FilterChipCloseButton, FilterChipContainer } from './FilterChipStyles'

export function FilterChipSearch({
  search,
  onClose,
  searchLabel = 'Search',
}: {
  search: string
  onClose?: () => void
  searchLabel?: string
}) {
  if (!search) return null
  return (
    <FilterChipContainer>
      <span>
        {`${searchLabel}: `}
        <b>{search}</b>
      </span>
      {onClose && <FilterChipCloseButton onClick={onClose} />}
    </FilterChipContainer>
  )
}
