import { cn } from 'components/UI/cn'
import { X } from 'lucide-react'
import * as React from 'react'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import { Input } from '../Input/input'
import { Label } from '../Label/label'

interface TextInputProps<T extends FieldValues> {
  control: Control<T>
  name: Path<T>
  label?: string
  placeholder?: string
  type?: string
  maxLength?: number
  optional?: boolean
  prefix?: string
  suffix?: string
  style?: React.CSSProperties
  sublabel?: string
  noMargin?: boolean
  disabled?: boolean
  className?: string
  helperText?: string
  min?: number
  max?: number
  onBlur?: () => void
  onClear?: () => void
  onChange?: (value: any) => void
}

export function TextInput<T extends FieldValues>(props: TextInputProps<T>) {
  const {
    field: { ref, onChange, value },
    fieldState: { error },
  } = useController({ name: props.name, control: props.control })

  const [isFocused, setIsFocused] = React.useState(false)
  const hasValue = value !== ''

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsFocused(true)
    let newValue: string | number = e.target.value

    if (props.type === 'number') {
      if (isNaN(e.target.valueAsNumber)) {
        newValue = ''
      } else {
        newValue = e.target.valueAsNumber
      }
    }

    onChange(newValue)
    props.onChange?.(newValue)
  }

  const handleBlur = () => {
    setIsFocused(!!value)
    props.onBlur?.()
  }

  return (
    <div
      className={cn(
        'relative w-full',
        props.noMargin ? 'mt-0' : 'mt-[10px]',
        '!mb-0',
        props.className
      )}
    >
      <div>
        {props.label && (
          <Label
            htmlFor={props.name}
            className={cn(
              'absolute transition-all duration-200 z-10',
              isFocused || hasValue
                ? '-top-2 text-xs px-1 bg-gradient-to-t from-white from-50% to-transparent to-50%'
                : 'top-1/2 -translate-y-1/2 pointer-events-none',
              props.prefix
                ? isFocused || hasValue
                  ? 'left-3'
                  : 'left-10'
                : 'left-3'
            )}
          >
            {props.label}
            {props.optional && (
              <span className="italic text-sm font-normal"> - optional</span>
            )}
          </Label>
        )}
        <div className="relative border border-input rounded-md">
          {props.onClear && !!value && (
            <button
              className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-600 hover:text-gray-800 focus:outline-none z-10"
              onClick={props.onClear}
            >
              <X size={18} />
            </button>
          )}
          <div className="flex">
            {props.prefix && (
              <span className="inline-flex items-center pl-3 bg-white text-sm text-gray-500 rounded-l-md">
                {props.prefix}
              </span>
            )}
            <Input
              id={props.name}
              onChange={handleOnChange}
              onFocus={() => setIsFocused(true)}
              onBlur={handleBlur}
              min={props.min}
              max={props.max}
              type={props.type}
              value={value ?? ''}
              ref={ref}
              placeholder={
                isFocused || hasValue ? props.placeholder : undefined
              }
              maxLength={props.maxLength}
              style={props.style}
              disabled={props.disabled}
              inputMode={props.type === 'number' ? 'numeric' : undefined}
              pattern={props.type === 'number' ? '[0-9]*' : undefined}
              onKeyDown={(e) => {
                if (
                  props.type === 'number' &&
                  !/^\d$/.test(e.key) &&
                  ![
                    'Backspace',
                    'Delete',
                    'ArrowLeft',
                    'ArrowRight',
                    'Tab',
                  ].includes(e.key)
                ) {
                  e.preventDefault()
                }
              }}
              className={cn(
                error?.message && 'border-red-500',
                props.prefix && 'rounded-l-none',
                props.suffix && 'rounded-r-none',
                props.onClear && 'pr-10'
              )}
            />
            {props.suffix && (
              <span className="inline-flex items-center pr-3 text-sm text-gray-500 bg-white rounded-r-md">
                {props.suffix}
              </span>
            )}
          </div>
        </div>
      </div>
      {props.sublabel && (
        <p className="mt-1 text-gray-600 text-sm font-normal leading-5">
          {props.sublabel}
        </p>
      )}
      {props.helperText && (
        <p className="mt-1 text-gray-600 text-sm font-normal leading-5">
          {props.helperText}
        </p>
      )}
      {error?.message && (
        <p className="mt-1 text-red-500 text-sm">{error.message}</p>
      )}
    </div>
  )
}
