import { ActivityV2 } from 'models/activityV2'
import styled from 'styled-components'

export type CellEventFormatterProps = {
  event: ActivityV2
}

export const contentTypeMap: Record<string, any> = {
  contact: 'Contact',
  contactcompany: 'Company',
  contactnote: 'Contact Note',
  companynote: 'Company Note',
  deal: 'Deal',
  product: 'Product',
  savedview: 'Saved Filter',
  campaign: 'Campaign',
}

export const convertSnakeCaseToSentenceCase = (str: string) => {
  const formattedStr = str.split('_').join(' ')
  return formattedStr
}

const simpleDateFormatter = (date: string) => {
  if (!date) return ''
  return new Date(date).toLocaleDateString()
}

const simpleBooleanToYesNo = (value: boolean) => (value ? 'Yes' : 'No')

export const getFieldFormatter = (field: string, contentType: string) => {
  const mapping: Record<string, Record<string, any>> = {
    contactcompany: {
      created: simpleDateFormatter,
      modified: simpleDateFormatter,
    },
    contact: {
      created: simpleDateFormatter,
      modified: simpleDateFormatter,
      consent_to_contact: simpleBooleanToYesNo,
    },
    deal: {
      created: simpleDateFormatter,
      modified: simpleDateFormatter,
      close_date_override: simpleDateFormatter,
      last_datetime_stage_changed: simpleDateFormatter,
      datetime_closed: simpleDateFormatter,
    },
    product: {
      estimated_sales_potential: (value: string) => `${value}`,
    },
  }
  return mapping[contentType]?.[field] || ((value: string) => value)
}

export const GreenBold = styled.b`
  color: #087443;
`

export const PillContainer = styled.span`
  display: inline-flex;
  margin: 0 2px;
`
