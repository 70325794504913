import { IconMiniButton } from 'components/FbUI/FbButton'
import CreateCompanyButton from 'components/Modals/CompanyModal/CreateCompanyButton'
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BsDownload } from 'react-icons/bs'
import { CompanyTypeSlug } from '../../models/companies'

interface OpportunitiesTableFooterControlsProps {
  companyTypeSlug: CompanyTypeSlug
  onDownloadButtonClick: () => void
  hideCreateCompanyButton?: boolean
}

const OpportunitiesTableFooterControls: React.FC<
  OpportunitiesTableFooterControlsProps
> = ({ companyTypeSlug, onDownloadButtonClick, hideCreateCompanyButton }) => {
  return (
    <>
      {!hideCreateCompanyButton ? (
        <CreateCompanyButton companyTypeSlug={companyTypeSlug} />
      ) : null}
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip-download">
            Download limited to 10,000 rows
          </Tooltip>
        }
      >
        <div>
          <IconMiniButton onClick={onDownloadButtonClick}>
            <BsDownload size={18} />
          </IconMiniButton>
        </div>
      </OverlayTrigger>
    </>
  )
}

export default OpportunitiesTableFooterControls
