import { ColumnDef } from '@tanstack/react-table'
import styled from 'styled-components'
import { ClientDataTable } from '../../ClientDataTable/ClientDataTable'

export function ContactsPreviewTable<T extends Record<string, any>>({
  data,
  columns,
}: {
  data: T[]

  columns: ColumnDef<T, any>[]
}) {
  return (
    <TableContainer>
      <ClientDataTable data={data} columns={columns} height={400} />
    </TableContainer>
  )
}

const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  overflow: hidden;
`
