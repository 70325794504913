import apiService from 'services/api'
import ExportModal, { ExportModalProps } from './ExportModal'
import { downloadFile } from 'utils/csvDownloader'
import { COMPANIES_COLUMNS } from 'components/Tables/CompaniesTable/companiesColumnInfo'
import { createExportColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { getCommonChainProxyExportColumns } from 'components/DataExpansionComponents/Common/commonExportColumns'

export default function ContactCompanyExportModal(
  props: ExportModalProps & {
    subCategoryName: string
  }
) {
  const api = apiService()

  const exportContactCompanies = async ({
    filter,
    selectedColumns,
    exportType,
    controller,
  }: {
    filter: Record<string, unknown>
    selectedColumns: string[]
    exportType: 'csv' | 'xlsx'
    controller?: AbortController
  }) => {
    const res = await api.exportContactCompanies(
      filter,
      selectedColumns,
      exportType,
      controller?.signal
    )
    downloadFile(
      res,
      `First Bite Export: Opportunities - ${new Date().toLocaleDateString()}.${exportType}`
    )
  }

  const companyColumnHelper = createExportColumnHelper({
    columns: COMPANIES_COLUMNS,
  })

  const columns = [
    {
      title: 'Opportunity Attributes',
      elements: [
        companyColumnHelper.retrieveColumn('name'),
        companyColumnHelper.retrieveColumn('domain'),
        companyColumnHelper.retrieveColumn('address'),
        companyColumnHelper.retrieveColumn('city'),
        companyColumnHelper.retrieveColumn('state'),
        companyColumnHelper.retrieveColumn('zipcode'),
        companyColumnHelper.retrieveColumn('country'),
        companyColumnHelper.retrieveColumn('created'),
        companyColumnHelper.retrieveColumn('category'),
        companyColumnHelper.retrieveColumn('type'),
      ],
    },
    {
      title: 'Account Details',
      elements: [...getCommonChainProxyExportColumns()],
    },
  ]

  return (
    <ExportModal
      title={`Select Columns for Unmatched ${props.subCategoryName} Download`}
      subTitle="Download up to 10,000 opportunities at a time, with any of the following columns. Any filters already applied will remain active."
      submitText="Export Companies"
      columns={columns}
      exportAPIAction={exportContactCompanies}
      {...props}
    />
  )
}
