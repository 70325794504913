import { FilterGroup } from 'components/Filters/components/FilterModal'
import { SelectFilter } from 'components/Filters/fields/SelectFilter'
import { FilterStore } from 'components/Filters/FilterStore'
import apiService from 'services/api'
import { createFilterColumnHelper } from '../commonColumnHelpers'
import { COMMON_COLUMNS } from '../CommonColumnsInfo'
import { RangeFilter } from 'components/Filters/fields/RangeFilter'

export function CommonFiltersetAccountGroup({
  filterStore,
  campaignOptions,
  hideCampaignsFilter: hideCampaigns,
}: {
  filterStore: FilterStore
  campaignOptions?: any
  hideCampaignsFilter?: boolean
}) {
  const api = apiService()
  const { data: stageOptions } = api.useGetSalesStageOptions(true)
  const { data: userOptions } = api.useGetUserOptions(true)
  const { data: tagsOptions } = api.useGetTagsOptions(true)
  const { data: distributorOptions } =
    api.useGetCompanyDistributorsOptions(true)
  const commonFilterHelper = createFilterColumnHelper({
    columns: COMMON_COLUMNS,
  })

  return (
    <FilterGroup title="Account">
      {campaignOptions && !hideCampaigns && (
        <SelectFilter
          {...commonFilterHelper.retrieveProps('campaigns')}
          options={campaignOptions as any}
          filterStore={filterStore}
        />
      )}
      <SelectFilter
        {...commonFilterHelper.retrieveProps('sales_stages')}
        options={stageOptions as any}
        filterStore={filterStore}
      />
      <SelectFilter
        {...commonFilterHelper.retrieveProps('account_owners')}
        options={userOptions as any}
        filterStore={filterStore}
      />
      <SelectFilter
        {...commonFilterHelper.retrieveProps('taglist')}
        options={tagsOptions as any}
        filterStore={filterStore}
      />
      <RangeFilter
        {...commonFilterHelper.retrieveProps('deal_count')}
        store={filterStore}
      />
      <RangeFilter
        {...commonFilterHelper.retrieveProps('note_count')}
        store={filterStore}
      />
      <RangeFilter
        {...commonFilterHelper.retrieveProps('contact_count')}
        store={filterStore}
      />
      <SelectFilter
        {...commonFilterHelper.retrieveProps('distributors')}
        filterStore={filterStore}
        options={distributorOptions as any}
      />
    </FilterGroup>
  )
}
