export const DOORS_TABLE_QUERYKEY = 'doorsTable'
export const DASHBOARD_TABLE_QUERYKEY = 'doorsTable-stageOnly'

export const PERSONAL_EMAIL_DOMAINS = ['gmail.com', 'yahoo.com', 'hotmail.com']

export const CONTACT_TITLES = [
  'Executive Chef',
  'Culinary Director',
  'Head of Culinary',
  'VP of Marketing',
  'Head of Marketing',
  'CMO',
  'General Manager',
  'GM',
  'President',
  'Owner',
  'Founder',
  'Co-founder',
  'District Manager',
  'COO',
  'VP of Operations',
  'Head of Operations',
  'Director of Operations',
  'Chef',
  'Investor',
  'VP of Brand',
  'Head of Brand',
  'Brand Manager',
  'Marketing Manager',
  'Manager of Operations',
  'Restaurant Manager',
  'Assistant Manager',
  'Cook',
  'Line Cook / Line Chef',
  'Line Chef',
  'Grill Cook',
  'Deli Cook',
  'Liquor Manager',
  'Cashier',
  'Crew member',
  'Host / Hostess',
  'Server',
]

export const TAG_COLORS = [
  { background: '#E3E1DA', text: '#464646' },
  { background: '#D1E8D5', text: '#13462C' },
  { background: '#94D5A9', text: '#13462C' },
  { background: '#AFF2C4', text: '#13462C' },
  { background: '#DDFBBA', text: '#0B4F30' },
  { background: '#FEF5B4', text: '#463B05' },
  { background: '#FFE0B2', text: '#553105' },
  { background: '#FFD1C9', text: '#642509' },
  { background: '#E2E2F0', text: '#320967' },
  { background: '#DDEEF4', text: '#0E3C45' },
]
