import { keepPreviousData, useQuery } from '@tanstack/react-query'
import {
  CellContext,
  ColumnDef,
  createColumnHelper,
} from '@tanstack/react-table'
import { JobActionButtons } from 'components/Buttons/ActionButtons'
import { DataTable, DataTableProvider } from 'components/DataTable'
import { JobStatusPill } from 'components/FbUI/JobStatusPill'
import { ContactRequestJobDetailsModal } from 'components/Modals/ContactRequestJobDetailsModal'
import { OverflownTextTooltip } from 'components/OverflownTextTooltip'
import { ContactRequestJobResponse } from 'models/contact_request'
import { useMemo, useState } from 'react'
import { MdPersonOutline } from 'react-icons/md'
import apiService from 'services/api'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import { featureFlagService } from 'utils/featureFlagService'
import { getTableQueryKey } from 'utils/getTableQueryKey'
import { usePaginationURLParams } from 'utils/usePaginationURLParams'
import { dataTableSortingStoreRepo } from '../../DataTable/DataTableSorting/DataTableSortingStore'
import { DataTableContainer } from '../../DataTable/UI'
import { CampaignsCell } from '../../FbUI/StagePill'

const PAGE_SIZE = 100

type SortableFields = (keyof ContactRequestJobResponse | string)[]

const sortableFields: SortableFields = ['created', 'modified']

interface ContactRequestsTableProps {
  campaignId?: number
  tableKey: string
}

function ContactRequestsTableComponent(props: ContactRequestsTableProps) {
  const api = apiService()
  const featureFlag = featureFlagService()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedJobId, setSelectedJobId] = useState<number | null>(null)
  const columnHelper = createColumnHelper<ContactRequestJobResponse>()

  const [pagination, setPagination] = usePaginationURLParams(
    PAGE_SIZE,
    `contact-request-jobs-page`
  )

  const { sorting } = dataTableSortingStoreRepo.getStore(props.tableKey)()
  const sortParams = useMemo(() => {
    const params: Record<string, string> = {}
    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }
    return params
  }, [sorting])

  const filterParams = {
    ...sortParams,
    campaign_id: props.campaignId,
  }

  const TABLE_QUERY_KEY = getTableQueryKey({
    tableKey: props.tableKey,
    filterParams: filterParams,
    page: pagination.pageIndex + 1,
  })

  const { data, isFetching } = useQuery({
    queryKey: TABLE_QUERY_KEY,
    queryFn: async ({ signal }) => {
      const response = await api.fetchContactRequestJobs(
        {
          ...filterParams,
          limit: PAGE_SIZE,
          page: pagination.pageIndex + 1,
        },
        signal
      )
      return response
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 5,
  })

  const handleViewJobDetails = (jobId: number) => {
    setSelectedJobId(jobId)
    setShowModal(true)
  }

  const columns: ColumnDef<ContactRequestJobResponse, any>[] = useMemo(() => {
    let cols = [
      columnHelper.accessor('sub_category', {
        header: 'Type',
        size: 150,
        cell: (info) => <p>{info.row.original.sub_category.name}</p>,
      }),
      columnHelper.accessor('user', {
        header: 'Created By',
        size: 120,
        cell: (info) => {
          const user = info.row.original.user
          return (
            <div className="d-flex align-items-center gap-1">
              <MdPersonOutline size={20} />
              <div>
                {user.first_name} {user.last_name}
              </div>
            </div>
          )
        },
      }),
      columnHelper.accessor('campaign', {
        header: 'Campaign',
        size: 120,
        cell: (info) => {
          const value = info.getValue()
          if (!value) return '-'
          return <CampaignsCell campaigns={[value]} />
        },
      }),
      columnHelper.display({
        id: 'contact_companies',
        header: 'Companies',
        size: 200,
        cell: (info) => {
          const contactCompanies = info.row.original.contact_companies

          if (!contactCompanies || contactCompanies.length === 0) return '-'

          const companyNames = contactCompanies.map((company) => company.name)
          const companyNamesSorted = companyNames.sort()
          const display = companyNamesSorted.slice(0, 25).join(', ')
          const totalCompanies = companyNamesSorted.length

          return (
            <OverflownTextTooltip
              tooltipText={`${display}, ... (${totalCompanies})`}
              maxLines={2}
            >
              {display}
            </OverflownTextTooltip>
          )
        },
      }),
      columnHelper.display({
        id: 'contact_companies_count',
        header: 'Unique Companies',
        size: 100,
        cell: (info) => {
          const contactCompanies = info.row.original.contact_companies
          const uniqueCount = contactCompanies
            ? new Set(contactCompanies.map((company) => company.id)).size
            : 0
          return <p>{uniqueCount}</p>
        },
      }),
      columnHelper.accessor('max_contacts', {
        header: 'Max Contacts Per Company',
        size: 150,
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        size: 150,
        cell: (info) => (
          <div>
            <JobStatusPill
              statusValue={info.row.original.status.id}
              statusLabel={info.row.original.status.label}
            />
          </div>
        ),
      }),
      columnHelper.accessor('created', {
        header: 'Created',
        size: 150,
        cell: (info) => {
          const date = new Date(info.row.original.created)
          const datePart = date.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })
          const timePart = date.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          })
          return (
            <>
              {datePart}
              <br />
              {timePart}
            </>
          )
        },
        enableSorting: true,
      }),
      columnHelper.accessor('modified', {
        header: 'Last Updated',
        size: 150,
        cell: (info) => {
          const date = new Date(info.row.original.modified)
          const datePart = date.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })
          const timePart = date.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          })
          return (
            <>
              {datePart}
              <br />
              {timePart}
            </>
          )
        },
        enableSorting: true,
      }),
      {
        id: 'actions',
        meta: {
          isLastColumn: true,
        },
        header: () => <div className="ml-[-8px]">Actions</div>,
        size: 80,
        cell: (info: CellContext<ContactRequestJobResponse, unknown>) => {
          return (
            <JobActionButtons
              className={'justify-center pr-0'}
              onView={() => handleViewJobDetails(info.row.original.id)}
            />
          )
        },
      },
    ]

    if (!featureFlag.enableCampaigns) {
      cols = cols.filter((col) => !col.id?.includes('campaigns'))
    }

    return cols
  }, [columnHelper, handleViewJobDetails])

  return (
    <>
      <DataTableContainer>
        <DataTable
          tableKey={props.tableKey}
          loading={isFetching}
          stickyLastColumn={true}
          data={data?.results ?? []}
          columns={columns}
          virtualizeRows={false}
          isPaginationEnabled={true}
          sortableFields={sortableFields}
          defaultSort={[{ id: 'created' as any, desc: true }]}
          paginationOptions={{
            pageCount: Math.ceil((data?.count ?? 0) / PAGE_SIZE),
            setPagination: setPagination,
            pagination: pagination,
            isPaginationLoading: isFetching,
          }}
        />
      </DataTableContainer>
      <ContactRequestJobDetailsModal
        open={showModal}
        onClose={() => setShowModal(false)}
        jobId={selectedJobId}
      />
    </>
  )
}

export function ContactRequestsTable(props: ContactRequestsTableProps) {
  return (
    <ColumnsStoreProvider tableKey={props.tableKey}>
      <DataTableProvider tableKey={props.tableKey}>
        <ContactRequestsTableComponent {...props} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}
