import { useRef } from 'react'
import { ColumnStore, createColumnsStore } from './ColumnsStore'

import { createContext } from 'react'
import { FilterIdentifier } from '../../models/saved_view'

export const ColumnStoreContext = createContext<ColumnStore | null>(null)

export function ColumnsStoreProvider({
  children,
  tableKey,
  defaultColumnVisibility,
  preferredGroupOrder,
  savedPresetsIdentifier,
}: {
  children: React.ReactNode
  tableKey: string
  defaultColumnVisibility?: Record<string, boolean>
  preferredGroupOrder?: string[]
  savedPresetsIdentifier?: FilterIdentifier
}) {
  const storeRef = useRef<ColumnStore | null>(null)
  if (!storeRef.current) {
    storeRef.current = createColumnsStore({
      tableKey,
      defaultColumnVisibility,
      preferredGroupOrder,
      savedPresetsIdentifier,
    })
  }
  return (
    <ColumnStoreContext.Provider value={storeRef.current}>
      {children}
    </ColumnStoreContext.Provider>
  )
}
