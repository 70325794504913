import axios from 'axios'
import { useMemo } from 'react'

export function useIsQueryResultNotFound({
  error,
  isError,
}: {
  error: Error | null
  isError: boolean
}) {
  const notFound = useMemo(() => {
    if (
      isError &&
      axios.isAxiosError(error) &&
      error?.response?.status === 404
    ) {
      return true
    }
    return false
  }, [error, isError])
  return notFound
}
