import { useVirtualizer } from '@tanstack/react-virtual'
import FbButton from 'components/FbUI/FbButton'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'components/UI/BaseDropdown/dropdown-base'
import { cn } from 'components/UI/cn'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im'
import { FilterButton } from '../components/FilterButton'
import { FilterCloseButton } from '../components/FilterCloseButton'
import { FilterStore } from '../FilterStore'

export interface SelectFilterOption {
  value: string
  label: string
}

export interface SelectFilterProps {
  filterKey: string
  title: string
  options?: SelectFilterOption[]
  filterStore: FilterStore
  hideSearch?: boolean
}

export function SelectFilter(props: SelectFilterProps) {
  const {
    pendingValueFilters: pending,
    setPendingValueFilters: setPending,
    clearPendingValueFilter: clearPending,
    registerFilterTitle,
  } = props.filterStore()
  const filter = pending[props.filterKey] ?? []
  const isActive = !!filter.length

  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [internalFilters, setInternalFilters] = useState(filter ?? [])

  const filteredOptions = props.options
    ?.filter((o) => {
      const regex = new RegExp(search, 'gi')
      const labelTest = regex.test(o.label)
      const isSelected = internalFilters.some((s) => s?.value === o?.value)
      return isSelected || labelTest
    })
    .sort((a, b) => {
      const isASelected = internalFilters.some((s) => s?.value === a.value)
        ? 1
        : -1
      const isBSelected = internalFilters.some((s) => s?.value === b.value)
        ? 1
        : -1
      return isBSelected - isASelected
    })

  const activeValueLabel = useMemo(() => {
    const length = filter?.length
    if (!length) return 'All'

    const firstActiveLabel = props.options?.find(
      (o) => o.value === filter[0]?.value
    )
    if (length === 1 && firstActiveLabel) {
      return firstActiveLabel.label
    }

    return `${length} selected`
  }, [filter])

  function handleInternalChange(option: SelectFilterOption, selected: boolean) {
    if (selected) {
      setInternalFilters((prev) =>
        prev.filter((f) => f?.value !== option.value)
      )
    } else {
      setInternalFilters((prev) => [...prev, option])
    }
  }

  function selectAll() {
    if (!props.options) return
    setInternalFilters(
      props.options.map((o) => ({
        value: o.value,
        label: o.label,
      }))
    )
  }

  function selectNone() {
    setInternalFilters([])
  }

  function clearFilters() {
    clearPending(props.filterKey)
  }

  function saveFilters() {
    setPending(
      props.filterKey,
      internalFilters.map((f) => ({
        value: f.value,
        label: f.label,
      }))
    )
  }

  // Syncs the internal filters with the filters in the context
  useEffect(() => {
    setInternalFilters(filter)
  }, [pending, open])

  useEffect(() => {
    registerFilterTitle(props.filterKey, props.title)
  }, [])

  function handleOpenChange(open: boolean) {
    if (!open) {
      // Apply filters when the dropdown closes
      saveFilters()
    }
    setOpen(open)
  }

  if (!props.options || !filteredOptions) return null

  return (
    <DropdownMenu open={open} onOpenChange={handleOpenChange}>
      <div className={'relative d-flex'}>
        <DropdownMenuTrigger asChild>
          <FilterButton
            open={open}
            title={props.title}
            onClick={() => setOpen(!open)}
            isActive={isActive}
            activeValueLabel={activeValueLabel}
          />
        </DropdownMenuTrigger>
        <FilterCloseButton isActive={isActive} onClick={() => clearFilters()} />
      </div>

      <DropdownMenuContent
        forceMount={true}
        side={'bottom'}
        className={cn('bg-white w-[260px] lg:w-[235px] p-2')}
        style={{
          zIndex: 9999,
        }}
        sideOffset={5}
      >
        {!props.hideSearch && (
          <Form.Control
            type="text"
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            placeholder="Search"
          />
        )}

        <ItemsList
          options={filteredOptions}
          onSelect={handleInternalChange}
          selected={internalFilters}
          column={props.filterKey}
        />
        <hr className={'my-2'} />
        <div className={'w-full'}>
          <div className={'flex items-center justify-between gap-2'}>
            <FbButton
              size={'sm'}
              className="flex-1"
              variant={'warning'}
              onClick={() => selectAll()}
            >
              All
            </FbButton>
            <FbButton
              size={'sm'}
              className="flex-1"
              variant={'secondary'}
              onClick={() => selectNone()}
            >
              None
            </FbButton>
          </div>

          <FbButton
            size={'sm'}
            className={'w-full mt-2'}
            onClick={() => {
              saveFilters()
              setOpen(false)
            }}
          >
            Apply
          </FbButton>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

function ItemsList(props: {
  options: SelectFilterOption[]
  selected: SelectFilterOption[]
  onSelect: (option: SelectFilterOption, selected: boolean) => void
  column: string
}) {
  const parentRef = useRef<HTMLDivElement>(null)

  const virtual = useVirtualizer({
    count: props.options?.length ?? 1,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 35,
  })

  return (
    <div
      className={'mt-2 w-full'}
      ref={parentRef}
      style={{
        height: `150px`,
        width: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <div
        style={{
          height: `${virtual.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {virtual.getVirtualItems().map((item) => {
          const o = props.options[item.index]
          const isSelected = props.selected.some((s) => s?.value === o?.value)
          if (!o) return null
          return (
            <div
              key={props.column + item.index}
              className={cn(
                'max-w-full cursor-pointer flex items-center py-2 px-3 gap-2 hover:bg-gray-200 text-sm overflow-ellipsis whitespace-nowrap'
              )}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${item.size}px`,
                transform: `translateY(${item.start}px)`,
              }}
              onClick={() => props.onSelect(o, isSelected)}
            >
              <div className={'flex-shrink-0'}>
                {isSelected ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
              </div>
              <div
                className={
                  'overflow-ellipsis overflow-hidden whitespace-nowrap'
                }
              >
                {o.label}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
