export interface DataTypeColumn {
  header: string
  group: string
  tooltipName?: string
  filterTitle?: string
}

export const createTableColumnHelper = <T>({
  columns,
  tooltips,
}: {
  columns: Record<keyof T, DataTypeColumn>
  tooltips?: Record<string, unknown>
}) => {
  return {
    retrieveColumn: (column: keyof T, overrides?: Record<string, any>) =>
      getTableColumn<T>({ columns, column, tooltips, overrides }),
  }
}

export const getTableColumn = <T>({
  columns,
  column,
  tooltips,
  overrides,
}: {
  columns: Record<keyof T, DataTypeColumn>
  column: keyof T
  tooltips?: Record<string, unknown>
  overrides?: Record<string, any>
}) => {
  const { header, group, tooltipName } = columns[column]
  const { meta: metaOverrides, ...restOverrides } = overrides || {}
  return {
    id: column,
    header,
    meta: {
      headerGroup: group,
      headerTitle: header,
      tooltip: tooltipName ? tooltips?.[tooltipName] : undefined,
      ...metaOverrides,
    },
    ...restOverrides,
  }
}

export const createExportColumnHelper = <T>({
  columns,
}: {
  columns: Record<keyof T, DataTypeColumn>
}) => {
  return {
    retrieveColumn: (column: keyof T) =>
      getExportColumn<T>({ columns, column }),
  }
}

export const getExportColumn = <T>({
  columns,
  column,
}: {
  columns: Record<keyof T, DataTypeColumn>
  column: keyof T
}) => {
  return [column, columns[column].header]
}

export const createFilterColumnHelper = <T>({
  columns,
}: {
  columns: Record<keyof T, DataTypeColumn>
}) => {
  return {
    retrieveTitle: (column: keyof T) => getFilterTitle<T>({ columns, column }),
    retrieveProps: (column: keyof T) => getFilterProps<T>({ columns, column }),
  }
}

export const getFilterTitle = <T>({
  columns,
  column,
}: {
  columns: Record<keyof T, DataTypeColumn>
  column: keyof T
}) => {
  return columns[column].filterTitle || columns[column].header
}

export const getAllFilterTitles = <T>({
  columns,
}: {
  columns: Record<keyof T, DataTypeColumn>
}) => {
  return Object.keys(columns).reduce(
    (acc, column) => {
      acc[column as keyof T] = getFilterTitle<T>({
        columns,
        column: column as keyof T,
      })
      return acc
    },
    {} as Record<keyof T, string>
  )
}

export const getFilterProps = <T>({
  columns,
  column,
}: {
  columns: Record<keyof T, DataTypeColumn>
  column: keyof T
}) => {
  return {
    filterKey: column,
    title: columns[column].header,
  }
}
