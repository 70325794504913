import apiService from 'services/api'
import ExportModal, { ExportModalProps } from './ExportModal'
import { downloadFile } from 'utils/csvDownloader'
import { DEALS_PIPELINE_COLUMNS } from 'components/DataExpansionComponents/Deals/dealsPipelineColumnInfo'
import { createExportColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'

export default function PipelineExportModal(props: ExportModalProps) {
  const api = apiService()

  const exportContacts = async ({
    filter,
    selectedColumns,
    exportType,
    controller,
  }: {
    filter: Record<string, unknown>
    selectedColumns: string[]
    exportType: 'csv' | 'xlsx'
    controller?: AbortController
  }) => {
    const res = await api.exportDealsPipeline(
      filter,
      selectedColumns,
      exportType,
      controller?.signal
    )
    downloadFile(
      res,
      `First Bite Export: Deals Pipeline - ${new Date().toLocaleDateString()}.${exportType}`
    )
  }

  const pipelineColumnHelper = createExportColumnHelper({
    columns: DEALS_PIPELINE_COLUMNS,
  })

  const columns = [
    {
      title: 'Company Information',
      elements: [
        pipelineColumnHelper.retrieveColumn('company_name'),
        pipelineColumnHelper.retrieveColumn('category'),
        pipelineColumnHelper.retrieveColumn('type'),
        pipelineColumnHelper.retrieveColumn('taro'),
        pipelineColumnHelper.retrieveColumn('taro_closed'),
        pipelineColumnHelper.retrieveColumn('domain'),
      ],
    },
    {
      title: 'Activity',
      elements: [
        pipelineColumnHelper.retrieveColumn('last_modified'),
        pipelineColumnHelper.retrieveColumn('days_since_last_modified'),
      ],
    },
    {
      title: 'Account Details',
      elements: [
        pipelineColumnHelper.retrieveColumn('sales_stages'),
        pipelineColumnHelper.retrieveColumn('account_owner'),
        pipelineColumnHelper.retrieveColumn('tag'),
        pipelineColumnHelper.retrieveColumn('count'),
        pipelineColumnHelper.retrieveColumn('closed'),
      ],
    },
  ]

  return (
    <ExportModal
      title="Select Columns for Pipeline Download"
      subTitle="Download up to 10,000 pipeline at a time, with any of the following columns. Any filters already applied will remain active."
      submitText="Download Pipeline"
      columns={columns}
      exportAPIAction={exportContacts}
      {...props}
    />
  )
}
