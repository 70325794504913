import { ContactsTable } from 'components/Tables/ContactsTable/ContactsTable'
import { useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { queryClient } from '../../services/queryClient'
import { formatInteger } from '../../utils/formatting'
import FbButton from '../FbUI/FbButton'
import NewContactModal from '../Modals/NewContactModal'
import { CollapsibleSection } from '../UI/CollapsibleSection/CollapsibleSection'

interface ContactsTableCardProps {
  data: any
}

const ContactsTableSection = (props: ContactsTableCardProps) => {
  const [showNewContactModal, setShowNewContactModal] = useState(false)
  const [contactsCount, setContactsCount] = useState<number>(0)
  const TABLE_KEY = `company-contacts-table-${props.data.id}`

  const refetchContacTable = () => {
    queryClient.refetchQueries({ queryKey: [TABLE_KEY] })
  }

  return (
    <>
      <CollapsibleSection
        forceClose={contactsCount < 1}
        summary={
          <div className={'flex justify-between items-center w-full'}>
            <div>
              Contacts{' '}
              <span className={'font-thin'}>
                ({formatInteger(contactsCount)})
              </span>
            </div>
            <FbButton onClick={() => setShowNewContactModal(true)}>
              <FaPlus size={14} /> Add Contact
            </FbButton>
          </div>
        }
      >
        <ContactsTable
          defaultColumnVisibility={{
            requested_contact__campaign__name: false,
          }}
          tableKey={TABLE_KEY}
          forCompany={props.data}
          setTotalRowsCount={setContactsCount}
          handleContactUpdated={refetchContacTable}
          disableUrlPagination
          disableExportButton
        />
      </CollapsibleSection>
      {showNewContactModal && (
        <NewContactModal
          show={showNewContactModal}
          handleClose={() => setShowNewContactModal(false)}
          onContactCreated={refetchContacTable}
          forCompany={props.data}
        />
      )}
    </>
  )
}

export default ContactsTableSection
