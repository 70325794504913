import { FilterCriteria, RangeFilter } from '../../FilterStore'
import { FilterChipCloseButton, FilterChipContainer } from './FilterChipStyles'

type FilterChipRangeProps = {
  title?: string
  value: RangeFilter
  onClose?: () => void
  filterCriteria: FilterCriteria
}

export function FilterChipRange({
  title,
  value,
  onClose,
  filterCriteria,
}: FilterChipRangeProps) {
  const { max, min } = value
  const isExclusive = filterCriteria === 'neither'

  const formatStrAsLocaleDate = (str: string) => {
    const date = new Date(str + 'T00:00:00')
    return date.toLocaleDateString()
  }

  const formatValue = (value: number | string) => {
    const isString = typeof value === 'string'
    const has3hyphens = value.toString().split('-').length === 3
    if (isString && has3hyphens) {
      return formatStrAsLocaleDate(value)
    }
    return value.toString()
  }

  const getFormattedValue = () => {
    if (min == null && max != null) return `≤${formatValue(max)}`
    if (max == null && min != null) return `≥${formatValue(min)}`

    return `${formatValue(min!)} - ${formatValue(max!)}`
  }

  return (
    <FilterChipContainer isExclusive={isExclusive}>
      <span>
        {`${title}: `}
        <b>{getFormattedValue()}</b>
      </span>
      {onClose && (
        <FilterChipCloseButton
          onClick={onClose}
          style={{
            color: isExclusive ? '#E44C4C' : '#087443',
          }}
        />
      )}
    </FilterChipContainer>
  )
}
