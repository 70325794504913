import { DealsTable } from '../../../components/Tables/DealsTable/DealsTable'
import React from 'react'
import { StatsCard, StatsContainer } from '../../../styled/Stats'
import { formatInteger } from '../../../utils/formatting'
import { DEAL_TABLE_KEY } from 'constants/tableQueryKeys'
import {
  FBPageHeader,
  FBPageTableContainer,
  FBPageTitle,
  FBPageTitleRow,
} from 'components/FbUI/Page/styles'
import { FBPage } from '../../../components/FbUI/Page/FBPage'

export function DealsPage() {
  const [totalDeals, setTotalDeals] = React.useState<number>()

  const placesStats = [
    {
      name: 'Total Deals',
      value: formatInteger(totalDeals),
      isLoading: !totalDeals,
    },
  ]

  return (
    <FBPage>
      <FBPageHeader>
        <FBPageTitleRow>
          <FBPageTitle>Deals</FBPageTitle>
        </FBPageTitleRow>
        <StatsContainer>
          {placesStats.map((stat) => (
            <StatsCard key={stat.name}>
              <h1> {stat.name} </h1>
              {!stat.isLoading ? (
                <span> {stat.value} </span>
              ) : (
                <span style={{ fontSize: 12, fontWeight: 400 }}>
                  Calculating...
                </span>
              )}
            </StatsCard>
          ))}
        </StatsContainer>
      </FBPageHeader>
      <FBPageTableContainer
        style={{
          height: 'calc(var(--base-page-table-height) - 86px)',
        }}
      >
        <DealsTable
          tableKey={DEAL_TABLE_KEY}
          defaultColumnVisibility={{
            predicted_annual_revenue: false,
            predicted_monthly_revenue: false,
            monthly_revenue_override: false,
            company_size: false,
          }}
          showCompanyColumn
          setTotalRowsCount={setTotalDeals}
        />
      </FBPageTableContainer>
    </FBPage>
  )
}
