import { useQuery } from '@tanstack/react-query'
import {
  DataTable,
  DataTableProvider,
  useDataTableContext,
} from 'components/DataTable'
import FbButton from 'components/FbUI/FbButton'
import {
  dealsPipelineFilterStore,
  useFilterParams,
} from 'components/Filters/FilterStore'
import { FilterChips } from 'components/Filters/components/FilterChips/FilterChips'
import PipelineExportModal from 'components/Modals/ExportModal/PipelineExportModal'
import { useMemo, useState } from 'react'
import { BsDownload } from 'react-icons/bs'
import apiService from 'services/api'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import styled from 'styled-components'
import { usePaginationURLParams } from 'utils/usePaginationURLParams'
import { getTableQueryKey } from '../../../utils/getTableQueryKey'
import { dataTableSortingStoreRepo } from '../../DataTable/DataTableSorting/DataTableSortingStore'
import { FBPageTableContainer } from '../../FbUI/Page/styles'
import { TableSearch } from '../../Filters/TableSearch'
import DealsPipelineTableFilters from './DealsPipelineFilterset'
import { useDealsPipelineTableColumns } from './useDealsPipelineTableColumns'

interface DealsPipelineTableProps {
  tableKey: string
}

function DealsPipelineTableComponent(props: DealsPipelineTableProps) {
  const { sorting } = dataTableSortingStoreRepo.getStore(props.tableKey)()
  const {
    methods: { clearSelectedRows },
  } = useDataTableContext()
  const filtersAsParams = useFilterParams(dealsPipelineFilterStore)

  const PAGE_SIZE = 20

  const [pagination] = usePaginationURLParams(PAGE_SIZE)

  const api = apiService()

  const [showExportModal, setShowExportModal] = useState(false)

  const filterParams = useMemo(() => {
    const params: Record<string, any> = filtersAsParams

    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }

    return params
  }, [sorting, filtersAsParams])

  const TABLE_QUERY_KEY = getTableQueryKey({
    tableKey: props.tableKey,
    filterParams: filterParams,
    page: pagination.pageIndex,
  })

  // DATA FETCHING
  const { isFetching, data } = useQuery({
    queryKey: TABLE_QUERY_KEY,
    queryFn: async ({ signal }) => {
      clearSelectedRows()

      const fullParams = {
        ...filterParams,
        page: pagination.pageIndex + 1,
        has_stage: true,
      }

      return await api.getDealsPipeline(fullParams, signal)
    },
  })

  const columns = useDealsPipelineTableColumns()

  return (
    <>
      <h2 className="uppercase font-light text-2xl text-gray-700">
        Companies in Pipeline
      </h2>
      <hr className="border-gray-400 mb-6 mt-2" />

      <FBPageTableContainer style={{ height: '800px' }}>
        <DataTable
          tableHeader={
            <>
              <SearchContainer>
                <TableSearch
                  filterStore={dealsPipelineFilterStore}
                  searchPlaceholder="Search by chain name"
                />
                <DealsPipelineTableFilters />
              </SearchContainer>

              <div className="mb-2">
                <FilterChips
                  identifier={'DEALS_PIPELINE_TABLE'}
                  store={dealsPipelineFilterStore}
                  showActive
                />
              </div>
            </>
          }
          tableKey={props.tableKey}
          loading={isFetching}
          data={data ?? []}
          columns={columns}
          sortableFields="all"
          virtualizeRows
          enableRowSelection={false}
          defaultSort={[{ id: 'last_modified' as any, desc: true }]}
          footerControls={
            <ExportButton
              variant="secondary"
              onClick={() => setShowExportModal(true)}
            >
              <BsDownload size={18} />
            </ExportButton>
          }
        />
      </FBPageTableContainer>

      <PipelineExportModal
        count={data?.length ?? 0}
        show={showExportModal}
        _handleClose={() => setShowExportModal(false)}
        filters={filtersAsParams}
      />
    </>
  )
}

export function DealsPipelineTable(props: DealsPipelineTableProps) {
  const { tableKey, ...rest } = props

  return (
    <ColumnsStoreProvider
      savedPresetsIdentifier={'DEALS_PIPELINE_TABLE'}
      tableKey={tableKey}
    >
      <DataTableProvider tableKey={tableKey}>
        <DealsPipelineTableComponent tableKey={tableKey} {...rest} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 12px;
  flex-wrap: wrap;
`

const ExportButton = styled(FbButton)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;

  gap: 8px;

  height: 44px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`
