import * as S from './styles'

import { Control, FieldValues, Path, useController } from 'react-hook-form'
import CustomCheckbox from '../../Checkbox/checkbox'
import { ErrorLabel } from '../ErrorLabel'

export function Checkbox<T extends FieldValues>({
  name,
  label,
  control,
  disabled,
  children,
  style,
  wrapperStyle,
}: {
  control: Control<T>
  name: Path<T>
  label?: string
  children?: React.ReactNode
  disabled?: boolean
  style?: React.CSSProperties
  wrapperStyle?: React.CSSProperties
}) {
  const {
    field: { ref, onChange, value },
    fieldState: { error },
  } = useController({ name: name, control })

  return (
    <S.Wrapper style={wrapperStyle}>
      <div style={{ ...style, alignItems: 'center', display: 'flex' }}>
        <CustomCheckbox
          id={name}
          ref={ref}
          onChange={onChange}
          disabled={disabled}
          value={value ?? ''}
          checked={value ?? false}
        />
        <label htmlFor={name} style={{ marginLeft: 8 }}>
          {children ?? label}
        </label>
      </div>
      {error?.message && <ErrorLabel message={error?.message} />}
    </S.Wrapper>
  )
}
