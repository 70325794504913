import { cn } from 'components/UI/cn'
import PropTypes from 'prop-types'
import * as React from 'react'

export const Input = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement>
>(({ className, type, min, max, ...props }, ref) => (
  <input
    type={type}
    min={type === 'number' ? min : undefined}
    max={type === 'number' ? max : undefined}
    className={cn(
      'flex h-10 w-full rounded-md bg-background px-2 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground',
      'focus:outline-none focus:ring-0 focus:ring-offset-0 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0 focus-within:outline-none focus-within:ring-0 focus-within:ring-offset-0',
      className
    )}
    ref={ref}
    {...props}
  />
))

Input.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Input.displayName = 'Input'
