import axios from 'axios'
import { DataTableProvider } from 'components/DataTable'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import apiService from 'services/api'
import { LTVChartsCard } from '../../../components/Charts/LTVChartsCard/LTVChartsCard'
import { RatingsCard } from '../../../components/Charts/RatingsCard/RatingsCard'
import { CompanyAttributesManager } from '../../../components/CompanyAttrsManager/CompanyAttrsManager'
import * as S from './door.styles'
import { OpeningHoursTitle } from './door.styles'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AgeCard } from 'components/Cards/AgeCard'
import ChainHeaderCard from 'components/Cards/ChainHeaderCard'
import { EducationCard } from 'components/Cards/EducationCard'
import { RaceCard } from 'components/Cards/RaceCard'
import { ZipCodeDemographics } from 'components/Cards/ZipCodeDemographics'
import { FbLinkPill } from 'components/FbUI/FbLinkPill'
import NotFoundCard from 'components/NotFoundCard/NotFoundCard'
import useDocumentTitle from 'components/useDocumentTitle'
import { usePreferences } from 'context/preferences/PreferencesContext'
import mapboxgl from 'mapbox-gl'
import { ContactCompanyType } from 'models/contact_companies'
import { Spinner } from 'react-bootstrap'
import { DoorMenuSection } from '../../../components/Cards/DoorMenuSection'
import { FBPage } from '../../../components/FbUI/Page/FBPage'
import NotesSection from '../../../components/Notes/NotesSection/NotesSection'
import { Block } from '../../../components/UI/Block'
import {
  Section,
  SectionItem,
} from 'components/DataExpansionComponents/Common/CommonPage/styles'

mapboxgl.accessToken =
  'pk.eyJ1IjoiY2NhYnJhbC1kZXYiLCJhIjoiY2xkdWxudHlyMDcybDN2b3o0OHU3d3o3ZiJ9.WNw9-EHladqP7BiEQYKuaw'

function _Door() {
  const mapContainer = useRef(null)
  const map = useRef<mapboxgl.Map | null>(null)
  const api = apiService()
  const urlParams = useParams()
  const placeID = urlParams?.firstbite_id
  const { preferences } = usePreferences()

  const {
    data: doorProxy,
    isLoading,
    isError,
    error,
  } = api.useGetPlaceV2(placeID)

  useDocumentTitle(
    `${doorProxy?.restaurant?.restaurant_name || 'Not Found'} (Door) - First Bite`
  )

  const [notFound, setNotFound] = useState(false)

  useEffect(() => {
    if (
      isError &&
      axios.isAxiosError(error) &&
      error?.response?.status === 404
    ) {
      setNotFound(true)
    }
  }, [error, isError])

  useEffect(() => {
    if (!mapContainer.current) return // wait for map container to be ready
    if (map.current || !doorProxy) return // initialize map only once
    const createdMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [doorProxy.restaurant.longitude, doorProxy.restaurant.latitude],
      zoom: 12,
    })
    map.current = createdMap as mapboxgl.Map
  }, [doorProxy, mapContainer])

  if (isLoading) {
    return (
      <div className={'flex items-center justify-center h-96'}>
        <Spinner animation="border" />
      </div>
    )
  }

  if (notFound) {
    return <NotFoundCard />
  }

  return (
    <FBPage className="gap-y-4" noContainer>
      {doorProxy && (
        <>
          <ChainHeaderCard
            companyTypeSlug="restaurants-bars"
            doorId={doorProxy?.restaurant.firstbite_id}
            isLoading={isLoading}
            category={'Commercial'}
            subCategory={'Restaurant Door'}
            name={doorProxy?.restaurant.restaurant_name}
            breadcrumbs={[
              {
                title: 'Chain',
                breadcrumb: doorProxy?.restaurant.restaurant_name,
                url: `/chain/${preferences?.company_type_prefix_map[ContactCompanyType.Chain]}${doorProxy?.chain_proxy?.chain?.id}`,
              },
            ]}
            phone={doorProxy?.restaurant.phone}
            address={{
              addressInfo: {
                fullAddress: doorProxy?.restaurant.address,
              },
              url: doorProxy?.restaurant.google_place_url,
            }}
            companyLogoUrl={doorProxy?.restaurant.logo_url}
            description={doorProxy?.restaurant.description}
            domainField={doorProxy?.restaurant.website}
            rightColumnHeader={
              <CompanyAttributesManager
                chainID={doorProxy?.chain_proxy?.chain?.id}
                contactCompanyID={undefined}
              />
            }
            leftFirstBiteCol={{
              chain: doorProxy?.chain_proxy?.chain?.count,
              category: doorProxy?.restaurant?.expense_category,
              cuisine: doorProxy?.restaurant?.cuisine_50,
              reviews: doorProxy?.restaurant?.total_reviews_count,
              rating: doorProxy?.restaurant?.avg_rating,
            }}
          />

          {doorProxy && (
            <NotesSection
              contactCompanyId={undefined}
              chainId={doorProxy?.chain_proxy?.chain?.id}
              pagination={false}
            />
          )}

          <DoorMenuSection doorId={doorProxy.restaurant.firstbite_id} />

          <Block>
            <Section>
              <SectionItem>
                <LTVChartsCard
                  ltv={{
                    brand_ltv: doorProxy.brand_ltv_sum,
                    revenue_ltv: doorProxy.revenue_ltv_sum,
                    total_ltv: doorProxy.total_ltv_sum,
                  }}
                />
              </SectionItem>

              {doorProxy?.restaurant && (
                <SectionItem>
                  <RatingsCard restaurant={doorProxy.restaurant} />
                </SectionItem>
              )}

              {doorProxy?.restaurant?.address_info && (
                <SectionItem>
                  <ZipCodeDemographics
                    addressInfo={doorProxy?.restaurant?.address_info}
                  />
                </SectionItem>
              )}

              {doorProxy?.restaurant?.address_info && (
                <>
                  <SectionItem>
                    <RaceCard
                      addressInfo={doorProxy?.restaurant?.address_info}
                    />
                  </SectionItem>

                  <SectionItem>
                    <AgeCard
                      addressInfo={doorProxy?.restaurant?.address_info}
                    />
                  </SectionItem>

                  <SectionItem>
                    <EducationCard
                      addressInfo={doorProxy?.restaurant?.address_info}
                    />
                  </SectionItem>
                </>
              )}
            </Section>
          </Block>
          <Block>
            <Section>
              <S.RestaurantInfo>
                <S.RowBetween>
                  <S.CardTitle>Restaurant Information</S.CardTitle>
                  <S.Row>
                    {doorProxy?.restaurant?.menu_url && (
                      <FbLinkPill url={doorProxy?.restaurant?.menu_url}>
                        Menu
                      </FbLinkPill>
                    )}
                  </S.Row>
                </S.RowBetween>

                {doorProxy?.restaurant?.open_hours && (
                  <>
                    <OpeningHoursTitle>Opening Hours</OpeningHoursTitle>
                    <S.OpenHoursGrid>
                      {Object.entries(doorProxy?.restaurant?.open_hours)?.map(
                        ([day, hours]) => {
                          return (
                            <S.OpeningHoursItem key={day}>
                              <span>{day}</span>
                              <span>{hours as string}</span>
                            </S.OpeningHoursItem>
                          )
                        }
                      )}
                    </S.OpenHoursGrid>
                  </>
                )}
              </S.RestaurantInfo>

              <S.Map>
                <div
                  style={{
                    width: '100%',
                    height: '400px',
                    marginBottom: 20,
                  }}
                  ref={mapContainer}
                  className="map-container"
                />
              </S.Map>
            </Section>
          </Block>
        </>
      )}
    </FBPage>
  )
}

export default function Door() {
  return (
    <DataTableProvider tableKey={'door-table'}>
      <_Door />
    </DataTableProvider>
  )
}
