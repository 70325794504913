import * as React from 'react'
import { Check, ChevronsUpDown, CirclePlus } from 'lucide-react'
import { Popover, PopoverContent, PopoverTrigger } from './popover'
import { Button } from './button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from './command'
import { cn } from '../cn'
import { Spinner } from 'react-bootstrap'

type ComboboxOption = {
  label: string
  value: string | number
  icon?: React.JSX.Element
}

export interface ComboboxProps {
  placeholder: string
  searchPlaceholder: string
  options: ComboboxOption[]
  onSelectAltAction?: (value: string | number) => void
  onAddNew?: () => void
  onSelect?: React.Dispatch<React.SetStateAction<string | number>>
  selected?: string | number
  notFoundPlaceholder?: string
  buttonClassName?: string
  buttonComponent?: React.JSX.Element
  disabled?: boolean
  loading?: boolean
  buttonLess?: boolean
}

export function Combobox(props: ComboboxProps) {
  const [open, setOpen] = React.useState(false)

  const content = (
    <Command>
      <CommandInput
        placeholder={props.searchPlaceholder ?? 'Nothing found...'}
      />
      <CommandList>
        <CommandEmpty>{props.notFoundPlaceholder}</CommandEmpty>
        <CommandGroup>
          {props.onAddNew && (
            <CommandItem
              key={'new'}
              value={'new'}
              onSelect={() => {
                setOpen(false)
                props.onAddNew?.()
              }}
            >
              <CirclePlus size={12} className={'mr-1'} />
              Add new
            </CommandItem>
          )}
          {props.options.map((option) => (
            <CommandItem
              key={option.value}
              value={option.label + option.value}
              onSelect={() => {
                if (props.onSelectAltAction) {
                  props.onSelectAltAction(option.value)
                } else {
                  props.onSelect?.(
                    option.value === props?.selected?.toString()
                      ? ''
                      : option.value
                  )
                }
                setOpen(false)
              }}
            >
              {option.icon}
              {option.label}
              <Check
                className={cn(
                  'mr-2 h-4 w-4',
                  props.selected?.toString() === option.value
                    ? 'opacity-100'
                    : 'opacity-0'
                )}
              />
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  )

  if (props.buttonLess) {
    return content
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        {props.buttonComponent ?? (
          <Button
            disabled={props.disabled || props.loading}
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className={cn(
              'text-md max-w-[300px] justify-between',
              props.buttonClassName
            )}
          >
            <>
              {props.selected
                ? props.options.find(
                    (option) =>
                      option.value.toString() === props.selected?.toString()
                  )?.label
                : props.placeholder}
              {props.loading ? (
                <Spinner className={'ml-2 flex-shrink-0'} size={'sm'} />
              ) : (
                <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
              )}
            </>
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">{content}</PopoverContent>
    </Popover>
  )
}
