import { createColumnHelper } from '@tanstack/react-table'
import { usePreferences } from 'context/preferences/PreferencesContext'
import { DealsPipeline } from 'models/deals_pipeline'
import moment from 'moment'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { formatUsd } from 'utils/formatting'
import { getCompanyUrlFromDealPipeline } from 'utils/getCompanyUrl'
import { SalesStageCell } from '../../FbUI/StagePill'
import { DEALS_PIPELINE_COLUMNS } from 'components/DataExpansionComponents/Deals/dealsPipelineColumnInfo'
import { createTableColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'

export function useDealsPipelineTableColumns() {
  const { preferences } = usePreferences()

  const tooltips: Record<string, any> = preferences?.tooltips || {}

  const dealsPipelineColumnHelper = createColumnHelper<DealsPipeline>()

  const dealsPipelineTableColumnHelper = createTableColumnHelper({
    columns: DEALS_PIPELINE_COLUMNS,
    tooltips,
  })

  const columns = useMemo(() => {
    return [
      dealsPipelineColumnHelper.accessor('company_name', {
        ...dealsPipelineTableColumnHelper.retrieveColumn('company_name'),
        cell: (info) => {
          if (!info.getValue()) return '-'

          return (
            <Link
              to={getCompanyUrlFromDealPipeline(info.row.original)}
              target="_blank"
            >
              {info.getValue()}
            </Link>
          )
        },
        size: 200,
      }),
      dealsPipelineColumnHelper.accessor('count', {
        ...dealsPipelineTableColumnHelper.retrieveColumn('count'),
        size: 100,
        cell: (info) => {
          return info.getValue()
        },
      }),
      dealsPipelineColumnHelper.accessor('closed', {
        ...dealsPipelineTableColumnHelper.retrieveColumn('closed'),
        size: 100,
        cell: (info) => {
          return info.getValue()
        },
      }),
      dealsPipelineColumnHelper.accessor('sales_stages', {
        ...dealsPipelineTableColumnHelper.retrieveColumn('sales_stages'),
        cell: (info) => {
          const salesStageIds = info
            .getValue()
            ?.map((it: { sales_stage: { id: string } }) =>
              parseInt(it.sales_stage.id)
            )
          if (!salesStageIds) return '-'
          return <SalesStageCell salesStagesIds={salesStageIds} />
        },
      }),
      dealsPipelineColumnHelper.accessor('taro', {
        ...dealsPipelineTableColumnHelper.retrieveColumn('taro'),
        cell: (info) => {
          if (!info.getValue()) return '-'
          return <div>{formatUsd(info.getValue())}</div>
        },
      }),
      dealsPipelineColumnHelper.accessor('taro_closed', {
        ...dealsPipelineTableColumnHelper.retrieveColumn('taro_closed'),
        cell: (info) => {
          if (!info.getValue()) return '-'
          return <div>{formatUsd(info.getValue())}</div>
        },
      }),
      dealsPipelineColumnHelper.accessor('last_modified', {
        ...dealsPipelineTableColumnHelper.retrieveColumn('last_modified'),
        cell: (info) => {
          if (!info.getValue()) return '-'
          return <div>{moment(info.getValue()).format('MMM DD, YYYY')}</div>
        },
      }),
      dealsPipelineColumnHelper.accessor('days_since_last_modified', {
        ...dealsPipelineTableColumnHelper.retrieveColumn(
          'days_since_last_modified'
        ),
        cell: (info) => {
          if (!info.getValue()) return '-'
          return info.getValue()
        },
      }),
    ]
  }, [tooltips])

  return columns
}
