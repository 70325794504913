import { baseAPICall } from '../../services/api'
import { clientInstance } from '../../utils/http-client'
import { useQuery } from '@tanstack/react-query'
import { RebateOfferTerms } from '../../models/rebateofferterms'

export interface RebateOffer {
  id: number
  name: string
  code: string
  state: string
  scope: string
  type: string
  valid_from: string
  valid_to: string
  terms: {
    company_name: string
    products: Array<{
      product_name: string
      product_desc: string
      unit: string
      tiers: Array<{
        min: number
        max: number
        discount: number
      }>
    }>
  }
  product_names: string
  total_claims: number
  pending_validation_claims: number
  invalid_claims: number
  unpaid_valid_claims: number
  paid_claims: number
  total_validated_units: number | null
  total_validated_payout: number | null
}

export type RebateEnrollment = {
  id: number
  tradespend_user_name: string
  company_name: string
  terms: any
  created: string
}

export type RebateClaim = {
  id: number
  status: string
  company_name: string
  enrollment_date: string
  created: string
}

export type PaginatedRebateOffers = {
  count: number
  next: string | null
  previous: string | null
  results: RebateOffer[]
}

export type PaginatedRebateEnrollments = {
  count: number
  next: string | null
  previous: string | null
  results: RebateEnrollment[]
}

export type PaginatedRebateClaims = {
  count: number
  next: string | null
  previous: string | null
  results: RebateClaim[]
}

/* -------------
List Rebate Offers
-------------- */
export async function getRebateOffers(
  campaignId?: number,
  signal?: AbortSignal
) {
  const params = campaignId ? { campaign_id: campaignId } : {}
  return baseAPICall<PaginatedRebateOffers>(() =>
    clientInstance.get('tradespend/firstbite/offers/', {
      params,
      signal,
    })
  )
}

export const useGetRebateOffers = (
  campaignId?: number,
  signal?: AbortSignal,
  disabled = false
) =>
  useQuery({
    queryKey: ['rebate-offers', campaignId],
    queryFn: () => getRebateOffers(campaignId, signal),
    refetchOnMount: false,
    enabled: !disabled,
  })

/* -------------
Get Single Rebate Offer
-------------- */
export async function getRebateOffer(offerId: number) {
  return baseAPICall<RebateOffer>(() =>
    clientInstance.get(`tradespend/firstbite/offers/${offerId}/`)
  )
}

export const useGetRebateOffer = (offerId: number) =>
  useQuery({
    queryKey: ['rebate-offer', offerId],
    queryFn: () => getRebateOffer(offerId),
    enabled: offerId !== undefined && offerId !== null,
  })

/* -------------
List Rebate Enrollments
-------------- */
export async function getRebateEnrollments(offerId: number) {
  return baseAPICall<PaginatedRebateEnrollments>(() =>
    clientInstance.get(`tradespend/firstbite/offers/${offerId}/enrollments/`)
  )
}

export const useGetRebateEnrollments = (offerId: number) =>
  useQuery({
    queryKey: ['rebate-enrollments', offerId],
    queryFn: () => getRebateEnrollments(offerId),
    enabled: !!offerId,
  })

/* -------------
List Rebate Claims
-------------- */
export async function getRebateClaims(offerId: number) {
  return baseAPICall<PaginatedRebateClaims>(() =>
    clientInstance.get(`tradespend/firstbite/offers/${offerId}/claims/`)
  )
}

export const useGetRebateClaims = (offerId: number) =>
  useQuery({
    queryKey: ['rebate-claims', offerId],
    queryFn: () => getRebateClaims(offerId),
    enabled: !!offerId,
  })

/* -------------
Get Rebate Suggestion
-------------- */
type GetRebateSuggestionsBody = {
  campaign_id?: number
  type: string
  basis: string
  min: number
}

export async function getRebateSuggestions(values: GetRebateSuggestionsBody) {
  return baseAPICall<RebateOfferTerms>(() =>
    clientInstance.post('tradespend/firstbite/get-rebate-suggestions/', values)
  )
}

/* -------------
Create Rebate Offer
-------------- */
type CreateRebateOfferBody = {
  name: string
  campaign_id?: number
  valid_from: Date
  valid_to: Date
  terms: string
}

export async function createRebateOffer(values: CreateRebateOfferBody) {
  return baseAPICall<RebateOfferTerms>(() =>
    clientInstance.post('tradespend/firstbite/create-rebate-offer/', values)
  )
}

/* -------------
Get Enrollment Details
-------------- */
export type RebateClaimDetail = {
  id: number
  status: string
  created: string
  operator_invoice_url: string | null
  manufacturer_invoice_url: string | null
  num_units: number | null
  payout_amount: number | null
}

export type RebateEnrollmentDetail = {
  id: number
  tradespend_user_name: string
  company_name: string
  terms: any
  created: string
  total_claims: number
  pending_validation_claims: number
  invalid_claims: number
  paid_claims: number
  unpaid_valid_claims: number
  claims: RebateClaimDetail[]
}

export async function getRebateEnrollmentDetails(enrollmentId: number) {
  return baseAPICall<RebateEnrollmentDetail>(() =>
    clientInstance.get(`tradespend/firstbite/enrollments/${enrollmentId}/`)
  )
}

export const useGetRebateEnrollmentDetails = (enrollmentId: number) =>
  useQuery({
    queryKey: ['rebate-enrollment-details', enrollmentId],
    queryFn: () => getRebateEnrollmentDetails(enrollmentId),
    enabled: !!enrollmentId,
  })

export interface ManufacturerInvoice {
  id: number
  created: string
  amount: number
  status: 'DRAFT' | 'PENDING' | 'PAID' | 'OVERDUE'
  due_date: string
  claim_count: number
  company_name: string
  paid_at?: string
}

interface PaginatedManufacturerInvoices {
  results: ManufacturerInvoice[]
  count: number
}

/* -------------
List Manufacturer Invoices
-------------- */
export async function getManufacturerInvoices() {
  return baseAPICall<PaginatedManufacturerInvoices>(() =>
    clientInstance.get('tradespend/firstbite/invoices/')
  )
}

export function useManufacturerInvoices() {
  const query = useQuery({
    queryKey: ['manufacturer-invoices'],
    queryFn: () => getManufacturerInvoices(),
  })

  return {
    ...query,
  }
}
