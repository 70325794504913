import React from 'react'

export function JsonIntoUl(props: {
  value: string
  fallback: React.ReactNode
}) {
  const isValidJson = (str: string) => {
    try {
      JSON.parse(str)
      return true
    } catch (e) {
      return false
    }
  }

  const renderBulletPoints = (obj: Record<string, string>, depth = 0) => {
    if (typeof obj !== 'object' || obj === null) {
      return <li key={depth}>{String(obj)}</li>
    }

    return (
      <ul className="list-disc ml-4">
        {Object.entries(obj).map(([key, value], index) => (
          <li key={`${depth}-${index}`}>
            <strong>{key}:</strong>
            {typeof value === 'object' && value !== null
              ? renderBulletPoints(value, depth + 1)
              : ` ${String(value)}`}
          </li>
        ))}
      </ul>
    )
  }

  if (isValidJson(props.value)) {
    return renderBulletPoints(JSON.parse(props.value))
  } else return props.fallback
}
