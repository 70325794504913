import styled from 'styled-components/macro'
import { GoSortAsc, GoSortDesc } from 'react-icons/go'

export const SortDescIcon = styled(GoSortDesc)`
  height: 1rem;
  width: 1rem;
  flex-shrink: 0;
`

export const SortAscIcon = styled(GoSortAsc)`
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
`
