import styled from 'styled-components'

export function PredictedTrafficBadge({ value }: { value: string | null }) {
  const getColorProps = (value: string | null) => {
    const value_lower = value?.toLowerCase() || 'unavailable'
    if (value_lower === 'very high')
      return { color: '#00876C', backgroundColor: '#e6f3f1' }
    if (value_lower === 'high')
      return { color: '#64901B', backgroundColor: '#eff5e4' }
    if (value_lower === 'average')
      return { color: '#E08B20', backgroundColor: '#fef6ec' }
    if (value_lower === 'low')
      return { color: '#D43D51', backgroundColor: '#fbecee' }
    return { color: '#959595' }
  }
  return (
    <div className="flex">
      <Badge {...getColorProps(value)}>{value ?? 'Unavailable'}</Badge>
    </div>
  )
}

const Badge = styled.div<{ color?: string; backgroundColor?: string }>`
  font-size: 85%;
  border-radius: 10px;
  padding: 2px 8px;
  font-weight: 500;
  ${({ color }) => color && `color: ${color};`}
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor};`}
`
