import React from 'react'
import './Switch.styles.css'
import { cn } from '../cn'

export interface ISwitchProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  labelRight?: string
  labelLeft?: string
}

export function Switch(props: ISwitchProps) {
  const { labelLeft, labelRight, ...rest } = props
  return (
    <div className={cn('flex items-center', props.className)}>
      {labelLeft && (
        <span className={'mr-2 text-sm text-gray-800 font-medium text-nowrap'}>
          {labelLeft}
        </span>
      )}
      <label className="switch">
        <input {...rest} type="checkbox" />
        <span
          style={{
            cursor: rest.disabled ? 'default' : 'pointer',
          }}
          className="slider round"
        ></span>
      </label>
      {labelRight && (
        <span className={'ml-2 text-sm text-gray-800 font-medium text-nowrap'}>
          {labelRight}
        </span>
      )}
    </div>
  )
}
