import styled from 'styled-components/macro'

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 67px 93px;
  background-color: #eaecf0;
`
export const Card = styled.div`
  background: #ffffff;
  border: 1px solid #d0d3dd;
  border-radius: 8px;
  padding: 20px 16px;
  margin-bottom: 5px;
`
export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`
export const SectionItem = styled.div`
  height: 200px;
  flex-shrink: 0;
  flex: 1;
`
export const Button = styled.button`
  color: #5a755e;
  display: flex;
  background-color: white;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
  width: 100%;
  margin-right: 4px;
  font-size: 12px;
  height: 40px;

  & span {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #344054;

    & strong {
      font-weight: 500;
    }
  }
`
export const Title = styled.h1`
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-right: 10px;
`
export const Divider = styled.div`
  height: 1px;
  background: #eaecf0;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
`
export const Value = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`
export const Label = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #475467;
  display: flex;
  align-items: center;
  gap: 4px;
`
export const ValueBig = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 38px;
  color: #101828;
`
export const VerticalDivider = styled.div`
  width: 1px;
  background-color: #eaecf0;
  margin: 0 28px;
  height: 200px;
`
export const Ratings = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
`

export const CardTitle = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 38px;
  /* identical to box height, or 173% */

  /* Gray/900 */

  color: #101828;
`

export const CardSubtitles = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  width: 300px;
`

export const CardSubtitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const ColoredDot = styled.div<{
  color: string
}>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${(props) => props.color};
`

export const CardSubtitle = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: #475467;
`

export const CardSubtitlePercent = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
`

export const RowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const MenuRow = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;
  margin-bottom: 20px;
`

export const MapCard = styled.div``

export const OpeningHoursItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  padding: 8px 0;
  border-bottom: 1px solid #eaecf0;

  &:nth-child(1) {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #101828;
  }

  &:nth-child(2) {
    font-weight: 400;
    font-size: 14px;
    color: #101828;
  }
`

export const AgesWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 20px;
`

export const AgesSubtitles = styled.div`
  width: 100%;
`

export const AgesChartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
  width: 130px;
`

export const OpenHoursGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: fit-content;
`

export const Map = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
`

export const RestaurantInfo = styled.div`
  flex: 1;
`

export const OpeningHoursTitle = styled.h1`
  font-weight: 600;
  font-size: 18px;
  line-height: 38px;
  /* identical to box height, or 211% */

  /* Gray/900 */

  color: #101828;
`
