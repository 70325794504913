import { useQuery } from '@tanstack/react-query'
import { ColumnDef } from '@tanstack/react-table'
import { OverflownTextTooltip } from 'components/OverflownTextTooltip'
import { useAuth } from 'context/authentication/useAuth'
import { InviteUserModal } from 'features/users/InviteUserModal'
import { User } from 'models/user'
import { useMemo } from 'react'
import { Spinner } from 'react-bootstrap'
import apiService from 'services/api'
import { ClientDataTable } from '../../../components/ClientDataTable/ClientDataTable'
import { DataTableContainer } from '../../../components/DataTable/UI'
import * as S from './styles'
export function UserTable() {
  const api = apiService()
  const { user } = useAuth()

  const { data, isLoading } = useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      return await api.getUsers()
    },
  })

  const columns = useMemo<ColumnDef<User, any>[]>(
    () => [
      {
        accessorKey: 'first_name',
        header: 'First Name',
        size: 200,
      },
      {
        accessorKey: 'last_name',
        header: 'Last Name',
        size: 200,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 150,
      },
    ],
    [data]
  )

  if (isLoading || !data) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '500px',
          width: '100%',
        }}
      >
        <Spinner animation="border" />
      </div>
    )
  }

  const descriptionText = `
  All ${user?.company.name} users who have created an account on First Bite. To add a team member, make sure to invite them using their work email.
  `
  return (
    <DataTableContainer>
      <ClientDataTable
        tableHeader={
          <S.SettingsTableHeader>
            <OverflownTextTooltip
              tooltipText={descriptionText}
              maxLines={4}
              placement="bottom"
            >
              <S.TableDescriptionText>{descriptionText}</S.TableDescriptionText>
            </OverflownTextTooltip>
            {data.length ? (
              <InviteUserModal
                existingUserEmails={data.map((it) => it.email)}
                companyDomain={data[0].email.split('@')[1]}
              />
            ) : null}
          </S.SettingsTableHeader>
        }
        height={'100%'}
        data={data}
        columns={columns}
      />
    </DataTableContainer>
  )
}
