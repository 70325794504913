import apiService from 'services/api'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import annotationPlugin from 'chartjs-plugin-annotation'
import { Chart } from 'chart.js'
import 'react-toggle/style.css'
import { SalesPipelineChart } from '../../../components/Charts/SalesPipelineChart'
import { DealsPipelineTable } from 'components/Tables/DealsPipelineTable/DealsPipelineTable'
import { Block } from 'components/UI/Block'
import { FBPage } from 'components/FbUI/Page/FBPage'
import { InfoCard } from 'components/UI/InfoCard'
import { SalesForecast } from 'components/Dashboard/SalesForecast'

Chart.register(ChartDataLabels)
Chart.register(annotationPlugin)

export function Dashboard() {
  const api = apiService()
  const { data: overview } = api.useGetDashboardOverview()
  return (
    <FBPage className="gap-y-4" noContainer>
      <Block className={'mb-3'}>
        <h3 className="text-4xl text-slate-900 font-bold mb-8">
          Deals Dashboard
        </h3>
        <div className="flex flex-row flex-wrap gap-4">
          {Object.entries(overview || {}).map(([k, v]) => (
            <InfoCard
              key={k}
              title={v.title}
              value={v.value}
              tooltip={v.tooltip}
            />
          ))}
        </div>
      </Block>

      <Block className={'mb-3'}>
        <SalesForecast />
      </Block>

      <Block className={'mb-3'}>
        <SalesPipelineChart />
      </Block>

      <Block className={'mb-3'}>
        <DealsPipelineTable tableKey={'dashboard-table'} />
      </Block>
    </FBPage>
  )
}
