import { createExportColumnHelper } from './commonColumnHelpers'
import { COMMON_COLUMNS } from './CommonColumnsInfo'

export function getCommonChainProxyExportColumns() {
  const commonColumnHelper = createExportColumnHelper({
    columns: COMMON_COLUMNS,
  })
  return [
    commonColumnHelper.retrieveColumn('sales_stages'),
    commonColumnHelper.retrieveColumn('account_owners'),
    commonColumnHelper.retrieveColumn('taglist'),
    commonColumnHelper.retrieveColumn('deal_count'),
    commonColumnHelper.retrieveColumn('contact_count'),
    commonColumnHelper.retrieveColumn('note_count'),
    commonColumnHelper.retrieveColumn('campaigns'),
  ]
}
