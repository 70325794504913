import styled from 'styled-components/macro'

export const Card = styled.div`
  background-color: #f4f7f5;
  border-radius: 8px;
  padding: 16px;
`

export const Badge = styled.span`
  background-color: #d5dcd9;
  color: #6b7280;
  font-size: 0.75rem;
  padding: 2px 8px;
  border-radius: 24px;
`

export const ModifiedBadge = styled(Badge)`
  background-color: #fff3e9;
  color: #c05621;
`
