import clsx from 'clsx'
import { IoIosRemoveCircleOutline } from 'react-icons/io'
import { MdDragIndicator } from 'react-icons/md'
import { Dropdown } from '../../UI/Dropdown/Dropdown'
import { SortDescIcon, SortAscIcon } from '../../DataTable/styles'
import { GoBlocked } from 'react-icons/go'
import React, { useMemo } from 'react'
import { DataTableColumnSort } from '../../DataTable/DataTableSorting/DataTableSortingStore'
import { RxDotsHorizontal } from 'react-icons/rx'

export interface ColumnDragAndDropItemProps {
  label: string
  id: string
  readOnly?: boolean
  onRemove?: () => void
  sorting: DataTableColumnSort[]
  setSorting: (sort: DataTableColumnSort[]) => void
}

export function ColumnDragAndDropItem({
  label,
  readOnly,
  onRemove,
  id,
  sorting,
  setSorting,
}: ColumnDragAndDropItemProps) {
  const hasSort = sorting?.[0]?.id ? sorting?.[0]?.id === id : false

  const items = useMemo(() => {
    const it = [
      {
        label: (
          <div className={'flex flex-row items-center justify-start gap-2'}>
            <SortAscIcon /> Sort Ascending
          </div>
        ),
        callback: () => {
          setSorting([{ id: id, desc: false }])
        },
      },
      {
        label: (
          <div className={'flex flex-row items-center justify-start gap-2'}>
            <SortDescIcon /> Sort Descending
          </div>
        ),
        callback: () => {
          setSorting([{ id: id, desc: true }])
        },
      },
    ]
    if (hasSort) {
      it.push({
        label: (
          <div className={'flex flex-row items-center justify-start gap-2'}>
            <GoBlocked /> No Sort
          </div>
        ),
        callback: () => {
          setSorting([])
        },
      })
    }
    return it
  }, [hasSort])

  return (
    <div
      className={clsx(
        'px-3 my-1 rounded',
        readOnly ? 'bg-[#D5DCD9]' : 'bg-[#E8F0EC]'
      )}
    >
      <div className="justify-between items-center flex py-3">
        <div className="flex items-center gap-2">
          {!readOnly && (
            <MdDragIndicator
              size={17}
              className="text-[#414942] hover:cursor-move"
            />
          )}
          <div>{label}</div>
          {hasSort ? (
            sorting[0]?.desc ? (
              <SortDescIcon />
            ) : (
              <SortAscIcon />
            )
          ) : null}
        </div>
        <div className={'flex flex-row items-end gap-2'}>
          {!readOnly && (
            <div
              className={
                'cursor-pointer rounded hover:bg-[#2B6A4633] h-7 w-7 flex items-center justify-center'
              }
              onClick={onRemove}
            >
              <IoIosRemoveCircleOutline
                size={17}
                className="cursor-pointer text-[#414942]"
              />
            </div>
          )}
          <Dropdown
            items={items}
            buttonComponent={
              <div
                className={
                  'cursor-pointer rounded hover:bg-[#2B6A4633] h-7 w-7 flex items-center justify-center'
                }
              >
                <RxDotsHorizontal />
              </div>
            }
          />
        </div>
      </div>
    </div>
  )
}
