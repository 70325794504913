export const CAMPAIGNS_COLUMNS = {
  name: {
    header: 'Name',
    group: 'Campaign Attributes',
  },
  description: {
    header: 'Description',
    group: 'Campaign Attributes',
  },
  company_type: {
    header: 'Type',
    group: 'Campaign Attributes',
  },
  product: {
    header: 'Product',
    group: 'Campaign Attributes',
  },
  created: {
    header: 'Created',
    group: 'Campaign Attributes',
  },
  modified: {
    header: 'Last Updated',
    group: 'Campaign Attributes',
  },
  days_since_last_update: {
    header: 'Days Since Last Update',
    group: 'Campaign Attributes',
  },
  contact_company_count: {
    header: 'Opps Assigned',
    group: 'Campaing Attributes',
  },
  door_count: {
    header: 'Door Count',
    group: 'Campaing Attributes',
  },
  // Deals
  deal_count: {
    header: 'Deals',
    group: 'Deals',
  },
  deal_count_won: {
    header: 'Deals Won',
    group: 'Deals',
  },
  deal_perc: {
    header: 'Deals (%)',
    group: 'Deals',
  },
  deal_perc_won: {
    header: 'Deals Won (%)',
    group: 'Deals',
  },

  contact_count: {
    header: 'Contacts Count',
    group: 'Contacts',
  },
  companies_with_contacts: {
    header: 'Companies With Contacts',
    group: 'Contacts',
  },
  companies_with_contacts_perc: {
    header: 'Companies With Contacts (%)',
    group: 'Contacts',
  },

  taro: {
    header: 'Annual Rev Opp',
    group: 'Forecasts',
  },
  taro_closed: {
    header: 'Annual Rev Opp (closed)',
    group: 'Forecasts',
  },
  est_meals_day: {
    header: 'Est. Meals / Day',
    group: 'Forecasts',
  },
}
