import { University } from 'models/universities'
import { DataTypeColumn } from '../Common/commonColumnHelpers'

export const UNIVERSITY_COLUMNS = {
  institution_name: {
    header: 'Institution Name',
    group: 'Opportunity Attributes',
  },
  address: {
    header: 'Address',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_full_address',
  },
  city: {
    header: 'City',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_city',
  },
  state_abbr: {
    header: 'State',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_state',
  },
  zipcode: {
    header: 'Zipcode',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_zipcode',
  },
  domain: {
    header: 'Domain',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_domain',
  },
  dormitory_capacity: {
    header: 'Dormitory Capacity',
    group: 'Classification and Enrollment',
    tooltipName: 'opps_dorm_capacity',
  },
  meal_plan_provided: {
    header: 'Meal Plan Provided',
    group: 'Dining Services',
    tooltipName: 'opps_mealplan',
  },
  total_enrollment: {
    header: 'Total Enrollment',
    group: 'Classification and Enrollment',
    tooltipName: 'opps_total_enrollment',
  },
  total_men_enrollment: {
    header: 'Total Men Enrollment',
    group: 'Classification and Enrollment',
    tooltipName: 'opps_M_enrollment',
  },
  total_women_enrollment: {
    header: 'Total Women Enrollment',
    group: 'Classification and Enrollment',
    tooltipName: 'opps_F_enrollment',
  },
  food_management_company: {
    header: 'Food Management Company',
    group: 'Dining Services',
    tooltipName: 'opps_FMC',
    filterTitle: 'Food Mgmt Company',
  },
  food_management_company_source: {
    header: 'Food Management Company Source',
    group: 'Dining Services',
    tooltipName: 'opps_FMC_source',
    filterTitle: 'Food Mgmt Source',
  },
  food_management_company_parent: {
    header: 'Parent Food Management Company',
    group: 'Dining Services',
    tooltipName: 'opps_FMC_parent',
    filterTitle: 'Parent Food Mgmt Company',
  },
  estimated_meals_per_day: {
    header: 'Estimated Meals per Day',
    group: 'Dining Services',
    tooltipName: 'opps_meals_count',
  },
  carnegie_classification_2021_size_setting_size: {
    header: 'Carnegie Classification Size',
    group: 'Classification and Enrollment',
    tooltipName: 'opps_carnegie_classification_2021_size_setting_size',
    filterTitle: 'Carnegie Size',
  },
  carnegie_classification_2021_size_setting_year: {
    header: 'Carnegie Classification Year',
    group: 'Classification and Enrollment',
    tooltipName: 'opps_carnegie_classification_2021_size_setting_year',
    filterTitle: 'Carnegie Year',
  },
  carnegie_classification_2021_size_setting_student_composition: {
    header: 'Carnegie Classification Student Composition',
    group: 'Classification and Enrollment',
    tooltipName:
      'opps_carnegie_classification_2021_size_setting_student_composition',
    filterTitle: 'Student Composition',
  },
  google_place_url: {
    header: 'Place URL',
    group: 'Opportunity Attributes',
  },
} satisfies Partial<Record<keyof University, DataTypeColumn>>
