import BaseStep from './BaseStep'
import { Col, Container, Row } from 'react-bootstrap'
import apiService from 'services/api'
import { useEffect, useMemo } from 'react'
import { useImportContactContext } from './ImportContactContext'
import * as S from './styles'
import { CreatableTagSelector } from 'components/CreatableTagSelector'
import { useFieldArray, useForm } from 'react-hook-form'
import Select from 'components/FormUtils/Select'
import { z } from 'zod'

const formShape = z.object({
  tags: z.array(z.number()),
  campaigns: z.array(
    z.object({
      campaign_id: z.number().optional(),
      sub_category_id: z.number(),
    })
  ),
})
export type FormType = z.infer<typeof formShape>

export function AddTagsStep() {
  const api = apiService()
  const { data: tagsOptions } = api.useGetTagsOptions(false, 'contacts')
  const { data: campaignOptions } = api.useGetCampaignsOptions()
  const { data: categories } = api.useGetCompanyCategoriesAndSub()
  const allSubCategories = categories?.flatMap((it) => it.sub_categories)
  console.log(allSubCategories)
  const {
    methods: { prevStep, handleClose, importContacts, setTags, setCampaigns },
    state: { previewData, file, isImporting, tags, campaigns },
  } = useImportContactContext()

  const { control, setError, watch } = useForm<FormType>({
    defaultValues: {
      tags: tags,
      campaigns: campaigns,
    },
  })

  const { fields, replace } = useFieldArray({
    control,
    name: 'campaigns',
  })

  const _tags = watch('tags')
  const _campaigns = watch('campaigns')

  useEffect(() => {
    setTags(_tags)
  }, [_tags])

  useEffect(() => {
    setCampaigns(_campaigns)
  }, [_campaigns])

  const subCategories = useMemo(
    () => [
      ...new Set(
        previewData?.success_contacts.map(
          (it) => it.contact_company.sub_category as unknown as number
        )
      ),
    ],
    [previewData]
  )

  useEffect(() => {
    replace(
      subCategories.map((it) => ({
        sub_category_id: it,
        campaign_id: undefined,
      }))
    )
  }, [subCategories])

  if (!previewData || !file) return null

  return (
    <BaseStep
      handleClose={handleClose}
      onBack={() => prevStep()}
      onContinue={importContacts}
      title="Update New Contacts - Tag Contacts"
      backButtonText="Back"
      continueButtonText="Complete"
      isContinueLoading={isImporting}
    >
      <S.Content style={{ maxHeight: 500 }}>
        <S.ContentTitle>
          To organize these contacts, you can move their associated companies
          into a campaign, and/or apply labels directly to the contacts
          themselves. Note: The contact labels differ from company and deal
          tags, and are only assigned on the contact level.
          <Container className="mt-2">
            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <CreatableTagSelector
                  name="tags"
                  label={`Add Contact Labels to ${previewData?.success_count} new contacts`}
                  control={control}
                  placeholder="Labels"
                  options={tagsOptions}
                  setError={setError}
                  tagParent="contacts"
                />
              </Col>
              {fields.map((it: any, index) => {
                const sub = allSubCategories?.find(
                  (sub) => it.sub_category_id === sub.id
                )
                return (
                  <Col
                    key={'camp_' + it.sub_category_id}
                    md={{ span: 6, offset: 3 }}
                  >
                    {/* Add companies to campaign */}
                    <Select
                      label={`Add "${sub?.name}" companies to campaign:`}
                      control={control}
                      name={`campaigns.${index}.campaign_id`}
                      options={campaignOptions?.filter(
                        (op) => op.companyTypeSlug === sub?.slug
                      )}
                    />
                  </Col>
                )
              })}
            </Row>
          </Container>
        </S.ContentTitle>
      </S.Content>
    </BaseStep>
  )
}
