import { Tabs } from 'components/FbUI/Tabs'
import React, { useEffect, useMemo } from 'react'
import { OpportunitySubCategorySlug } from '../../../models/opportunities'
import { CompanyTypeSlug } from '../../../models/companies'

interface OpportunitiesTabsProps {
  unmatched: boolean
  setUnmatched: (value: boolean) => void
  companyTypeSlug?: CompanyTypeSlug
}

const OpportunitiesTabs: React.FC<OpportunitiesTabsProps> = ({
  companyTypeSlug,
  unmatched,
  setUnmatched,
}) => {
  const noKnownRecords = useMemo(() => {
    const excludedSlugs: OpportunitySubCategorySlug[] = [
      OpportunitySubCategorySlug.RESTAURANTS_BARS,
      OpportunitySubCategorySlug.EDUCATION_CU,
      OpportunitySubCategorySlug.EDUCATION_K_12,
      OpportunitySubCategorySlug.HEALTHCARE_HOSPITALS,
    ]
    return (
      companyTypeSlug &&
      !excludedSlugs.includes(companyTypeSlug as OpportunitySubCategorySlug)
    )
  }, [companyTypeSlug])

  useEffect(() => {
    if (noKnownRecords) {
      // if no known records (firstbite does not have data)
      // default to unmatched view
      setUnmatched(true)
    } else {
      setUnmatched(false)
    }
  }, [noKnownRecords])

  const tabs = noKnownRecords
    ? [{ key: 'unmatched', title: 'Unmatched' }]
    : [
        { key: 'firstbite', title: 'First Bite' },
        { key: 'unmatched', title: 'Unmatched' },
      ]

  return (
    <>
      <Tabs
        tabs={tabs}
        selectedTab={
          noKnownRecords ? 'unmatched' : unmatched ? 'unmatched' : 'firstbite'
        }
        setSelectedTab={(t) =>
          noKnownRecords ? {} : setUnmatched(t === 'unmatched')
        }
        style={{ borderBottom: 'none' }}
        tabStyle={{ width: '140px' }}
      />
    </>
  )
}

export default OpportunitiesTabs
