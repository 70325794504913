import { ColumnDef } from '@tanstack/react-table'
import { OverflownTextTooltip } from 'components/OverflownTextTooltip'
import { useMemo, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { ActionButtons } from '../../../components/Buttons/ActionButtons'
import { ClientDataTable } from '../../../components/ClientDataTable/ClientDataTable'
import { DataTableContainer } from '../../../components/DataTable/UI'
import { FilterChipsComponent } from '../../../components/Filters/components/FilterChips/FilterChips'
import { DateCell } from '../../../components/Tables/CommonTableCells/DateCell'
import { Modal } from '../../../components/UI/Modal/Modal'
import {
  FilterIdentifier,
  SavedView,
  SavedViewTableLabels,
} from '../../../models/saved_view'
import apiService from '../../../services/api'
import * as S from './styles'

export function SavedFiltersTable() {
  const api = apiService()

  const { data: categories } = api.useGetCompanyCategoriesAndSub()
  const { data, isLoading, refetch, isFetching } = api.useGetAllSavedFilters()

  const [filterIdToDelete, setFilterIdToDelete] = useState<number>()

  async function handleRowDelete() {
    if (!filterIdToDelete) return
    await api.deleteFilter(filterIdToDelete)
    setFilterIdToDelete(undefined)
    void refetch()
  }

  const columns = useMemo<ColumnDef<SavedView, any>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'filters',
        header: 'Filters',
        size: 500,
        cell: (info) => {
          const payload = info.row.original.payload
          return (
            <div className={'py-2'}>
              <FilterChipsComponent
                filterState={{
                  valueFilters: payload.valueFilters,
                  rangeFilters: payload.rangeFilters,
                  zipCodeFilter: payload.zipCodeFilter,
                  criteria: payload.criteria,
                  search: payload.search,
                }}
                identifier={info.row.original.identifier}
              />
            </div>
          )
        },
      },
      {
        accessorKey: 'identifier',
        header: 'Table',
        cell: (info) => {
          if (info.getValue().toString().startsWith('companies-')) {
            const id = parseInt(info.getValue().toString().split('-')[1])
            let name = ''
            if (categories) {
              loop: for (const c of categories) {
                for (const s of c.sub_categories) {
                  if (s.id === id) {
                    name = s.name
                    break loop
                  }
                }
              }
            }

            return name
          } else {
            return SavedViewTableLabels[info.getValue() as FilterIdentifier]
          }
        },
      },
      {
        accessorKey: 'shared',
        size: 100,
        header: 'Type',
        cell: (info) => (info.getValue() ? 'Shared' : 'Private'),
      },
      {
        id: 'user__first_name',
        header: 'Created By',
        cell: (info) => {
          return (
            info.row.original?.user?.first_name +
            ' ' +
            info.row.original?.user?.last_name
          )
        },
      },
      {
        accessorKey: 'created',
        header: 'Date Created',
        cell: DateCell,
      },
      {
        accessorKey: 'modified',
        header: 'Date Modified',
        cell: DateCell,
      },
      {
        id: 'edit',
        header: 'Actions',
        meta: { rightAlign: true },
        cell: ({ row }) => {
          return (
            <ActionButtons
              className={'justify-end pr-0'}
              onDelete={() => setFilterIdToDelete(row.original.id)}
            />
          )
        },
      },
    ],
    [data]
  )

  if (isLoading || !data || isFetching) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '500px',
        }}
      >
        <Spinner animation="border" />
      </div>
    )
  }

  const descriptionText = `
  Saved filters from across all tables in First Bite. Private filters are only visible to you.
  `

  return (
    <DataTableContainer>
      <ClientDataTable
        tableHeader={
          <S.SettingsTableHeader>
            <S.TableDescriptionText>
              <OverflownTextTooltip
                tooltipText={descriptionText}
                maxLines={4}
                placement="bottom"
              >
                {descriptionText}
              </OverflownTextTooltip>
            </S.TableDescriptionText>
          </S.SettingsTableHeader>
        }
        height={'100%'}
        stickyFirstColumn={true}
        stickyLastColumn={true}
        data={data || []}
        columns={columns}
      />
      <Modal
        open={!!filterIdToDelete}
        title={'Delete Saved Filter'}
        onOpenChange={(open) => !open && setFilterIdToDelete(undefined)}
        onAccept={() => handleRowDelete()}
        acceptButtonText={'Delete'}
      >
        <span>Are you sure you want to delete this saved filter?</span>
      </Modal>
    </DataTableContainer>
  )
}
