import { Chain } from '../../models/chains'
import { formatInteger } from '../../utils/formatting'
import { ClientDataTable } from '../ClientDataTable/ClientDataTable'
import { Anchor } from '../UI/Anchor'
import { CollapsibleSection } from '../UI/CollapsibleSection/CollapsibleSection'
import { getPathFromUrl } from '../../utils/getPathFromUrl'

interface IChainMenuCardProps {
  instagram?: Chain['instagram']
}

export function ChainIGSection(props: IChainMenuCardProps) {
  if (!props.instagram) {
    return null
  }

  return (
    <CollapsibleSection summary={<>Instagram</>}>
      <ClientDataTable
        height={'max-content'}
        data={[props.instagram]}
        enableSorting={false}
        columns={[
          {
            id: 'instagram_url',
            accessorKey: 'url',
            header: 'Instagram Handle',
            cell: (info) => {
              const value = info.getValue()
              if (!value) return '-'

              return (
                <Anchor href={value} target="_blank">
                  @{getPathFromUrl(value)}
                </Anchor>
              )
            },
          },
          {
            accessorKey: 'followers',
            header: 'Followers',
            cell: (info) => {
              const value = info.getValue()
              if (!value) return 'N/A'

              return formatInteger(value)
            },
          },
          {
            accessorKey: 'following',
            header: 'Following',
            cell: (info) => {
              const value = info.getValue()
              if (!value) return 'N/A'

              return formatInteger(value)
            },
          },
          {
            accessorKey: 'posts',
            header: 'Posts',
          },
          {
            accessorKey: 'instagram_text_percentile',
            header: 'Instagram Ranking',
            cell: (info) => {
              const value = info.getValue()
              if (!value) return 'N/A'

              return value
            },
          },
        ]}
      />
    </CollapsibleSection>
  )
}
