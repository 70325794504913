import { cn } from 'components/UI/cn'
import PropTypes from 'prop-types'
import * as React from 'react'

export const Label = React.forwardRef<
  HTMLLabelElement,
  React.LabelHTMLAttributes<HTMLLabelElement>
>(({ className, ...props }, ref) => (
  <label
    ref={ref}
    className={cn(
      'text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
      className
    )}
    {...props}
  />
))

Label.propTypes = {
  className: PropTypes.string,
}

Label.displayName = 'Label'
