import { ColumnDef } from '@tanstack/react-table'
import { format } from 'date-fns'
import { useMemo, useState } from 'react'
import { DataTable, DataTableProvider } from '../../components/DataTable'
import { DataTableContainer } from '../../components/DataTable/UI'
import { IColumnVisibility } from '../../components/DataTable/types'
import apiService from '../../services/api'
import { ColumnsStoreProvider } from '../../stores/ColumnsStore/ColumnsStoreProvider'
import { RebateEnrollment } from './tradespend_api'
import { RebateEnrollmentDetailsModal } from './RebateEnrollmentDetailsModal'

const TABLE_KEY = 'rebate-enrollments-table'

function RebateOfferEnrollmentsTabComponent({ offerId }: { offerId: number }) {
  const api = apiService()
  const [selectedEnrollmentId, setSelectedEnrollmentId] = useState<
    number | null
  >(null)

  const { data: enrollmentsData, isFetching } =
    api.useGetRebateEnrollments(offerId)

  const columns = useMemo<ColumnDef<RebateEnrollment, any>[]>(
    () => [
      {
        accessorKey: 'tradespend_user_name',
        header: 'User',
        size: 200,
      },
      {
        accessorKey: 'company_name',
        header: 'Company',
        size: 200,
      },
      {
        accessorKey: 'total_claims',
        header: 'Total Claims',
        size: 120,
      },
      {
        accessorKey: 'pending_validation_claims',
        header: 'Claims Pending Validation',
        size: 120,
      },
      {
        accessorKey: 'invalid_claims',
        header: 'Invalid Claims',
        size: 120,
      },
      {
        accessorKey: 'unpaid_valid_claims',
        header: 'Unpaid Valid Claims',
        size: 120,
      },
      {
        accessorKey: 'paid_claims',
        header: 'Paid Valid Claims',
        size: 120,
      },
      {
        accessorKey: 'total_validated_units',
        header: 'Total Valid Units',
        size: 120,
        cell: (info) => {
          const units = info.getValue()
          return units ? units.toFixed(2) : '-'
        },
      },
      {
        accessorKey: 'total_validated_payout',
        header: 'Total Valid Payout',
        size: 120,
        cell: (info) => {
          const amount = info.getValue()
          return amount ? `$${amount.toFixed(2)}` : '-'
        },
      },
      {
        accessorKey: 'created',
        header: 'Enrolled On',
        size: 150,
        cell: (info) => format(new Date(info.getValue()), 'MMM d, yyyy'),
      },
    ],
    []
  )

  return (
    <>
      <DataTableContainer>
        <DataTable
          loading={isFetching}
          height={600}
          data={enrollmentsData?.results ?? []}
          columns={columns}
          tableKey={TABLE_KEY}
          onRowClick={(row) => setSelectedEnrollmentId(row.original.id)}
        />
      </DataTableContainer>

      {selectedEnrollmentId && (
        <RebateEnrollmentDetailsModal
          open={true}
          onClose={() => setSelectedEnrollmentId(null)}
          enrollmentId={selectedEnrollmentId}
        />
      )}
    </>
  )
}

export function RebateOfferEnrollmentsTab({
  offerId,
  defaultColumnVisibility,
}: {
  offerId: number
  defaultColumnVisibility?: IColumnVisibility<any>
}) {
  return (
    <ColumnsStoreProvider
      tableKey={TABLE_KEY}
      defaultColumnVisibility={defaultColumnVisibility}
    >
      <DataTableProvider tableKey={TABLE_KEY}>
        <RebateOfferEnrollmentsTabComponent offerId={offerId} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}
