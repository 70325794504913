import { RowSelectionState } from '@tanstack/react-table'
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'

const DataTableContext = createContext<DataTableContextData | null>(null)

export function DataTableProvider({ children }: DataTableProviderProps) {
  const [expanded, setExpanded] = React.useState<string[]>([])
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const clearSelectedRows = () => {
    setRowSelection({})
    setIsAllRowsSelected(false)
  }

  const [isAllRowsSelected, setIsAllRowsSelected] = useState<boolean>(false)
  const [totalRowsInBackend, setTotalRowsInBackend] = useState<number>(0)
  const [totalRowsInPage, setTotalRowsInPage] = useState<number>(0)

  const totalSelectedRows = useMemo(() => {
    if (isAllRowsSelected) {
      let total = totalRowsInBackend
      // Exclude unselected rows from the total
      const selectedRowsCount = Object.keys(rowSelection).length
      total = total - (totalRowsInPage - selectedRowsCount)
      return total
    } else {
      return Object.keys(rowSelection).length
    }
  }, [rowSelection, isAllRowsSelected, totalRowsInBackend])

  return (
    <DataTableContext.Provider
      value={{
        methods: {
          setRowSelection,
          clearSelectedRows,
          setExpanded,
          setIsAllRowsSelected,
          setTotalRowsInBackend,
          setTotalRowsInPage,
        },
        state: {
          isAllRowsSelected,
          rowSelection,
          expanded,
          totalSelectedRows,
          totalRowsInBackend,
          totalRowsInPage,
        },
      }}
    >
      {children}
    </DataTableContext.Provider>
  )
}

type DataTableContextData = {
  methods: {
    setRowSelection: Dispatch<SetStateAction<RowSelectionState>>
    clearSelectedRows: () => void
    setExpanded: React.Dispatch<React.SetStateAction<string[]>>
    setIsAllRowsSelected: Dispatch<SetStateAction<boolean>>
    setTotalRowsInBackend: Dispatch<SetStateAction<number>>
    setTotalRowsInPage: React.Dispatch<React.SetStateAction<number>>
  }
  state: {
    isAllRowsSelected: boolean
    rowSelection: RowSelectionState
    expanded: string[]
    totalSelectedRows: number
    totalRowsInBackend: number
    totalRowsInPage: number
  }
}

type DataTableProviderProps = {
  children: any
  tableKey: string
}

export function useDataTableContext() {
  const context = useContext(
    DataTableContext as unknown as React.Context<DataTableContextData>
  )

  if (!context) {
    console.info('useDataTableContext must be used within a DataTableProvider')
    return {} as DataTableContextData
  }

  return { ...context }
}
