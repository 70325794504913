import apiService from 'services/api'
import { Tag } from 'models/tags'
import BaseSelectModal, {
  BaseSelectModalProps,
} from './BaseSelectModal/BaseSelectModal'
import { bulkEditSingleParams } from 'services/apiTypes'

export default function SalesStageSelectModal({
  tableObjAccessor = 'sales_stage',
  ...props
}: {
  tableObjAccessor?: string
} & Omit<
  BaseSelectModalProps<Tag>,
  | 'apiAction'
  | 'title'
  | 'subtitle'
  | 'label'
  | 'placeholder'
  | 'onConfirmAddNew'
  | 'items'
  | 'tableObjAccessor'
>) {
  const api = apiService()
  const { data: salesStages } = api.useGetSalesStageOptions(true)

  const apiAction = async (params: bulkEditSingleParams) => {
    const { item_id: sales_stage, ...rest } = params
    return api.bulkEditDeal({
      deal: { sales_stage_id: sales_stage },
      ...rest,
    })
  }

  if (!salesStages) return null

  return (
    <BaseSelectModal
      {...props}
      title="Change Sales Stage"
      subtitle={
        <span>
          Add or replace the sales stage for <b>all</b> selected rows.
        </span>
      }
      label="Sales Stage"
      placeholder="Select Sales Stage"
      items={salesStages}
      tableObjAccessor={tableObjAccessor!}
      apiAction={apiAction}
    />
  )
}
