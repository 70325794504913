import React, { useEffect, useMemo, useRef, useState } from 'react'
import Checkbox from 'components/Checkbox/checkbox'
import * as S from './ExportModal.styles'
import { hasIntersection } from 'utils/hasIntersection'
import { Modal } from '../../UI/Modal/Modal'
import ReactSelectWrapper from 'components/FormUtils/ReactSelectWrapper'
import { useColumnStoreContext } from 'stores/ColumnsStore/useColumnsStoreContext'

export interface ExportModalProps {
  show: boolean
  _handleClose: () => void
  count: number
  filters: Record<string, unknown>
  productSelected?: number
  selectedIds?: number[]
  excludeIds?: number[]
}

export interface ExportAPIActionArgs {
  filter: Record<string, unknown>
  selectedColumns: string[]
  selectedProductId?: number
  exportType: 'csv' | 'xlsx'
  ids: number[] | undefined
  excludeIds: number[] | undefined
  controller?: AbortController
}

interface ExportModalPropsPrivate extends ExportModalProps {
  columns: {
    title: string
    elements: string[][]
  }[]
  exportAPIAction: (args: ExportAPIActionArgs) => Promise<void>
  title: string
  subTitle: string
  submitText: string
}

const ExportModal = (props: ExportModalPropsPrivate) => {
  const [controller, setController] = useState<AbortController>()
  const [exportType, setExportType] = useState<'csv' | 'xlsx'>('csv')

  const [uncheckedList, setUncheckedList] = useState<string[]>([])

  const selectedColumns = useColumnStoreContext(
    (state) => state.selectedColumns
  )

  useEffect(() => {
    if (!selectedColumns) return
    const columnsToUncheck: string[] = []
    props.columns.forEach((column) => {
      column.elements.forEach((element) => {
        if (!selectedColumns.find((col) => col.id === element[0])) {
          columnsToUncheck.push(element[0])
        }
      })
    })
    setUncheckedList(columnsToUncheck)
  }, [selectedColumns, props.columns, setUncheckedList])

  const manageUnchecked = (id: string) => {
    if (uncheckedList.includes(id)) {
      setUncheckedList((prevArray) => prevArray.filter((elem) => elem !== id))
    } else {
      setUncheckedList((prevArray) => [...prevArray, id])
    }
  }

  async function onSubmit() {
    const selected: string[] = []
    props.columns.forEach((column) => {
      column.elements.forEach((element) => {
        if (!uncheckedList.includes(element[0])) {
          selected.push(element[0])
        }
      })
    })

    await props.exportAPIAction({
      filter: props.filters,
      selectedColumns: selected,
      selectedProductId: props.productSelected,
      exportType,
      controller,
      ids: props.selectedIds,
      excludeIds: props.excludeIds,
    })
  }

  useEffect(() => {
    if (!controller) {
      const _controller = new AbortController()
      setController(_controller)
    } else if (!props.show) {
      controller.abort()
      setController(undefined)
    }
  }, [props.show])

  return (
    <Modal
      size={'lg'}
      title={props.title}
      description={props.subTitle}
      open={props.show}
      onOpenChange={(open) => !open && props._handleClose()}
      onAccept={onSubmit}
      acceptButtonText={`${props.submitText}`}
      footerChildren={
        <SelectExportType
          exportType={exportType}
          setExportType={setExportType}
        />
      }
    >
      <div className={'flex gap-3'}>
        {props.columns?.map((el) => (
          <div className={'flex-1'} key={el.title}>
            <div className={'flex items-center gap-2'}>
              <Checkbox
                boxSize={'lg'}
                checked={
                  !hasIntersection(
                    el.elements.map((it) => it[0]),
                    uncheckedList
                  )
                }
                onChange={() => {
                  if (
                    hasIntersection(
                      el.elements.map((it) => it[0]),
                      uncheckedList
                    )
                  ) {
                    setUncheckedList((prevArray) =>
                      prevArray.filter(
                        (elem) => !el.elements.map((el) => el[0]).includes(elem)
                      )
                    )
                  } else {
                    setUncheckedList((prevArray) => [
                      ...prevArray,
                      ...el.elements.map((el) => el[0]),
                    ])
                  }
                }}
              />
              <S.ColumnLabel>{el.title}</S.ColumnLabel>
            </div>
            {el.elements.map((item) => {
              return (
                <ColumnItem
                  key={item[0]}
                  label={item[1]}
                  checked={!uncheckedList.includes(item[0])}
                  onChange={() => manageUnchecked(item[0])}
                />
              )
            })}
          </div>
        ))}
      </div>
    </Modal>
  )
}

const SelectExportType = ({
  exportType,
  setExportType,
}: {
  exportType: 'csv' | 'xlsx'
  setExportType: (type: 'csv' | 'xlsx') => void
}) => {
  const _value = useMemo(() => {
    return { label: exportType.toUpperCase(), value: exportType }
  }, [exportType])

  return (
    <div className={'flex gap-3 justify-center'}>
      <div className="w-1/3">
        <label
          className="text-base font-medium text-gray-800 select-none mb-1"
          htmlFor="exportType"
        >
          Export Type
        </label>
        <ReactSelectWrapper
          name={'exportType'}
          options={[
            { label: 'CSV', value: 'csv' },
            { label: 'XLSX', value: 'xlsx' },
          ]}
          value={_value}
          onChange={(value) =>
            setExportType((value as { value: 'csv' | 'xlsx' }).value)
          }
        />
      </div>
    </div>
  )
}

const ColumnItem = (props: {
  label: string
  onChange: (checked: boolean) => void
  checked?: boolean
}) => {
  const checkBoxRef = useRef<HTMLInputElement>(null)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.checked)
  }

  const toggleChecked = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    checkBoxRef.current?.click()
  }

  return (
    <S.ColumnItemContainer checked={props.checked} onClick={toggleChecked}>
      <Checkbox
        ref={checkBoxRef}
        checked={props.checked}
        onChange={handleChange}
      />
      {props.label}
    </S.ColumnItemContainer>
  )
}

export default ExportModal
