import FbButton, { IFbButtonProps } from 'components/FbUI/FbButton'
import { cn } from 'components/UI/cn'
import React from 'react'

export interface ITableButtonProps extends IFbButtonProps {
  icon: (props: any) => JSX.Element
  text: string
  activeValue?: string | null
  iconSize?: number

  postpendIcon?: (props: any) => JSX.Element
  onPostpendIconClick?: () => void
}

const TableButton = React.forwardRef((props: ITableButtonProps, ref: any) => {
  const {
    icon,
    text,
    activeValue,
    iconSize = 24,
    postpendIcon,
    onPostpendIconClick,
    ...rest
  } = props

  return (
    <div className="flex xl:min-w-[140px]">
      <FbButton
        ref={ref}
        variant="secondary"
        className={cn(
          {
            active: activeValue !== null,
            'rounded-r-none': !!postpendIcon,
          },
          'bg-white xl:min-w-[120px] gap-2 justify-start text-nowrap flex-1'
        )}
        {...rest}
      >
        {icon({ size: iconSize })}
        <span className={'hidden xl:inline'}>
          {text}
          {activeValue && (
            <span>
              {' '}
              (
              <span>
                <strong>{activeValue}</strong>
              </span>
              )
            </span>
          )}
        </span>
      </FbButton>
      {!!postpendIcon && (
        <FbButton
          variant={'primary'}
          className="rounded-l-none rounded-r px-2 -ml-1"
          onClick={onPostpendIconClick}
        >
          {postpendIcon({ size: iconSize })}
        </FbButton>
      )}
    </div>
  )
})
TableButton.displayName = 'TableButton'
export default TableButton
