import { usePreferences } from 'context/preferences/PreferencesContext'
import { ContactCompanyType } from 'models/contact_companies'
import React from 'react'
import { useParams } from 'react-router-dom'
import apiService from 'services/api'
import { getIdWithPrefix } from 'utils/pathUtils'
import CommonPage from 'components/DataExpansionComponents/Common/CommonPage'
import { CARNEGIE_CLASSIFICATION_SIZE_OPTIONS } from 'utils/carnegieClassificationSize'
import { formatInteger } from 'utils/formatting'

export default function University() {
  const urlParams = useParams()
  const { preferences } = usePreferences()
  const universityID = urlParams?.university_name
    ? getIdWithPrefix(
        urlParams?.university_name,
        preferences?.company_type_prefix_map[ContactCompanyType.University]
      )
    : undefined

  const api = apiService()
  const { data, isLoading, isError, error } = api.useGetUniversity(universityID)

  return (
    <CommonPage
      uniqueCompanyTypeId={universityID}
      useGetCompany={{
        companyData: data,
        isLoading,
        isError,
        error,
      }}
      companyTypeSlug="education-cu"
      breadcrumbs={[
        {
          title: 'University',
          breadcrumb: data?.university?.institution_name,
        },
      ]}
      getFieldsFromData={(data) => ({
        contactCompanyId: data?.id,
        companyName: data?.university?.institution_name || '',
        websiteAddress: data?.university?.website_address || '',
        address: {
          addressUrl: data?.university?.google_place_url,
          addressInfo: {
            fullAddress: data?.university?.address,
            city: data?.university?.city,
            state: data?.university?.state_abbr,
            zipcode: data?.university?.zipcode,
          },
          fullAddressInfo: data?.university?.address_info,
        },
      })}
      customBottomLeftItems={[
        {
          header: 'Carnegie Classification: Size',
          text:
            CARNEGIE_CLASSIFICATION_SIZE_OPTIONS.find(
              (option) =>
                option.value ===
                data?.university?.carnegie_classification_2021_size_setting
            )?.label ?? 'Not available',
        },
        {
          header: 'Estimated Meals per Day',
          text: formatInteger(data?.university?.estimated_meals_per_day),
        },
        {
          header: 'Meal Plan Provided',
          text: data?.university?.meal_plan_provided ? 'Yes ✅' : 'No ⛔',
        },
      ]}
    />
  )
}
