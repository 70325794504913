import { useAuth } from 'context/authentication/useAuth'
import { ContactCompanyType } from 'models/contact_companies'
import { Preferences } from 'models/preferences'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import apiService from 'services/api'

const PreferencesContext = createContext<{
  preferences: Preferences | undefined
  isLoading: boolean
}>({
  preferences: undefined,
  isLoading: true,
})

export const PreferencesProvider = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated } = useAuth()
  const api = apiService()
  const [isLoading, setIsLoading] = useState(true)

  const initialPreferences = useMemo(
    () => ({
      tooltips: {},
      feature_flags: {},
      misc_settings: [],
      company_type_prefix_map: JSON.parse(
        localStorage.getItem('company_type_prefix_map') ||
          JSON.stringify({
            [ContactCompanyType.Chain]: 'RE',
            [ContactCompanyType.University]: 'CU',
            [ContactCompanyType.K12District]: 'DI',
            [ContactCompanyType.UserGenerated]: 'UG',
            [ContactCompanyType.Hospital]: 'HO',
          })
      ),
    }),
    []
  )

  const [localPreferences, setLocalPreferences] =
    useState<Preferences>(initialPreferences)

  const { data: serverPreferences } = api.useGetPreferences(!isAuthenticated)

  useEffect(() => {
    if (serverPreferences) {
      setLocalPreferences((prevPreferences) => {
        const server_company_type_prefix_map_json = JSON.stringify(
          serverPreferences.company_type_prefix_map
        )
        if (
          JSON.stringify(prevPreferences.company_type_prefix_map) !==
          server_company_type_prefix_map_json
        ) {
          localStorage.setItem(
            'company_type_prefix_map',
            server_company_type_prefix_map_json
          )
        }
        return serverPreferences
      })
      setIsLoading(false)
    }
  }, [serverPreferences])

  return (
    <PreferencesContext.Provider
      value={{
        preferences: localPreferences,
        isLoading,
      }}
    >
      {children}
    </PreferencesContext.Provider>
  )
}

export const usePreferences = () => {
  const context = useContext(PreferencesContext)
  return context
}
