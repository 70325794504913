import { useMutation, useQuery } from '@tanstack/react-query'

import lodash from 'lodash'
import qs from 'query-string'
import {
  CONTACTS_TABLE_KEY,
  DEAL_TABLE_KEY,
  getCampaignTableKey,
} from '../../constants/tableQueryKeys'
import { Campaign } from '../../models/campaign'
import { ChainListResponse } from '../../models/chains'
import { CompanyTypeSlug } from '../../models/companies'
import { baseAPICall } from '../../services/api'
import { queryClient } from '../../services/queryClient'
import { clientInstance } from '../../utils/http-client'

const CAMPAIGNS_LIST_KEY = 'campaigns-list'

/* -------------
List Campaigns
-------------- */
async function getCampaigns(
  signal?: AbortSignal,
  queryParams?: Record<string, any>
) {
  return baseAPICall<Campaign[]>(() =>
    clientInstance.get('contacts/campaigns/', {
      signal,
      params: queryParams,
      paramsSerializer: (p) => {
        return qs.stringify(p)
      },
    })
  )
}

export const useGetCampaigns = (
  signal?: AbortSignal,
  disabled?: boolean,
  queryParams?: Record<string, any>
) =>
  useQuery({
    queryKey: [CAMPAIGNS_LIST_KEY, JSON.stringify(queryParams)],
    queryFn: () => getCampaigns(signal, queryParams),
    refetchOnMount: false,
    enabled: !disabled,
  })

/* -------------
Get Campaign Options
-------------- */
interface CampaignOption {
  label: string
  value: number | string
  icon?: React.JSX.Element
  companyTypeSlug?: CompanyTypeSlug
}

export const useGetCampaignsOptions = (
  type?: CompanyTypeSlug,
  signal?: AbortSignal,
  withNoneOption = false,
  disabled = false
) =>
  useQuery({
    queryKey: [CAMPAIGNS_LIST_KEY, 'options', type],
    queryFn: async () => {
      const res = await getCampaigns(signal)
      const campaignOptions: CampaignOption[] = (
        type ? res.filter((it) => it.type.slug === type) : res
      ).map((c) => ({
        label: c.name,
        value: c.id,
        companyTypeSlug: c.type.slug,
        icon: (
          <div
            className={`h-3 w-3 rounded-full mr-2 flex-shrink-0`}
            style={{
              backgroundColor: c.color,
            }}
          />
        ),
      }))

      if (withNoneOption) {
        campaignOptions.unshift({
          label: 'No Campaign',
          value: 'REMOVE',
        })
      }

      return campaignOptions
    },
    refetchOnMount: false,
    enabled: !disabled,
  })

/* -------------
Create Campaign
-------------- */
type CreateCampaignParams = {
  type_slug: CompanyTypeSlug
  product_id?: number
  name: string
  color: string
  description: string
}

async function createCampaign(values: CreateCampaignParams) {
  return baseAPICall<Campaign>(
    () => clientInstance.post('contacts/campaigns/create/', values),
    { successMessage: 'Campaign created successfully' }
  )
}

export const useCreateCampaign = () => {
  return useMutation({
    mutationFn: createCampaign,
    onMutate: async () => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({
        queryKey: [CAMPAIGNS_LIST_KEY],
        exact: false,
      })
    },
    onSettled: () => {
      // Always refetch after error or success to ensure consistency
      queryClient.invalidateQueries({ queryKey: [CAMPAIGNS_LIST_KEY] })
    },
  })
}

/* -------------
Edit Campaign
-------------- */
type EditCampaignParams = Partial<CreateCampaignParams>

async function editCampaign(id: number, values: EditCampaignParams) {
  return baseAPICall<Campaign>(
    () => clientInstance.put(`contacts/campaigns/${id}/edit/`, values),
    { successMessage: 'Campaign updated successfully' }
  )
}

export const useEditCampaign = () => {
  return useMutation({
    mutationFn: ({
      id,
      product_id,
      ...values
    }: EditCampaignParams & {
      id: number
      product?: { id: number; name: string }
      product_id?: number
    }) => editCampaign(id, { ...values, product_id }),

    onMutate: async (newCampaign) => {
      // Get the main campaign list query
      const mainQueryKey = [CAMPAIGNS_LIST_KEY]
      const mainQuery = queryClient.getQueryData(mainQueryKey)

      // Cancel any outgoing refetches to avoid optimistic update being overwritten
      await queryClient.cancelQueries({
        queryKey: mainQueryKey,
        exact: false,
      })

      // Update the campaign in all related queries
      const updateCampaign = (campaign: Campaign) => ({
        ...campaign,
        name: newCampaign.name ?? campaign.name,
        description: newCampaign.description ?? campaign.description,
        color: newCampaign.color ?? campaign.color,
        product: newCampaign.product,
      })

      // Apply updates to all matching queries
      queryClient.setQueriesData(
        { queryKey: mainQueryKey, exact: false },
        (old: Campaign[] | undefined) => {
          if (!old) return old
          return old.map((campaign) =>
            campaign.id === newCampaign.id ? updateCampaign(campaign) : campaign
          )
        }
      )

      // Return the main query data for rollback
      return {
        prev: mainQuery,
        queryKey: mainQueryKey,
      }
    },

    onError: (_, __, context) => {
      // Restore the previous data if there was an error
      if (context?.prev && context.queryKey) {
        queryClient.setQueryData(context.queryKey, context.prev)
      }
    },
  })
}

/* -------------
Delete Campaign
-------------- */
async function deleteCampaign(id: number) {
  return baseAPICall(
    () => clientInstance.delete(`contacts/campaigns/${id}/delete/`),
    {
      successMessage: 'Campaign deleted successfully',
    }
  )
}

export const useDeleteCampaign = () => {
  return useMutation({
    mutationFn: deleteCampaign,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CAMPAIGNS_LIST_KEY] })
    },
  })
}

/* -------------
Assign To Campaign
-------------- */
export type CampaignAssignRequestCompanyType =
  | 'chain'
  | 'k12'
  | 'cnu'
  | 'hospital'
  | 'ugc'

type AssignToCampaignRequest = {
  campaign_id: number
  campaign_name: string
  campaign_color: string
  chain_proxy_ids?: number[]
  exclude_chain_proxy_ids?: number[]
  params: any
}

async function assignToCampaign(
  companyType: CampaignAssignRequestCompanyType,
  body: AssignToCampaignRequest
) {
  return baseAPICall(
    () =>
      clientInstance.post(
        `contacts/campaigns/assign/${companyType}/`,
        {
          campaign_id: body.campaign_id,
          chain_proxy_ids: body.chain_proxy_ids,
          exclude_chain_proxy_ids: body.exclude_chain_proxy_ids,
          assign: true,
        },
        {
          params: body.params,
          paramsSerializer: (p) => {
            return qs.stringify(p)
          },
        }
      ),
    {
      successMessage: 'Succesfully Assigned To Campaign',
    }
  )
}

export const useAssignToCampaignMutation = (
  oppsListQueryKey: string[],
  chainProxyIdAccessor = 'id',
  companyTypeSlug?: CompanyTypeSlug
) => {
  let assignCompanyType: CampaignAssignRequestCompanyType
  switch (companyTypeSlug) {
    case 'restaurants-bars':
      assignCompanyType = 'chain'
      break
    case 'education-cu':
      assignCompanyType = 'cnu'
      break
    case 'education-k-12':
      assignCompanyType = 'k12'
      break
    case 'healthcare-hospitals':
      assignCompanyType = 'hospital'
      break
    default:
      assignCompanyType = 'ugc'
      break
  }

  return useMutation({
    mutationFn: (variables: AssignToCampaignRequest) =>
      assignToCampaign(assignCompanyType, variables),
    onMutate: async (newCampaign) => {
      if (!oppsListQueryKey) {
        return
      }

      // Base table
      await queryClient.cancelQueries({ queryKey: oppsListQueryKey })
      const prev = queryClient.getQueryData(oppsListQueryKey) as {
        results: any[]
      }
      queryClient.setQueryData(oppsListQueryKey, (old: ChainListResponse) => {
        const data = structuredClone(old)

        newCampaign.chain_proxy_ids?.forEach((id) => {
          const row = data.results.find(
            (d) => lodash.get(d, chainProxyIdAccessor) === id
          )
          if (row) {
            if (
              !row.campaigns.some(
                (cmp) => cmp.name === newCampaign.campaign_name
              )
            ) {
              row.campaigns = [
                ...row.campaigns,
                {
                  id: newCampaign.campaign_id,
                  name: newCampaign.campaign_name,
                  color: newCampaign.campaign_color,
                } as Campaign,
              ]
            }
          }
        })

        return data
      })

      // Campaign table
      const campaignTableQueryKey = [
        getCampaignTableKey(oppsListQueryKey[0], newCampaign.campaign_id),
      ]
      const prevCamp = queryClient.getQueriesData({
        queryKey: campaignTableQueryKey,
      })
      queryClient.setQueriesData(
        { queryKey: campaignTableQueryKey },
        (old?: { results: any[] }) => {
          if (!old) {
            return
          }
          const data = structuredClone(old)
          newCampaign.chain_proxy_ids?.forEach((chain_proxy_id) => {
            if (
              !data.results.some(
                (it) => lodash.get(it, chainProxyIdAccessor) === chain_proxy_id
              )
            ) {
              data.results.push(
                prev?.results.find(
                  (it) =>
                    lodash.get(it, chainProxyIdAccessor) === chain_proxy_id
                )
              )
            }
          })
          return data
        }
      )

      return { prev, prevCamp: prevCamp?.[0]?.[1] }
    },
    onError: (_, newCampaign, context) => {
      // Restore base table
      queryClient.setQueryData(oppsListQueryKey, context?.prev)

      // Restore campaign table
      queryClient.setQueriesData(
        {
          queryKey: [
            getCampaignTableKey(oppsListQueryKey[0], newCampaign.campaign_id),
          ],
        },
        context?.prevCamp
      )
    },
    onSettled: (_, __, variables) => {
      void queryClient.invalidateQueries({
        queryKey: [getCampaignTableKey(DEAL_TABLE_KEY, variables.campaign_id)],
      })
      void queryClient.invalidateQueries({
        queryKey: [
          getCampaignTableKey(CONTACTS_TABLE_KEY, variables.campaign_id),
        ],
      })
    },
  })
}

/* -------------
Unassign From Campaign
-------------- */
type UnassignFromCampaignRequest = {
  campaign_id: number
  chain_proxy_ids?: number[]
  exclude_chain_proxy_ids?: number[]
  params: any
}

async function unassignFromCampaign(
  companyType: CampaignAssignRequestCompanyType,
  body: UnassignFromCampaignRequest
) {
  return baseAPICall(
    () =>
      clientInstance.post(
        `contacts/campaigns/assign/${companyType}/`,
        {
          campaign_id: body.campaign_id,
          chain_proxy_ids: body.chain_proxy_ids,
          exclude_chain_proxy_ids: body.exclude_chain_proxy_ids,
          assign: false,
        },
        {
          params: body.params,
        }
      ),
    {
      successMessage: 'Succesfully Unassigned From Campaign',
    }
  )
}

export const useUnassignToCampaignMutation = (
  companyType: CampaignAssignRequestCompanyType,
  oppsListQueryKey: string[],
  chainProxyIdAccessor = 'id'
) => {
  return useMutation({
    mutationFn: (variables: UnassignFromCampaignRequest) =>
      unassignFromCampaign(companyType, variables),
    onMutate: async (newCampaign) => {
      if (!oppsListQueryKey) {
        return
      }
      await queryClient.cancelQueries({ queryKey: oppsListQueryKey })

      const prev = queryClient.getQueryData(oppsListQueryKey)

      queryClient.setQueryData(oppsListQueryKey, (old: any) => {
        const data = structuredClone(old)

        const results = data.results.filter(
          (it: any) =>
            !newCampaign.chain_proxy_ids?.includes(
              lodash.get(it, chainProxyIdAccessor)
            )
        )

        data.results = results

        return data
      })

      return { prev }
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(oppsListQueryKey, context?.prev)
    },
  })
}

/* -------------
Campaign Companies
(for contact request modal)
-------------- */
async function getCampaignCompanies(campaignId: number, signal?: AbortSignal) {
  return baseAPICall<CampaignCompaniesResponse>(() =>
    clientInstance.get(`contacts/campaigns/${campaignId}/companies/`, {
      signal,
    })
  )
}

export const useGetCampaignCompanies = (
  campaignId: number,
  signal?: AbortSignal
) =>
  useQuery({
    queryKey: ['campaign', campaignId, 'companies'],
    queryFn: () => getCampaignCompanies(campaignId, signal),
  })

export interface CampaignCompaniesResponse {
  results: Array<{
    id: number
    name: string
  }>
  total_count: number
}
