import { zodResolver } from '@hookform/resolvers/zod'
import lodash from 'lodash'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { z } from 'zod'
import { CompanyTypeSlug } from '../../models/companies'
import apiService from '../../services/api'
import { ClientDataTable } from '../ClientDataTable/ClientDataTable'
import { useDataTableContext } from '../DataTable/DataTableContext'
import { NumericInput } from '../FormUtils/NumericInput'
import { Modal } from '../UI/Modal/Modal'

export interface CreateContactRequestJobModalProps {
  open: boolean
  onClose: () => void
  accessorKeys: string[]
  tableQueryKey: string[]
  requestParams: Record<string, any>
  companyTypeSlug: CompanyTypeSlug
  data: any[]
}

export const MAX_OPPORTUNITIES_REQUESTED = 500

const formSchema = z.object({
  max_contacts: z
    .number()
    .min(1, { message: 'Please enter a number between 1 and 5.' })
    .max(5, { message: 'Please enter a number between 1 and 5.' }),
})

type FormSchema = z.infer<typeof formSchema>

export function CreateContactRequestJobModal(
  props: CreateContactRequestJobModalProps
) {
  const api = apiService()
  const {
    state: { rowSelection, isAllRowsSelected, totalRowsInBackend },
  } = useDataTableContext()

  const selectedRows = props.data?.filter((_, i) => Boolean(rowSelection?.[i]))

  const selectedChainProxyIds = selectedRows
    ?.map((r: any) => {
      for (const key of props.accessorKeys) {
        const value = lodash.get(r, key)
        if (value !== undefined) {
          return value
        }
      }
      return undefined
    })
    .filter((id): id is number => id !== undefined)

  const unselectedRows = props.data?.filter((_, i) => {
    return !rowSelection?.[i]
  })

  const unselectedChainProxyIds = unselectedRows
    ?.map((r: any) => {
      for (const key of props.accessorKeys) {
        const value = lodash.get(r, key)
        if (value !== undefined) {
          return value
        }
      }
      return undefined
    })
    .filter((id): id is number => id !== undefined)

  const form = useForm<FormSchema>({
    defaultValues: {
      max_contacts: 1,
    },
    resolver: zodResolver(formSchema),
    mode: 'onChange',
  })

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = form

  const handleAccept = async (values: FormSchema) => {
    try {
      await api.createContactRequestJob(
        props.companyTypeSlug,
        {
          max_contacts: values.max_contacts,
          chain_proxy_ids: isAllRowsSelected
            ? undefined
            : selectedChainProxyIds,
          exclude_chain_proxy_ids: unselectedChainProxyIds,
          sub_category_slug: props.companyTypeSlug,
        },
        props.requestParams
      )
      toast.success(
        "Contacts successfully requested. Check status in the 'Contact Requests' section of the Contacts page"
      )
      props.onClose()
    } catch (err) {
      toast.error(`Error Requesting Contacts: ${err}.`)
    }
  }

  return (
    <Modal
      open={props.open}
      onOpenChange={(open) => !open && props.onClose()}
      title={'Request Contacts'}
      onAccept={handleSubmit(handleAccept)}
      acceptButtonText={'Request Contacts'}
      blockAccept={!isValid}
    >
      <div className="overflow-y-auto">
        <ClientDataTable
          columns={[
            {
              header: 'Company Name',
              cell: ({ row }: any) =>
                row.original?.chain?.chain || row.original?.name,
            },
          ]}
          data={selectedRows || []}
        />
      </div>
      {isAllRowsSelected && totalRowsInBackend > selectedRows.length && (
        <div className="mt-2 text-sm text-gray-500 flex justify-end">
          <span>... {totalRowsInBackend - selectedRows.length} more</span>
        </div>
      )}
      <NumericInput
        label={'How many contacts per company?'}
        control={control}
        fieldName={'max_contacts'}
      />
    </Modal>
  )
}
