import styled from 'styled-components/macro'

// Table
export const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: separate; /* Don't collapse */
`

export const THead = styled.thead`
  background: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  border-radius: 8px 8px 0 0;
`
export const TRHead = styled.tr`
  height: 44px;
  background: #fcfcfd;
  z-index: 1;
`
export const TH = styled.th<{ showShadow?: boolean }>`
  border-bottom: 1px solid #eaecf0;
  background: #fcfcfd;
  padding-left: 24px;
  position: sticky;
  top: -1px;
  z-index: 15;

  &:first-child {
    padding-left: 12px;
  }
  &:last-child {
    padding-right: 24px;
  }
`
export const TBody = styled.tbody``

export const TR = styled.tr<{
  expandCell?: boolean
  isExpanded?: boolean
  isSelected?: boolean
  isDisabled?: boolean
}>`
  border-bottom: 1px solid #eaecf0;

  /* Base background color */
  background-color: ${(props) => {
    if (props.isDisabled) return '#F9FAFB'
    if (props.isExpanded) return '#F4FAF8'
    if (props.isSelected) return '#F9F9F9'
    return '#fff'
  }};

  /* Hover state */
  &:hover {
    background-color: ${(props) =>
      props.isSelected ? '#D0DCD5' : '#E7EDEA'} !important;
  }

  ${(props) =>
    props.expandCell &&
    `
    &:hover {
      & > td {
        background: inherit;
      }
    }
  `}
`

export const TD = styled.td`
  background-color: inherit;
  padding-left: 24px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #101828;
  border-bottom: 1px solid #eaecf0;
  word-break: break-word;

  & a {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #087443;
  }

  &:last-child {
    padding-right: 24px;
  }
`

export const TRExtra = styled.tr`
  max-width: 10px;
  border-left: 1px solid #eaecf0;
  border-right: 1px solid #eaecf0;
  border-bottom: 1px solid #eaecf0;
`

export const TDExtra = styled.td`
  background-color: #f4faf8 !important;
`

export const HeadingText = styled.span<{
  rightAlign?: boolean
}>`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #667085;
  text-align: ${(props) => (props.rightAlign ? 'right' : 'left')};
`

export const CheckboxRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const TFooter = styled.div`
  width: 100%;
  background: #f9fafb;
  border-top: 1px solid #eaecf0;
  border-radius: 0 0 8px 8px;
  position: sticky;
  bottom: -1px;
  left: 0;
  z-index: 30;
`
