import React, { useState } from 'react'
import { ConditionalWrapper } from '../../ConditionalWrapper'
import FbButton, { IFbButtonProps } from '../../FbUI/FbButton'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogContentSize,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ModalPrimitive'
import clsx from 'clsx'

export interface IModalProps {
  open?: boolean
  onOpenChange?: (open: boolean) => void
  trigger?: React.JSX.Element
  title: string | React.JSX.Element
  description?: string | React.JSX.Element
  size?: DialogContentSize
  loading?: boolean
  noPadding?: boolean
  noScroll?: boolean
  hideActions?: boolean

  blockAccept?: boolean
  onAccept?: () => void | Promise<void>
  acceptButtonProps?: IFbButtonProps
  acceptButtonText?: string

  onAlt?: () => void | Promise<void>
  altButtonProps?: IFbButtonProps
  altButtonText?: string
  blockAlt?: boolean

  onCancel?: () => void
  cancelButtonText?: string
  cancelButtonProps?: IFbButtonProps
  blockCancel?: boolean

  preventCloseOnCancel?: boolean
  hideCancelButton?: boolean

  children?: React.ReactNode
  footerChildren?: React.ReactNode

  childrenContainerClassName?: string
  dialogFooterClassName?: string
}

export function Modal(props: IModalProps) {
  const [isLoading, setLoading] = useState(false)
  const [altLoading, setAltLoading] = useState(false)

  async function handleAccept() {
    setLoading(true)
    try {
      await props.onAccept?.()
    } finally {
      setLoading(false)
    }
  }

  async function handleAltAccept() {
    setAltLoading(true)
    await props.onAlt?.()
    setAltLoading(false)
  }

  const defaultChildrenContainerClassName =
    'max-h-[70vh] overflow-x-hidden max-w-full w-full my-3'
  const defaultDialogFooterClassName =
    'flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2 py-2'

  return (
    <Dialog open={props.open} onOpenChange={props.onOpenChange}>
      {props.trigger ? (
        <DialogTrigger asChild>{props.trigger}</DialogTrigger>
      ) : null}
      <DialogContent
        id={'fb-modal-content'}
        noPadding={props.noPadding}
        size={props.size}
        forceMount={true}
      >
        <form
          className={'flex flex-col flex-1'}
          onSubmit={(e) => {
            e.preventDefault()
            void handleAccept()
          }}
        >
          <DialogHeader noPadding={props.noPadding}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogDescription>{props.description}</DialogDescription>
          </DialogHeader>
          <div
            className={
              props.childrenContainerClassName ||
              clsx(
                defaultChildrenContainerClassName,
                props.noScroll ? 'overflow-y-hidden' : 'overflow-y-auto'
              )
            }
          >
            {props.children}
          </div>
          <div className={'mb-2'}>{props.footerChildren}</div>

          <DialogFooter
            className={
              props.dialogFooterClassName || defaultDialogFooterClassName
            }
            noPadding={props.noPadding}
          >
            {/* CANCEL BUTTON */}
            {!props.hideCancelButton && (
              <ConditionalWrapper
                condition={!props.preventCloseOnCancel}
                wrapper={(children) => (
                  <DialogClose disabled={isLoading} asChild>
                    {children}
                  </DialogClose>
                )}
              >
                <FbButton
                  disabled={isLoading || props.blockCancel || altLoading}
                  size={'lg'}
                  variant="secondary"
                  onClick={() => props?.onCancel?.()}
                  className={'flex-1'}
                  {...props.cancelButtonProps}
                >
                  {props.cancelButtonText ?? 'Cancel'}
                  {props.cancelButtonProps?.children}
                </FbButton>
              </ConditionalWrapper>
            )}

            {/* ALT BUTTON */}
            {props.onAlt && (
              <FbButton
                loading={altLoading}
                size={'lg'}
                disabled={props.blockAlt || isLoading}
                variant="secondary"
                type={'button'}
                onClick={handleAltAccept}
                className={'flex-1'}
                {...props.altButtonProps}
              >
                {props.altButtonText}
                {props.altButtonProps?.children}
              </FbButton>
            )}

            {/*ACCEPT BUTTON*/}
            {props.onAccept && (
              <FbButton
                disabled={props.blockAccept || altLoading}
                loading={isLoading}
                size={'lg'}
                className={'flex-1'}
                type="submit"
                {...props.acceptButtonProps}
              >
                {props.acceptButtonText}
                {props.acceptButtonProps?.children}
              </FbButton>
            )}
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}
