import clsx from 'clsx'
import { IndeterminateCheckbox } from 'components/IndeterminateCheckbox'
import { useMemo } from 'react'
import { Column } from 'stores/ColumnsStore/ColumnsStore'
import { useColumnStoreContext } from 'stores/ColumnsStore/useColumnsStoreContext'

interface ColumnTableProps {
  selectedColumns: Column[]
  setSelectedColumns: React.Dispatch<React.SetStateAction<Column[]>>
}

export const ColumnTable = ({
  selectedColumns,
  setSelectedColumns,
}: ColumnTableProps) => {
  const groups = useColumnStoreContext((state) => state.groups)
  const orderableGroups = useMemo(
    () =>
      groups
        .map((group) => ({
          ...group,
          columns: group.columns.filter(
            (column) => column.isEditable && !column.isHidden
          ),
        }))
        .filter((group) => group.columns.length > 0),
    [groups]
  )

  const toggleGroup = (groupId: string) => {
    const group = orderableGroups.find((group) => group.id === groupId)
    if (!group) return

    const allSelected = group.columns.every((column) =>
      selectedColumns.some((selectedColumn) => selectedColumn.id === column.id)
    )

    if (allSelected) {
      setSelectedColumns(
        selectedColumns.filter((selectedColumn) =>
          group.columns.every((column) => selectedColumn.id !== column.id)
        )
      )
    } else {
      setSelectedColumns((prev) => [
        ...prev,
        ...group.columns.filter(
          (column) =>
            !selectedColumns.some(
              (selectedColumn) => selectedColumn.id === column.id
            )
        ),
      ])
    }
  }

  const getGroupProps = (groupId: string) => {
    const group = orderableGroups.find((group) => group.id === groupId)
    if (!group) return {}

    const allSelected = group.columns.every((column) =>
      selectedColumns.some((selectedColumn) => selectedColumn.id === column.id)
    )
    const someSelected = group.columns.some((column) =>
      selectedColumns.some((selectedColumn) => selectedColumn.id === column.id)
    )

    const indeterminate = !allSelected && someSelected

    return {
      checked: allSelected,
      indeterminate,
      wrapperClassName: indeterminate ? 'alternate' : undefined, // Add alternate class to the wrapper if the checkbox is indeterminate
    }
  }

  const toggleColumn = (columnId: string) => {
    const selectedColumn = selectedColumns.find(
      (selectedColumn) => selectedColumn.id === columnId
    )
    if (selectedColumn) {
      setSelectedColumns(
        selectedColumns.filter(
          (selectedColumn) => selectedColumn.id !== columnId
        )
      )
    } else {
      const column = orderableGroups
        .flatMap((group) => group.columns)
        .find((column) => column.id === columnId)
      if (column) {
        setSelectedColumns([...selectedColumns, column])
      }
    }
  }

  const getcolumnProps = (columnId: string) => {
    const selectedColumn = selectedColumns.find(
      (selectedColumn) => selectedColumn.id === columnId
    )

    return {
      checked: !!selectedColumn,
    }
  }

  const getAllSelectedProps = () => {
    const allSelected = orderableGroups.every((group) =>
      group.columns.every((column) =>
        selectedColumns.some(
          (selectedColumn) => selectedColumn.id === column.id
        )
      )
    )
    const someSelected = orderableGroups.some((group) =>
      group.columns.some((column) =>
        selectedColumns.some(
          (selectedColumn) => selectedColumn.id === column.id
        )
      )
    )
    const indeterminate = !allSelected && someSelected

    return {
      checked: allSelected,
      indeterminate,
    }
  }

  const toggleSelectAll = () => {
    const allSelected = orderableGroups.every((group) =>
      group.columns.every((column) =>
        selectedColumns.some(
          (selectedColumn) => selectedColumn.id === column.id
        )
      )
    )
    if (allSelected) {
      setSelectedColumns(
        selectedColumns.filter((selectedColumn) =>
          orderableGroups.every((group) =>
            group.columns.every((column) => selectedColumn.id !== column.id)
          )
        )
      )
    } else {
      setSelectedColumns([
        ...selectedColumns,
        ...orderableGroups.flatMap((group) =>
          group.columns.filter(
            (column) =>
              !selectedColumns.some(
                (selectedColumn) => selectedColumn.id === column.id
              )
          )
        ),
      ])
    }
  }

  return (
    <div className="border border-[#EAECF0] divide-y divide-[#EAECF0] flex flex-col flex-1">
      <div className="flex items-center px-4 py-3 bg-[#F9FAFB] justify-between">
        <div className="flex gap-2 items-center">
          <IndeterminateCheckbox
            {...getAllSelectedProps()}
            onChange={toggleSelectAll}
          />
          Column Label
        </div>
        <div className="flex gap-2 items-center">
          <div
            className={clsx('px-2 py-1 bg-[#D5DCD9] rounded-xl font-medium', {
              invisible: selectedColumns.length === 0,
            })}
          >
            {selectedColumns.length} Selected
          </div>
          <div className="text-[#667085]">
            {orderableGroups.reduce(
              (acc, group) => acc + group.columns.length,
              0
            )}{' '}
            Total
          </div>
        </div>
      </div>
      <div className="flex-1 overflow-y-auto">
        <div className="max-h-0">
          {orderableGroups.map((group) => (
            <div key={group.title}>
              <div
                className="px-4 pb-3 pt-2 gap-2 flex items-center font-semibold"
                onClick={(event) => {
                  toggleGroup(group.id)
                  event.stopPropagation()
                  event.preventDefault()
                }}
              >
                <IndeterminateCheckbox
                  {...getGroupProps(group.id)}
                  onChange={(event) => {
                    toggleGroup(group.id)
                    event.stopPropagation()
                    event.preventDefault()
                  }}
                />
                {group.title}
              </div>
              {group.columns.map((column) => (
                <div
                  className="px-4 pb-4 gap-2 flex items-center"
                  key={column.title}
                  onClick={(event) => {
                    toggleColumn(column.id)
                    event.stopPropagation()
                    event.preventDefault()
                  }}
                >
                  <IndeterminateCheckbox
                    {...getcolumnProps(column.id)}
                    onChange={(event) => {
                      toggleColumn(column.id)
                      event.stopPropagation()
                      event.preventDefault()
                    }}
                  />
                  {column.title}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
