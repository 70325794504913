import { SectionItem } from 'components/DataExpansionComponents/Common/CommonPage/styles'
import styled from 'styled-components/macro'

export const CardTitle = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 38px;
  /* identical to box height, or 173% */

  /* Gray/900 */

  color: #101828;
`

export const CardSubtitles = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  width: 300px;
`

export const CardSubtitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const RowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const MapCard = styled.div``

export const OpeningHoursItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  padding: 8px 0;
  border-bottom: 1px solid #eaecf0;

  &:nth-child(1) {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #101828;
  }

  &:nth-child(2) {
    font-weight: 400;
    font-size: 14px;
    color: #101828;
  }
`

export const AgesWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 20px;
`

export const AgesSubtitles = styled.div`
  width: 100%;
`

export const AgesChartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
  width: 130px;
`

export const OpenHoursGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: fit-content;
`

export const Map = styled(SectionItem)`
  @media (min-width: 1024px) {
    grid-column: span 2;

    .map-container {
      width: 100%;
      height: 400px;
    }
  }

  @media (min-width: 1280px) {
    grid-column: span 2; // Maintain 2-column span even in 3-column layout
  }
`

export const RestaurantInfo = styled(SectionItem)`
  @media (min-width: 1024px) {
    grid-column: span 1;
  }
`

export const OpeningHoursTitle = styled.h1`
  font-weight: 600;
  font-size: 18px;
  line-height: 38px;
  /* identical to box height, or 211% */

  /* Gray/900 */

  color: #101828;
`
