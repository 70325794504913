import { Modal } from 'components/UI/Modal/Modal'
import { ColumnTable } from './ColumnTable'
import { OrderableListNew } from 'components/OrderableListNew/OrderableListNew'
import { ColumnDragAndDropItem } from './ColumnDragAndDropItem'
import React, { useEffect, useState } from 'react'
import { MdOutlineClearAll } from 'react-icons/md'
import TableButton from 'components/Buttons/TableButtons/TableButton'
import { HiOutlineViewColumns } from 'react-icons/hi2'
import _ from 'lodash'
import { ColumnPresetSelector } from './ColumnPresets'
import {
  Column,
  getAllColumns,
  getDefaultSelectedColumns,
  getHiddenColumns,
  getReadOnlyColumns,
} from '../../../stores/ColumnsStore/ColumnsStore'
import { useColumnStoreContext } from '../../../stores/ColumnsStore/useColumnsStoreContext'
import {
  DataTableColumnSort,
  dataTableSortingStoreRepo,
} from 'components/DataTable/DataTableSorting/DataTableSortingStore'

function prepareSelectedColumnsWithOrder(
  allColumns: Column[],
  orderedColumns: Column[],
  readOnlyColumns: Column[],
  hiddenColumns: Column[]
) {
  // Keep the order from readOnlyColumns and hiddenColumns, just adding the orderedColumns
  const selectedColumns = []
  for (const originalColumn of allColumns) {
    if (readOnlyColumns.some((column) => column.id === originalColumn.id)) {
      selectedColumns.push(originalColumn)
    } else if (
      hiddenColumns.some((column) => column.id === originalColumn.id)
    ) {
      selectedColumns.push(originalColumn)
    } else if (
      orderedColumns.some((column) => column.id === originalColumn.id)
    ) {
      selectedColumns.push(originalColumn)
    }
  }

  const selectedOrderedColumns: Column[] = []
  let sortedIndex = 0

  // Iterate through selectedColumns and insert elements from orderedColumns in the correct order
  for (const col of selectedColumns) {
    if (orderedColumns.some((orderedCol) => _.isEqual(orderedCol, col))) {
      // Find the correct element from the sorted order based on the orderedColumns
      selectedOrderedColumns.push(orderedColumns[sortedIndex])
      sortedIndex++
    } else {
      // Keep the columns that aren't part of orderedColumns in their original place
      selectedOrderedColumns.push(col)
    }
  }

  return selectedOrderedColumns
}

export const ColumnModal = () => {
  const [open, setOpen] = useState(false)

  const defaultSelectedColumns = getDefaultSelectedColumns()
  const allColumns = getAllColumns()
  const readOnlyColumns = getReadOnlyColumns()
  const hiddenColumns = getHiddenColumns()

  const [selectedColumns, setSelectedColumns] = useState<Column[]>([])
  const [orderedColumns, setOrderedColumns] = useState<Column[]>([])
  const setSelectedColumnsStore = useColumnStoreContext(
    (state) => state.setSelectedColumns
  )
  const tableKey = useColumnStoreContext((state) => state.tableKey)
  const { sorting, setSorting } = dataTableSortingStoreRepo.getStore(tableKey)()
  const [internalSorting, setInternalSorting] =
    useState<DataTableColumnSort[]>(sorting)

  // Load initial columns
  useEffect(() => {
    if (
      defaultSelectedColumns &&
      !selectedColumns.length &&
      !orderedColumns.length
    ) {
      const filteredColumns = defaultSelectedColumns.filter(
        (column) => column.isEditable
      )
      setOrderedColumns(filteredColumns)
      setSelectedColumns(filteredColumns)
    }
  }, [])

  useEffect(() => {
    // We want to keep the order and just add/remove columns
    setOrderedColumns((columns) => {
      const newColumns = selectedColumns.filter(
        (column) => !columns.some((c) => c.id === column.id)
      )
      const existingFilteredColumns = columns.filter((column) =>
        selectedColumns.some((c) => c.id === column.id)
      )
      return [...existingFilteredColumns, ...newColumns]
    })
  }, [selectedColumns])

  const updateOrderedColumns = (columnsIds: string[]) => {
    setOrderedColumns((columns) => {
      return columnsIds
        .map((id) => columns.find((c) => c.id === id))
        .filter((c) => c) as Column[]
    })
  }

  const handleApply = () => {
    const selectedOrderedColumns = prepareSelectedColumnsWithOrder(
      allColumns,
      orderedColumns,
      readOnlyColumns,
      hiddenColumns
    )
    setSelectedColumnsStore([...selectedOrderedColumns])
    setSorting(internalSorting)
    setOpen(false)
  }

  const clearAll = () => {
    setSelectedColumns([])
  }

  return (
    <Modal
      open={open}
      onOpenChange={(open) => !open && setOpen(false)}
      title={'Table Columns'}
      onAccept={handleApply}
      acceptButtonText={'Apply'}
      cancelButtonText="Cancel"
      size="lg"
      trigger={
        <TableButton
          text="Columns"
          icon={HiOutlineViewColumns}
          onClick={() => setOpen(true)}
        />
      }
      noScroll
    >
      <ColumnPresetSelector
        currentCols={orderedColumns}
        setCurrentCols={(cols) => {
          setOrderedColumns(cols)
          setSelectedColumns(cols)
        }}
      />
      <hr className="mt-[3px]" />
      {/* 2 columns with 1 divider */}
      <div className="grid grid-cols-2 divide-x divide-gray-300 min-h-[50vh]">
        <div className="py-[28px] h-full flex flex-col">
          <p className="mb-2">
            <span className="bg-gray-300 px-2 py-1 rounded-md mr-2">1</span>
            <strong>All Columns</strong>
          </p>
          <p>Select columns to display</p>
          <ColumnTable
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
          />
        </div>
        <div className="p-[28px] h-full flex flex-col">
          <p className="mb-2">
            <span className="bg-gray-300 px-2 py-1 rounded-md mr-2">2</span>
            <strong>Selected Columns</strong>
          </p>
          <p>Adjust the display order of selected columns</p>
          {readOnlyColumns.map((column) => (
            <ColumnDragAndDropItem
              id={column.id}
              key={column.id}
              label={column.title}
              readOnly
              setSorting={setInternalSorting}
              sorting={internalSorting}
            />
          ))}
          <OrderableListNew
            onOrderChangeKey={updateOrderedColumns}
            className="flex-1 overflow-y-auto pl-[14px] ml-[-14px]"
          >
            {orderedColumns.map((column) => (
              <ColumnDragAndDropItem
                id={column.id}
                key={column.id}
                label={column.title}
                onRemove={() =>
                  setSelectedColumns((columns) =>
                    columns.filter((c) => c.id !== column.id)
                  )
                }
                setSorting={setInternalSorting}
                sorting={internalSorting}
              />
            ))}
          </OrderableListNew>
          {orderedColumns.length > 0 && (
            <div className="flex items-center mt-2 justify-end">
              <div
                onClick={clearAll}
                className="cursor-pointer flex items-center text-[#005A31]"
              >
                Clear All{' '}
                <MdOutlineClearAll className="ml-1" size={15} color="#005A31" />
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export function getColumnSelectorRecipientId(tableKey: string) {
  return `${tableKey}-columns-modal`
}

export function ColumnSelectorRecipient(props: { tableKey: string }) {
  return <div id={getColumnSelectorRecipientId(props.tableKey)} />
}
