import { NavMenu } from '../NavMenu'
import * as S from './Footer.styles'
import footerLogo from 'assets/Name - White Transparent.svg'
import styled from 'styled-components'

const LogoIcon = styled.img`
  width: 100px;
`
export default function Footer() {
  const supportEmail = 'support@firstbite.io'
  const handleEmailClick = () => {
    window.open(`mailto:${supportEmail}`)
  }

  return (
    <S.Wrapper>
      <S.Content>
        <S.TopBarTitle>
          <LogoIcon
            src={footerLogo}
            alt="logo"
            style={{
              marginRight: '10px',
            }}
          />
        </S.TopBarTitle>
        <S.FooterMenu>
          <NavMenu
            items={[
              {
                label: 'Help Center',
                to: 'https://help.firstbite.io/',
              },
              {
                label: 'Privacy Policy',
                to: '/privacy-policy',
              },
              {
                label: 'Terms of Use',
                to: '/terms-of-use',
              },
              {
                label: 'Contact Us',
                to: '#',
                onClick: handleEmailClick,
              },
            ]}
            options={{
              activeColor: '#04331D',
            }}
          />
        </S.FooterMenu>
      </S.Content>
    </S.Wrapper>
  )
}
