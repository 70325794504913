import { createColumnHelper } from '@tanstack/react-table'
import { getCommonContactCompanyColumns } from 'components/DataExpansionComponents/Common/CommonTable/CommonColumns'
import { LinkCell } from 'components/Tables/CommonTableCells/LinkCell'
import { usePreferences } from 'context/preferences/PreferencesContext'
import { useMemo } from 'react'
import { featureFlagService } from 'utils/featureFlagService'
import { integerFormatterOrFallback } from 'utils/formatting'
import { ContactCompanyType } from '../../../../models/contact_companies'
import { UniversityChainProxy } from '../../../../models/universities'
import { DataTableColumnSort } from '../../../DataTable/DataTableSorting/DataTableSortingStore'
import { FbLink } from '../../../FbUI/FbLink'
import { Anchor } from '../../../UI/Anchor'
import { UNIVERSITY_COLUMNS } from '../universityColumnsInfo'
import { createTableColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'

export const useUniversitiesTableCols = ({
  sorting,
}: {
  sorting?: DataTableColumnSort[]
}) => {
  const { preferences } = usePreferences()
  const tooltips: Record<string, unknown> = preferences?.tooltips || {}
  const columnHelper = createColumnHelper<UniversityChainProxy>()
  const universityColumnHelper = createTableColumnHelper({
    columns: UNIVERSITY_COLUMNS,
    tooltips,
  })
  const columns = useMemo(() => {
    let cols = [
      columnHelper.accessor('name', {
        ...universityColumnHelper.retrieveColumn('institution_name', {
          meta: {
            isEditable: false,
            isFirstColumn: true,
          },
        }),
        header: () => null,
        size: 250,
        cell: (info) => {
          const name = info.getValue()
          let link = ''
          if (info.row.original?.university) {
            link = `/university/${preferences?.company_type_prefix_map[ContactCompanyType.University]}${info.row.original?.university?.institution_id}`
          } else {
            link = `/company/${preferences?.company_type_prefix_map[ContactCompanyType.UserGenerated]}${info.row.original?.id}`
          }
          return (
            <FbLink to={link} target="_blank">
              {name}
            </FbLink>
          )
        },
      }),
      columnHelper.accessor('university.address', {
        ...universityColumnHelper.retrieveColumn('address'),
        size: 250,
      }),
      columnHelper.accessor('university.city', {
        ...universityColumnHelper.retrieveColumn('city'),
        size: 150,
      }),
      columnHelper.accessor('university.state_abbr', {
        ...universityColumnHelper.retrieveColumn('state_abbr'),
        size: 100,
      }),
      columnHelper.accessor('university.zipcode', {
        ...universityColumnHelper.retrieveColumn('zipcode'),
        size: 100,
      }),
      columnHelper.accessor('university.domain', {
        ...universityColumnHelper.retrieveColumn('domain'),
        size: 200,
        cell: (info) => {
          let domain = info.getValue()
          if (domain.startsWith('http://') || domain.startsWith('https://')) {
            domain = domain.replace('http://', '').replace('https://', '')
          }
          if (domain.endsWith('/')) {
            domain = domain.slice(0, -1)
          }
          return domain ? (
            <Anchor href={'//' + domain} target="_blank">
              {domain}
            </Anchor>
          ) : (
            '-'
          )
        },
      }),
      columnHelper.accessor('university.dormitory_capacity', {
        ...universityColumnHelper.retrieveColumn('dormitory_capacity'),
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('university.meal_plan_provided', {
        ...universityColumnHelper.retrieveColumn('meal_plan_provided'),
        size: 150,
        cell: (info) => {
          const mealPlan = info.getValue()
          return mealPlan ? 'Yes' : 'No'
        },
      }),
      columnHelper.accessor('university.total_enrollment', {
        ...universityColumnHelper.retrieveColumn('total_enrollment'),
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('university.total_men_enrollment', {
        ...universityColumnHelper.retrieveColumn('total_men_enrollment'),
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('university.total_women_enrollment', {
        ...universityColumnHelper.retrieveColumn('total_women_enrollment'),
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('university.food_management_company', {
        ...universityColumnHelper.retrieveColumn('food_management_company'),
        size: 200,
      }),
      columnHelper.accessor('university.food_management_company_source', {
        ...universityColumnHelper.retrieveColumn(
          'food_management_company_source'
        ),
        size: 200,
        cell: (info) => {
          const src = info.getValue() ?? ''
          return src.charAt(0).toUpperCase() + src.slice(1)
        },
      }),
      columnHelper.accessor('university.food_management_company_parent', {
        ...universityColumnHelper.retrieveColumn(
          'food_management_company_parent'
        ),
        size: 200,
      }),
      columnHelper.accessor('university.estimated_meals_per_day', {
        ...universityColumnHelper.retrieveColumn('estimated_meals_per_day'),
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor(
        'university.carnegie_classification_2021_size_setting_size',
        {
          ...universityColumnHelper.retrieveColumn(
            'carnegie_classification_2021_size_setting_size'
          ),
          size: 150,
          cell: (info) => {
            return info.getValue() || '-'
          },
        }
      ),
      columnHelper.accessor(
        'university.carnegie_classification_2021_size_setting_year',
        {
          ...universityColumnHelper.retrieveColumn(
            'carnegie_classification_2021_size_setting_year'
          ),
          size: 150,
          cell: (info) => {
            return info.getValue() || '-'
          },
        }
      ),
      columnHelper.accessor(
        'university.carnegie_classification_2021_size_setting_student_composition',
        {
          ...universityColumnHelper.retrieveColumn(
            'carnegie_classification_2021_size_setting_student_composition'
          ),
          size: 300,
          cell: (info) => {
            return info.getValue() || '-'
          },
        }
      ),

      ...getCommonContactCompanyColumns(columnHelper, sorting),

      columnHelper.accessor('university.google_place_url', {
        ...universityColumnHelper.retrieveColumn('google_place_url'),
        size: 150,
        cell: (info) => (info.getValue() ? <LinkCell info={info} /> : '-'),
      }),
    ]

    const featureFlag = featureFlagService()
    if (!featureFlag.enableCampaigns) {
      cols = cols.filter((col) => !col.id?.includes('campaigns'))
    }
    return cols
  }, [columnHelper, sorting])
  return columns
}
