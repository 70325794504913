import { ColumnPreset } from '../models/column_preset'
import { Notification } from '../models/notifications'
import { clientInstance } from '../utils/http-client'
import { baseAPICall } from './api'
import { useQuery } from '@tanstack/react-query'
import { FilterIdentifier } from '../models/saved_view'

async function getColumnPresetOptions(identifier: string) {
  return baseAPICall<
    {
      label: string
      value: number
      original: ColumnPreset
    }[]
  >(() =>
    clientInstance.get('column-presets/', {
      params: {
        identifier,
      },
      transformResponse: (data) => {
        const columnPresets = JSON.parse(data)
        return columnPresets.map((columnPreset: ColumnPreset) => ({
          label: columnPreset.name,
          value: columnPreset.id,
          original: columnPreset,
        }))
      },
    })
  )
}

async function getAllColumnPresets() {
  return baseAPICall<ColumnPreset[]>(() =>
    clientInstance.get('column-presets/')
  )
}

export const useGetAllColumnPresets = () => {
  return useQuery({
    queryKey: ['column-presets/'],
    queryFn: () => getAllColumnPresets(),
  })
}

export const useGetColumnPresetOptions = (identifier?: FilterIdentifier) => {
  return useQuery({
    queryKey: ['column_presets', identifier],
    queryFn: () => getColumnPresetOptions(identifier ?? ''),
    enabled: !!identifier,
  })
}

export interface CreateColumnPresetBody {
  name: string
  identifier: FilterIdentifier
  shared: boolean
  payload: Record<any, any>
}

export async function createColumnPreset(payload: CreateColumnPresetBody) {
  return baseAPICall<Notification[]>(
    () =>
      clientInstance.post('column-presets/', {
        ...payload,
      }),
    { disableNotifyError: true }
  )
}

export async function deleteColumnPreset(presetId: number) {
  return baseAPICall(
    () => clientInstance.delete(`column-presets/${presetId}`),
    {
      disableNotifyError: true,
    }
  )
}
