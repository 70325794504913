import { DealsPipeline } from 'models/deals_pipeline'
import { DataTypeColumn } from '../Common/commonColumnHelpers'

export const DEALS_PIPELINE_COLUMNS = {
  company_name: {
    header: 'Company',
    group: 'Opportunity Attributes',
    tooltipName: 'dealspipeline_company_name',
  },
  category: {
    header: 'Category',
    group: 'Opportunity Attributes',
    tooltipName: 'dealspipeline_category',
  },
  type: {
    header: 'Type',
    group: 'Opportunity Attributes',
    tooltipName: 'dealspipeline_type',
  },
  domain: {
    header: 'Domain',
    group: 'Opportunity Attributes',
    tooltipName: 'dealspipeline_domain',
  },
  count: {
    header: 'Deals',
    group: 'Opportunity Attributes',
    tooltipName: 'dealspipeline_count',
  },
  closed: {
    header: 'Deals Closed',
    group: 'Opportunity Attributes',
    tooltipName: 'dealspipeline_closed',
  },
  sales_stages: {
    header: 'Sales Stages',
    group: 'Opportunity Attributes',
    tooltipName: 'dealspipeline_sales_stages',
  },
  taro: {
    header: 'Annual Revenue Opportunity',
    group: 'Predicted Values',
    tooltipName: 'dealspipeline_taro',
  },
  taro_closed: {
    header: 'Annual Revenue Opportunity (Closed)',
    group: 'Predicted Values',
    tooltipName: 'dealspipeline_taro_closed',
  },
  last_modified: {
    header: 'Last Update',
    group: 'Opportunity Attributes',
    tooltipName: 'dealspipeline_last_modified',
  },
  days_since_last_modified: {
    header: 'Days Since Last Update',
    group: 'Opportunity Attributes',
    tooltipName: 'dealspipeline_days_since_last_modified',
  },
  account_owner: {
    header: 'Account Owner',
    group: 'Account Details',
    tooltipName: 'dealspipeline_account_owner',
  },
  tag: {
    header: 'Tags',
    group: 'Account Details',
    tooltipName: 'dealspipeline_tag',
  },
} satisfies Partial<Record<keyof DealsPipeline, DataTypeColumn>>
