import React from 'react'
import { Tabs } from '../../../components/FbUI/Tabs'
import { FBPage } from 'components/FbUI/Page/FBPage'
import useDocumentTitle from 'components/useDocumentTitle'
import { ActivityTableV2 } from 'components/Tables/ActivityTableV2/ActivityTableV2'
import { useNavigate, useParams } from 'react-router-dom'
import { useNavbarStore } from '../../../components/Layout/navbar-store'
import {
  FBPageHeader,
  FBPageInnerTabsContainer,
  FBPageTableContainer,
  FBPageTitle,
  FBPageTitleRow,
} from '../../../components/FbUI/Page/styles'

export function ActivityPageV2() {
  const navigate = useNavigate()
  const params = useParams()
  const selectedTab = params?.type ?? 'all'
  const store = useNavbarStore()

  const [totalActivity, setTotalActivity] = React.useState<number | null>(null)
  const [totalMyActivity, setTotalMyActivity] = React.useState<number | null>(
    null
  )

  useDocumentTitle(
    `${
      selectedTab === 'my-activity' ? 'My' : 'Team'
    } Activity - Activity - First Bite`
  )

  return (
    <FBPage>
      <FBPageHeader>
        <FBPageTitleRow>
          <FBPageTitle>Team Activity</FBPageTitle>
        </FBPageTitleRow>
      </FBPageHeader>

      <FBPageInnerTabsContainer>
        <Tabs
          tabs={[
            {
              key: 'all',
              title: (
                <div>
                  <b>Team activity</b>{' '}
                  {totalActivity ? `(${totalActivity})` : ''}
                </div>
              ),
            },
            {
              key: 'my',
              title: (
                <div>
                  <b>My activity</b>{' '}
                  {totalMyActivity ? `(${totalMyActivity})` : ''}
                </div>
              ),
            },
          ]}
          selectedTab={selectedTab}
          setSelectedTab={(value) => {
            const url = `/activity/${value}/`
            navigate(url)
            store.setLastActivityVisited(url)
          }}
          tabStyle={{ width: '180px' }}
        />
      </FBPageInnerTabsContainer>

      <FBPageTableContainer
        style={{
          height: 'calc(var(--base-page-table-height) - 95px)',
        }}
      >
        {selectedTab === 'all' && (
          <ActivityTableV2
            tableKey={'all-activity-table-v2'}
            setTotalRowsCount={setTotalActivity}
          />
        )}
        {selectedTab === 'my' && (
          <ActivityTableV2
            tableKey={'my-activity-table-v2'}
            setTotalRowsCount={setTotalMyActivity}
            myActivityOnly
          />
        )}
      </FBPageTableContainer>
    </FBPage>
  )
}
