import apiService from 'services/api'
import ExportModal, { ExportModalProps } from './ExportModal'
import { downloadFile } from 'utils/csvDownloader'
import { HOSPITAL_COLUMNS } from 'components/DataExpansionComponents/Hospitals/hospitalColumnsInfo'
import { createExportColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { getCommonChainProxyExportColumns } from 'components/DataExpansionComponents/Common/commonExportColumns'

export default function HospitalExportModal(props: ExportModalProps) {
  const api = apiService()

  const exportHospitals = async ({
    filter,
    selectedColumns,
    exportType,
    ids,
    excludeIds,
    controller,
  }: {
    filter: Record<string, unknown>
    selectedColumns: string[]
    exportType: 'csv' | 'xlsx'
    controller?: AbortController
    ids?: number[] | undefined
    excludeIds?: number[] | undefined
  }) => {
    const res = await api.exportHospitals(
      filter,
      selectedColumns,
      exportType,
      ids,
      excludeIds,
      controller?.signal
    )
    downloadFile(
      res,
      `First Bite Export: Hospitals - ${new Date().toLocaleDateString()}.${exportType}`
    )
  }

  const hospitalColumnHelper = createExportColumnHelper({
    columns: HOSPITAL_COLUMNS,
  })

  const columns = [
    {
      title: 'Opportunity Attributes',
      elements: [
        hospitalColumnHelper.retrieveColumn('hospital_name'),
        hospitalColumnHelper.retrieveColumn('street_addr'),
        hospitalColumnHelper.retrieveColumn('city'),
        hospitalColumnHelper.retrieveColumn('state'),
        hospitalColumnHelper.retrieveColumn('zip_code'),
        hospitalColumnHelper.retrieveColumn('domain'),
        hospitalColumnHelper.retrieveColumn('website_url'),
      ],
    },
    {
      title: 'Hospital Insights',
      elements: [
        hospitalColumnHelper.retrieveColumn('total_hospital_beds'),
        hospitalColumnHelper.retrieveColumn('total_hospital_days'),
        hospitalColumnHelper.retrieveColumn('cafeteria'),
        hospitalColumnHelper.retrieveColumn('dietary'),
        hospitalColumnHelper.retrieveColumn('dietary_under_contract'),
        hospitalColumnHelper.retrieveColumn('meals_per_day'),
      ],
    },
    {
      title: 'Predicted Values and Deals',
      elements: getCommonChainProxyExportColumns(),
    },
  ]

  return (
    <ExportModal
      title="Select Columns for Hospitals Download"
      subTitle="Download up to 10,000 hospitals at a time, with any of the following columns. Any filters already applied will remain active."
      submitText="Export Hospitals"
      columns={columns}
      exportAPIAction={exportHospitals}
      {...props}
    />
  )
}
