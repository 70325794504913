import styled from 'styled-components'

interface SectionProps {
  columns?: {
    default?: number
    lg?: number
    xl?: number
    xxl?: number
  }
}

export const Section = styled.div<SectionProps>`
  display: grid;
  gap: 28px;
  padding: 0 var(--page-container-horizontal-padding);

  // Mobile and Medium screens: 1 column or custom default
  grid-template-columns: ${({ columns }) =>
    `repeat(${columns?.default || 1}, 1fr)`};

  // Large screens
  @media (min-width: 1024px) {
    gap: 0;
    padding: 0;
    grid-template-columns: ${({ columns }) =>
      `repeat(${columns?.lg || 2}, 1fr)`};
  }

  // Extra large screens
  @media (min-width: 1280px) {
    grid-template-columns: ${({ columns }) =>
      `repeat(${columns?.xl || 3}, 1fr)`};
  }

  // 2XL screens
  @media (min-width: 1536px) {
    grid-template-columns: ${({ columns }) =>
      `repeat(${columns?.xxl || columns?.xl || 3}, 1fr)`};
  }
`

export const SectionItem = styled.div<{
  span?: number
  columns?: SectionProps['columns']
}>`
  width: 100%;
  padding: 28px var(--page-container-horizontal-padding);
  position: relative;

  // Mobile and Medium screens: horizontal dividers
  @media (max-width: 1023px) {
    &:not(:last-child) {
      border-bottom: 1px solid #eaecf0;
    }
  }

  // Large screens and up: vertical dividers
  @media (min-width: 1024px) {
    grid-column: ${(props) => (props.span ? `span ${props.span}` : 'auto')};

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 28px;
      bottom: 28px;
      width: 1px;
      background: #eaecf0;
    }

    &:first-child::before,
    &:nth-child(${({ columns }) => columns?.lg || 2}n+1)::before {
      display: none;
    }
  }

  @media (min-width: 1280px) {
    &:nth-child(${({ columns }) => columns?.lg || 2}n+1)::before {
      display: block;
    }
    &:nth-child(${({ columns }) => columns?.xl || 3}n+1)::before {
      display: none;
    }
  }

  @media (min-width: 1536px) {
    &:nth-child(${({ columns }) => columns?.xl || 3}n+1)::before {
      display: block;
    }
    &:nth-child(
        ${({ columns }) => columns?.xxl || columns?.xl || 3}n+1
      )::before {
      display: none;
    }
  }
`
