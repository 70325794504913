import styled from 'styled-components/macro'

export const FBPageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
`

export const FBPageTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
`

export const FBPageTitle = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #101828;
`

export const FBPageTableContainer = styled.div<{
  hide?: boolean
}>`
  display: ${(props) => (props.hide ? 'none' : 'flex')};
  flex-shrink: 1;
  width: 100%;
`

export const FBPageInnerTabsContainer = styled.div`
  justify-content: stretch;
  align-items: flex-end;
  margin-bottom: 20px;
  gap: 100px;
`
