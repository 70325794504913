import { BarChart } from 'components/Charts/BarChart'
import { CardSubtitles, CardSubtitleWrapper } from 'pages/app/door/door.styles'
import * as S from './RaceCard.styles'
import { AddressInfo } from 'models/restaurant'

export function EducationCard({ addressInfo }: { addressInfo?: AddressInfo }) {
  if (!addressInfo) {
    return null
  }
  const education = [
    {
      color: '#DDD855',
      name: 'High School and Below',
      percentage:
        addressInfo?.educationlevel_highschoolgraduate_pop_pct +
        addressInfo?.educationlevel_9thto12thgrade_nodiploma_pop_pct +
        addressInfo?.educationlevel_lessthan9thgrade_pop_pct,
    },

    {
      color: '#218100',
      name: 'Some College / Associate’s',
      percentage:
        addressInfo?.educationlevel_somecollege_nodegree_pop_pct +
        addressInfo?.educationlevel_associatesdegree_pop_pct,
    },

    {
      color: '#70DD55',
      name: "Bachelor's Degree",
      percentage: addressInfo?.educationlevel_bachelorsdegree_pop_pct,
    },

    {
      color: '#E3BE75',
      name: 'Graduate or Professional Degree',
      percentage:
        addressInfo?.educationlevel_graduateorprofessionaldegree_pop_pct,
    },
  ]

  return (
    <div className="flex-1 flex flex-col">
      <S.CardTitle>Education</S.CardTitle>
      <BarChart data={education} />
      <CardSubtitles className="flex-1">
        {education.map((v) => {
          return (
            <CardSubtitleWrapper key={v.name}>
              <S.ColoredDot color={v.color} />
              <S.CardSubtitle>{v.name}</S.CardSubtitle>
              <S.CardSubtitlePercent>
                - {Math.round(v.percentage * 100)}%
              </S.CardSubtitlePercent>
            </CardSubtitleWrapper>
          )
        })}
      </CardSubtitles>
    </div>
  )
}
