import * as SelectPrimitive from '@radix-ui/react-select'
import { TextInputProps } from 'components/FormUtils/TextInput/text-input'
import { cn } from 'components/UI/cn'
import { ChevronDown, X } from 'lucide-react'
import * as React from 'react'
import { FieldValues, Path, useController } from 'react-hook-form'
import { Input } from '../Input/input'
import { Label } from '../Label/label'

interface NumericInputWithUnitSelectorProps<T extends FieldValues>
  extends Omit<TextInputProps<T>, 'type' | 'suffix'> {
  unit: string
  unit_name?: string
  unitOptions: { label: string; value: string }[]
  min?: number
  max?: number
}

export function NumericInputWithUnitSelector<T extends FieldValues>(
  props: NumericInputWithUnitSelectorProps<T>
) {
  const {
    field: { ref, onChange, value },
    fieldState: { error },
  } = useController({ name: props.name, control: props.control })

  const unitField = props.unit_name
    ? useController({
        name: props.unit_name as Path<T>,
        control: props.control,
      })
    : null

  const handleUnitChange = (newUnit: string) => {
    if (unitField) {
      unitField.field.onChange(newUnit)
    }
  }

  const [isFocused, setIsFocused] = React.useState(false)
  const hasValue = value !== ''

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsFocused(true)
    if (isNaN(e.target.valueAsNumber)) {
      onChange('')
    } else {
      onChange(e.target.valueAsNumber)
    }
  }
  return (
    <div
      className={cn(
        'relative w-full',
        props.noMargin ? 'mt-0' : 'mt-[10px]',
        '!mb-0',
        props.className
      )}
    >
      <div>
        <Label
          htmlFor={props.name}
          className={cn(
            'absolute transition-all duration-200 z-10',
            isFocused || hasValue
              ? '-top-2 text-xs px-1 bg-gradient-to-t from-white from-50% to-transparent to-50%'
              : 'top-1/2 -translate-y-1/2 pointer-events-none',
            props.prefix
              ? isFocused || hasValue
                ? 'left-3'
                : 'left-10'
              : 'left-3'
          )}
        >
          {props.label}
          {props.optional && (
            <span className="italic text-sm font-normal"> - optional</span>
          )}
        </Label>
        <div className="relative border border-input rounded-md">
          {props.onClear && !!value && (
            <button
              className="absolute right-20 top-1/2 -translate-y-1/2 text-gray-600 hover:text-gray-800 focus:outline-none z-10"
              onClick={props.onClear}
            >
              <X size={18} />
            </button>
          )}
          <div className="flex">
            {props.prefix && (
              <span className="inline-flex items-center pl-3 bg-white text-sm text-gray-500 rounded-l-md">
                {props.prefix}
              </span>
            )}
            <Input
              id={props.name}
              onChange={handleOnChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(!!value)}
              type="number"
              value={value ?? ''}
              ref={ref}
              min={props.min}
              max={props.max}
              inputMode="numeric"
              pattern="[0-9]*"
              onKeyDown={(e) => {
                if (
                  !/^\d$/.test(e.key) &&
                  ![
                    'Backspace',
                    'Delete',
                    'ArrowLeft',
                    'ArrowRight',
                    'Tab',
                  ].includes(e.key)
                ) {
                  e.preventDefault()
                }
              }}
              placeholder={
                isFocused || hasValue ? props.placeholder : undefined
              }
              maxLength={props.maxLength}
              style={props.style}
              disabled={props.disabled}
              className={cn(
                error?.message && 'border-red-500',
                'w-full rounded-md',
                props.prefix && 'pl-0',
                'rounded-r-none',
                props.onClear && 'pr-28'
              )}
            />
            <SelectPrimitive.Root
              value={props.unit}
              onValueChange={handleUnitChange}
            >
              <SelectPrimitive.Trigger className="inline-flex items-center px-3 text-sm bg-white border-l border-gray-300 rounded-r-md">
                <SelectPrimitive.Value />
                <SelectPrimitive.Icon>
                  <ChevronDown className="w-4 h-4 ml-2 opacity-50" />
                </SelectPrimitive.Icon>
              </SelectPrimitive.Trigger>
              <SelectPrimitive.Portal>
                <SelectPrimitive.Content
                  className="relative bg-white z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
                  position="popper"
                  sideOffset={5}
                >
                  <SelectPrimitive.Viewport className="p-1">
                    {props.unitOptions.map((option) => (
                      <SelectPrimitive.Item
                        key={option.value}
                        value={option.value}
                        className="relative flex w-full cursor-pointer select-none items-center rounded-sm py-1.5 pl-2 pr-8 text-sm outline-none
                        hover:bg-gray-100
                        focus:bg-accent focus:text-accent-foreground
                        data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
                      >
                        <SelectPrimitive.ItemText>
                          {option.label}
                        </SelectPrimitive.ItemText>
                      </SelectPrimitive.Item>
                    ))}
                  </SelectPrimitive.Viewport>
                </SelectPrimitive.Content>
              </SelectPrimitive.Portal>
            </SelectPrimitive.Root>
          </div>
        </div>
      </div>
      {props.sublabel && (
        <p className="mt-1 text-gray-600 text-sm font-normal leading-5">
          {props.sublabel}
        </p>
      )}
      {props.helperText && (
        <p className="mt-1 text-gray-600 text-sm font-normal leading-5">
          {props.helperText}
        </p>
      )}
      {error?.message && (
        <p className="mt-1 text-red-500 text-sm">{error.message}</p>
      )}
    </div>
  )
}
