import { create, StoreApi, UseBoundStore } from 'zustand'
import { persist } from 'zustand/middleware'

export interface DataTableColumnSort {
  desc: boolean
  id: string
}

export type DataTableSortingStore = UseBoundStore<
  StoreApi<DataTableSortingState>
>

export interface DataTableSortingState {
  setSorting: (sort: DataTableColumnSort[]) => void
  sorting: DataTableColumnSort[]
  handleSort: (columnID: string) => void
}

export function dataTableSortingStoreFactory(tableKey: string) {
  return create(
    persist<DataTableSortingState>(
      (set, getState) => ({
        sorting: [],
        setSorting: (sort) => {
          set({ sorting: sort })
        },
        handleSort: (columnID: string) => {
          const state = getState()
          const isSorted = state.sorting?.find((s) => s.id === columnID)

          if (isSorted == null) {
            set({ sorting: [{ id: columnID, desc: true }] })
            return
          }

          if (isSorted.desc) {
            set({ sorting: [{ id: columnID, desc: false }] })
            return
          }

          if (!isSorted.desc) {
            set({ sorting: [] })
            return
          }
        },
      }),
      {
        name: tableKey + '_SORTING',
      }
    )
  )
}

class DataTableSortingStoreRepo {
  private repo: Record<string, DataTableSortingStore> = {}

  getStore(tableKey: string): DataTableSortingStore {
    if (!this.repo[tableKey]) {
      this.repo[tableKey] = dataTableSortingStoreFactory(tableKey)
    }
    return this.repo[tableKey]
  }
}

export const dataTableSortingStoreRepo = new DataTableSortingStoreRepo()
