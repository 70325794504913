import { BarChart } from 'components/Charts/BarChart'
import { CardSubtitles, CardSubtitleWrapper } from 'pages/app/door/door.styles'
import * as S from './RaceCard.styles'
import { AddressInfo } from 'models/restaurant'

export function RaceCard({ addressInfo }: { addressInfo?: AddressInfo }) {
  if (!addressInfo) {
    return null
  }

  const races = [
    {
      color: '#FFD582',
      name: 'White',
      percentage: addressInfo?.race_white_pop_pct,
    },
    {
      color: '#528142',
      name: 'Black',
      percentage: addressInfo?.race_blackorafricanamerican_pop_pct,
    },
    {
      color: '#DDD855',
      name: 'Asian',
      percentage: addressInfo?.race_asian_pop_pct,
    },
    {
      color: '#83E1FF',
      name: 'American Indian / Alaskan Native',
      percentage: addressInfo?.race_americanindianandalaskanative_pop_pct,
    },
    {
      color: '#86DD71',
      name: 'Native Hawaiian / Pacific Islander',
      percentage:
        addressInfo?.race_nativehawaiianandotherpacificislander_pop_pct,
    },
    {
      color: '#E87676',
      name: 'Multiracial',
      percentage: addressInfo?.race_twoormoreraces_pop_pct,
    },
    {
      color: '#A675E5',
      name: 'Other',
      percentage: addressInfo?.race_otherrace_pop_pct,
    },
  ]

  return (
    <div className="flex-1 flex flex-col">
      <S.CardTitle>Race</S.CardTitle>
      <BarChart data={races} />
      <CardSubtitles className="flex-1">
        {races.map((v) => {
          if (!v.percentage) return null
          return (
            <CardSubtitleWrapper key={v.name}>
              <S.ColoredDot color={v.color} />
              <S.CardSubtitle>{v.name}</S.CardSubtitle>
              <S.CardSubtitlePercent>
                - {Math.round(v.percentage * 100)}%
              </S.CardSubtitlePercent>
            </CardSubtitleWrapper>
          )
        })}
      </CardSubtitles>
    </div>
  )
}
