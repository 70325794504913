import TableButton from 'components/Buttons/TableButtons/TableButton'
import { FilterChips } from 'components/Filters/components/FilterChips/FilterChips'
import { FilterCriteria, FilterStore } from 'components/Filters/FilterStore'
import { cn } from 'components/UI/cn'
import { Modal } from 'components/UI/Modal/Modal'
import { FilterIdentifier } from 'models/saved_view'
import React, { useEffect, useMemo } from 'react'
import { Form } from 'react-bootstrap'
import { AiOutlineSave } from 'react-icons/ai'
import { CiFilter } from 'react-icons/ci'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import SaveFilterModal from '../saved-filters/SaveFilterModal'
import SelectSavedFilter from '../saved-filters/SelectSavedFilter'

export default function FilterModal({
  children,
  store,
  identifier,
}: {
  store: FilterStore
  identifier: FilterIdentifier
  children?: React.ReactNode
}) {
  const [openFilterSaveModal, setOpenFilterSaveModal] = React.useState(false)
  const [openFilterModal, setOpenFilterModal] = React.useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const {
    applyFilters,
    setPendingCriteria,
    pendingCriteria,
    valueFilters,
    rangeFilters,
    zipCodeFilter,
    search,
    syncPendingFilters,
  } = store()

  const filterCount = useMemo(() => {
    const valueCount = Object.keys(valueFilters).length
    const rangeCount = Object.keys(rangeFilters).length
    const searchCount = Object.keys(search).length
    const zipCount = zipCodeFilter[0] ? 1 : 0

    return valueCount + rangeCount + searchCount + zipCount
  }, [valueFilters, rangeFilters, search, zipCodeFilter])

  useEffect(() => syncPendingFilters(), [openFilterModal])

  const removePageFromUrlSearchParams = () => {
    const searchParamsCopy = new URLSearchParams(searchParams)
    searchParamsCopy.delete('page')
    setSearchParams(searchParamsCopy)
  }

  return (
    <>
      <TableButton
        text="Filter"
        icon={CiFilter}
        activeValue={filterCount > 0 ? filterCount.toString() : null}
        postpendIcon={filterCount > 0 ? AiOutlineSave : undefined}
        onPostpendIconClick={() => setOpenFilterSaveModal(true)}
        onClick={() => setOpenFilterModal(true)}
      />
      <Modal
        open={openFilterModal}
        onOpenChange={(open) => setOpenFilterModal(open)}
        size="lg"
        title={'All filters'}
        onAccept={() => {
          applyFilters()
          removePageFromUrlSearchParams()
          setOpenFilterModal(false)
        }}
        acceptButtonText={'Apply'}
        footerChildren={
          <>
            <FilterModalFooterCriteriaHeader>
              Show results matching
              <Form.Select
                style={{ display: 'inline', width: 'auto', margin: '0 0.5rem' }}
                value={pendingCriteria}
                onChange={(e) =>
                  setPendingCriteria(e.target.value as FilterCriteria)
                }
              >
                <option value={'all'}>All</option>
                <option value={'one'}>One</option>
                <option value={'neither'}>None</option>
              </Form.Select>
              of the following criteria:
            </FilterModalFooterCriteriaHeader>
            <FilterModalFooterCriteria>
              <FilterChips
                identifier={identifier}
                store={store}
                className="ml-0"
              />
            </FilterModalFooterCriteria>
          </>
        }
      >
        <div className="mb-2">
          <SelectSavedFilter filterStore={store} identifier={identifier} />
        </div>
        {children}
      </Modal>
      <SaveFilterModal
        show={openFilterSaveModal}
        setShow={setOpenFilterSaveModal}
        identifier={identifier}
        filterStore={store}
      />
    </>
  )
}

export function QuickFilterGroup({ children }: { children: React.ReactNode }) {
  return (
    <>
      <FilterGroup title="Quick filters" fontStyle="italic">
        {children}
      </FilterGroup>
      <Divider />
    </>
  )
}

export function FilterGroup({
  children,
  fontStyle,
  title,
  className,
}: {
  children: React.ReactNode
  fontStyle?: string
  title: string
  className?: string
}) {
  return (
    <div className={'mt-3 mb-2'}>
      <p className={'text-md font-medium mb-2'} style={{ fontStyle }}>
        {title}
      </p>
      <div className={cn('grid grid-cols-2 lg:grid-cols-3 gap-2', className)}>
        {children}
      </div>
    </div>
  )
}

export const Divider = styled.hr`
  border: 1px solid #e5e5e5;
  margin: 16px 0;
`

export const FilterModalFooterCriteriaHeader = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FilterModalFooterCriteria = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > span {
    margin: 4px;
  }
`
