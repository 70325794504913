import { EmailValidity, EmailValidityLabels } from 'models/contacts'
import { TextPill } from './TextPill'

interface Colors {
  [key: number]: string[]
}

export default function getEmailValidityColors(emailValidity: EmailValidity) {
  const colors: Colors = {
    [EmailValidity.INVALID]: ['#F98080', '#000000'], // Invalid (Red)
    [EmailValidity.VALID]: ['#84E1BC', '#000000'], // Valid (Green)
    [EmailValidity.CATCH_ALL]: ['#FCE96A', '#000000'], // Catch-All (Yellow)
    [EmailValidity.UNKNOWN]: ['#A0AEC0', '#000000'], // Unknown (Gray)
    [EmailValidity.SPAMTRAP]: ['#F6AD55', '#000000'], // Spamtrap (Orange)
    [EmailValidity.ABUSE]: ['#FC8181', '#000000'], // Abuse (Light Red)
    [EmailValidity.DO_NOT_MAIL]: ['#805AD5', '#FFFFFF'], // Do Not Mail (Purple)
  }

  return colors[emailValidity]
}

export function EmailValidityPill({
  emailValidity,
}: {
  emailValidity: EmailValidity
}) {
  const colors = getEmailValidityColors(emailValidity)

  return (
    <TextPill
      style={{
        marginBottom: 2,
      }}
      textColor={colors[1]}
      backgroundColor={colors[0]}
    >
      {EmailValidityLabels[emailValidity]}
    </TextPill>
  )
}
