import { HospitalsTable } from 'components/DataExpansionComponents/Hospitals/HospitalsTable/HospitalsTable'
import { K12Table } from 'components/DataExpansionComponents/K12/K12Table/K12Table'
import { UniversitiesTable } from 'components/DataExpansionComponents/Universities/UniversitiesTable/UniversitiesTable'
import { FBPage } from 'components/FbUI/Page/FBPage'
import {
  FBPageHeader,
  FBPageInnerTabsContainer,
  FBPageTableContainer,
  FBPageTitle,
  FBPageTitleRow,
} from 'components/FbUI/Page/styles'
import { Tabs } from 'components/FbUI/Tabs'
import LoadingScreen from 'components/Layout/LoadingScreen'
import { CreateContactRequesJobFromCampaign } from 'components/Modals/CreateContactRequestJobFromCampaignModal'
import { ChainsTable } from 'components/Tables/ChainsTable/ChainsTable'
import { CompaniesTable } from 'components/Tables/CompaniesTable/CompaniesTable'
import { ContactRequestsTable } from 'components/Tables/ContactRequestsTable/ContactRequestsTable'
import { ContactsTable } from 'components/Tables/ContactsTable/ContactsTable'
import { DealsTable } from 'components/Tables/DealsTable/DealsTable'
import {
  CHAINS_TABLE_KEY,
  COLLEGE_AND_UNIVERSITY_TABLE_KEY,
  CONTACT_REQUEST_JOB_TABLE_KEY,
  CONTACTS_TABLE_KEY,
  DEAL_TABLE_KEY,
  getCampaignTableKey,
  getCompanyTableKey,
  HOSPITALS_TABLE_KEY,
  K12_TABLE_KEY,
} from 'constants/tableQueryKeys'
import { RebateOffersTable } from 'features/tradespend/RebateOffersTable'
import { Campaign } from 'models/campaign'
import {
  Category,
  CompanyTypeSlug,
  CompanyTypeSlugWithFBData,
  SubCategory,
} from 'models/companies'
import { FilterIdentifier } from 'models/saved_view'
import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import apiService from 'services/api'
import { featureFlagService } from 'utils/featureFlagService'
import { useNavbarStore } from '../../../components/Layout/navbar-store' // Adjust the path as needed
import useDocumentTitle from '../../../components/useDocumentTitle'

const TABLE_CONTAINER_HEIGHT = 'calc(var(--base-page-table-height) - 102px)'

export function SingleCampaignPage() {
  const featureFlag = featureFlagService()
  const navigate = useNavigate()
  const params = useParams<{ campaign_id: string; tab?: string }>()
  const campaignId = params.campaign_id
  const selectedTab = params.tab ?? 'opportunities'
  const store = useNavbarStore()
  const api = apiService()
  const { data: campaigns } = api.useGetCampaigns()

  const campaign = campaigns?.find(
    (it) => it.id === parseInt(campaignId ?? '-1')
  )

  const hasContactRequests = useMemo(
    () =>
      [
        'restaurants-bars',
        'education-cu',
        'education-k-12',
        'healthcare-hospitals',
      ].includes(campaign?.type.slug ?? '-1'),
    [campaign]
  )

  useDocumentTitle(`Campaign - ${campaign?.name} - First Bite`)

  if (!campaign) {
    return <LoadingScreen />
  }
  if (!featureFlag.isLoading && !featureFlag.enableCampaigns) {
    navigate('/')
    return null
  }

  return (
    <FBPage>
      <FBPageHeader>
        <FBPageTitleRow className="w-auto">
          <FBPageTitle>
            <span style={{ fontWeight: 400 }}>Campaign - </span>
            {campaign.name}
          </FBPageTitle>
        </FBPageTitleRow>
        <div className="flex gap-3">
          {hasContactRequests && (
            <CreateContactRequesJobFromCampaign campaignId={campaign.id} />
          )}
        </div>
      </FBPageHeader>
      <GetCampaignTable
        campaign={campaign}
        selectedTab={selectedTab}
        onTabChange={(tab) => {
          const url = `/campaigns/${campaignId}/${tab}`
          navigate(url)
          if (campaignId) {
            store.setLastCampaignTabVisited(campaignId, url)
          }
        }}
      />
    </FBPage>
  )
}

function GetCampaignTable(props: {
  campaign: Campaign
  selectedTab: string
  onTabChange: (tab: string) => void
}) {
  const api = apiService()
  const { data: categories, isFetching: categoriesLoading } =
    api.useGetCompanyCategoriesAndSub()

  const campaignTypes = useMemo(() => {
    const types: {
      slug: string
      sub_category: SubCategory
      category: Category
    }[] = []
    categories?.forEach((it) => {
      it.sub_categories.forEach((s) => {
        types.push({
          slug: s.slug,
          sub_category: s,
          category: it,
        })
      })
    })
    return types
  }, [categories])

  const type = campaignTypes.find((it) => it.slug === props.campaign.type.slug)

  if (categoriesLoading || !type) {
    return <LoadingScreen />
  }

  // Explicitly making this CompanyTypeSlugWithFBData type, to REQUIRE that we have a table for each of these types
  const tableBySlugType: Record<CompanyTypeSlugWithFBData, React.JSX.Element> =
    {
      'restaurants-bars': (
        <ChainsTable
          hideCreateCompanyButton={true}
          filterIdentifierModifier={
            `_CAMPAIGN_${props.campaign.id}` as FilterIdentifier
          }
          defaultColumnVisibility={{
            chain: true,
            count: true,
            menu: false,
            menu_length: false,
            menu_matches: false,
            total_ltv: false,
            brand_ltv: false,
            revenue_ltv: false,
            one_year_total_value: false,
            taro: false,
            tabo: false,
            pounds_per_year: false,
            note_count: true,
            contact_count: true,
            deal_count: true,
            sales_stages: true,
            account_owners: true,
            tags: true,
            distributors: true,
            cuisine_50: false,
            expense_category: false,
            rating: false,
            reviews_count: false,
            velocity_group: false,
            domain: false,
            instagram_url: false,
            instagram_followers: false,
            instagram_following: false,
            instagram_posts: false,
            instagram_percentile: false,
            instagram_text_percentile: false,
            ingredients_matrix: false,
            country_summary: false,
            state_summary: false,
            city_summary: false,
            zip_summary: false,
            median_hhi: false,
            pop_density: false,
            hh_gt100k: false,
            reputation_data: false,
            michelin_stars_count: false,
          }}
          tableKey={getCampaignTableKey(CHAINS_TABLE_KEY, props.campaign.id)}
          baseFilters={{ campaigns: props.campaign.id }}
        />
      ),
      'education-cu': (
        <UniversitiesTable
          hideCreateCompanyButton={true}
          defaultColumnVisibility={{
            institution_name: true,
            address: false,
            city: false,
            state_abbr: false,
            zipcode: false,
            domain: false,
            dormitory_capacity: false,
            meal_plan_provided: false,
            total_enrollment: false,
            total_men_enrollment: false,
            total_women_enrollment: false,
            food_management_company: false,
            food_management_company_source: false,
            food_management_company_parent: false,
            estimated_meals_per_day: false,
            carnegie_classification_2021_size_setting_size: false,
            carnegie_classification_2021_size_setting_year: false,
            carnegie_classification_2021_size_setting_student_composition:
              false,
            campaigns: false,
            deal_count: true,
            contact_count: true,
            note_count: true,
            sales_stages: true,
            account_owner: true,
            taglist: true,
            distributors: true,
            google_place_url: false,
          }}
          filterIdentifierModifier={
            `_CAMPAIGN_${props.campaign.id}` as FilterIdentifier
          }
          tableKey={getCampaignTableKey(
            COLLEGE_AND_UNIVERSITY_TABLE_KEY,
            props.campaign.id
          )}
          baseFilters={{ campaigns: props.campaign.id }}
        />
      ),
      'education-k-12': (
        <K12Table
          hideCreateCompanyButton={true}
          filterIdentifierModifier={
            `_CAMPAIGN_${props.campaign.id}` as FilterIdentifier
          }
          tableKey={getCampaignTableKey(K12_TABLE_KEY, props.campaign.id)}
          baseFilters={{ campaigns: props.campaign.id }}
          defaultColumnVisibility={{
            k12district__lea_name: true,
            k12district__address: false,
            k12district__zipcode: false,
            k12district__lcity: false,
            k12district__lstate: false,
            k12district__county: false,
            k12district__locale: false,
            k12district__operational_schools: false,
            k12district__gslo: false,
            k12district__gshi: false,
            k12district__student_count: false,
            k12district__teachers_count: false,
            k12district__phone: false,
            campaigns: false,
            sales_stages: true,
            account_owners: true,
            taglist: true,
            contact_count: true,
            note_count: true,
            distributors: true,
            deal_count: true,
            k12district__revenue: false,
            k12district__reduced_lunch: false,
            k12district__free_lunch: false,
            k12district__estimated_meals_per_day: false,
            k12district__food_service_expenditures: false,
            k12district__total_supplemented_lunch: false,
            k12district__total_supplemented_lunch_perc: false,
            k12district__domain: false,
            k12district__google_place_url: false,
            k12district__uncategorized_lunch: false,
            k12district__free_lunch_perc: false,
            k12district__reduced_lunch_perc: false,
            k12district__uncategorized_lunch_perc: false,
          }}
        />
      ),
      'healthcare-hospitals': (
        <HospitalsTable
          hideCreateCompanyButton={true}
          defaultColumnVisibility={{
            provider_number: true,
            hospital_name: true,
            street_addr: false,
            po_box: false,
            city: false,
            state: false,
            zip_code: false,
            year: false,
            total_hospital_days: false,
            total_hospital_beds: false,
            year_2: false,
            cafeteria: false,
            dietary: false,
            dietary_under_contract: false,
            campaigns: false,
            sales_stages: true,
            account_owners: true,
            taglist: true,
            contact_count: true,
            note_count: true,
            distributors: true,
            deal_count: true,
          }}
          filterIdentifierModifier={
            `_CAMPAIGN_${props.campaign.id}` as FilterIdentifier
          }
          tableKey={getCampaignTableKey(HOSPITALS_TABLE_KEY, props.campaign.id)}
          baseFilters={{ campaigns: props.campaign.id }}
        />
      ),
    }

  const tableForUnmatchedCompanyType = (
    <CompaniesTable
      subCategory={type.sub_category}
      category={type.category}
      filterIdentifierModifier={
        `_CAMPAIGN_${props.campaign.id}` as FilterIdentifier
      }
      tableKey={getCampaignTableKey(
        getCompanyTableKey(type.slug as CompanyTypeSlug),
        props.campaign.id
      )}
      baseFilters={{ campaigns: props.campaign.id }}
    />
  )

  if (Object.keys(tableBySlugType).includes(props.campaign.type.slug)) {
    return (
      <TableContainer
        campaign={props.campaign}
        selectedTab={props.selectedTab}
        onTabChange={props.onTabChange}
        key={`campaign-${props.campaign.id}`}
        oppsTable={
          tableBySlugType[props.campaign.type.slug as CompanyTypeSlugWithFBData]
        }
      />
    )
  } else {
    return (
      <TableContainer
        campaign={props.campaign}
        selectedTab={props.selectedTab}
        onTabChange={props.onTabChange}
        key={`campaign-${props.campaign.id}`}
        oppsTable={tableForUnmatchedCompanyType}
      />
    )
  }
}

function TableContainer(props: {
  oppsTable: React.JSX.Element
  campaign: Campaign
  selectedTab: string
  onTabChange: (tab: string) => void
}) {
  const { enableRebates } = featureFlagService()
  const hasContactRequests = useMemo(
    () =>
      [
        'restaurants-bars',
        'education-cu',
        'education-k-12',
        'healthcare-hospitals',
      ].includes(props.campaign.type.slug),
    [props.campaign]
  )

  const tabs = useMemo(() => {
    const tabs = [
      { title: 'Opportunities', key: 'opportunities' },
      { title: 'Deals', key: 'deals' },
      { title: 'Contacts', key: 'contacts' },
    ]

    if (enableRebates) {
      tabs.push({ title: 'Rebates', key: 'rebate_offers' })
    }

    if (hasContactRequests) {
      tabs.push({ title: 'Contact Requests', key: 'contact_requests' })
    }
    return tabs
  }, [hasContactRequests, enableRebates])

  return (
    <>
      <FBPageInnerTabsContainer>
        <Tabs
          tabs={tabs as any}
          selectedTab={props.selectedTab}
          setSelectedTab={props.onTabChange}
          tabStyle={{ width: '140px' }}
        />
      </FBPageInnerTabsContainer>
      <FBPageTableContainer
        style={{ height: TABLE_CONTAINER_HEIGHT }}
        hide={props.selectedTab !== 'opportunities'}
      >
        {props.oppsTable}
      </FBPageTableContainer>
      <FBPageTableContainer
        style={{ height: TABLE_CONTAINER_HEIGHT }}
        hide={props.selectedTab !== 'deals'}
      >
        <DealsTable
          showCompanyColumn
          disableUrlPagination
          filterIdentifierModifier={`_CAMPAIGN_${props.campaign.id}`}
          baseFilters={{ campaign: props.campaign.id }}
          tableKey={getCampaignTableKey(DEAL_TABLE_KEY, props.campaign.id)}
        />
      </FBPageTableContainer>
      <FBPageTableContainer
        style={{ height: TABLE_CONTAINER_HEIGHT }}
        hide={props.selectedTab !== 'contacts'}
      >
        <ContactsTable
          disableUrlPagination
          defaultColumnVisibility={{ requested_contact__campaign__name: false }}
          tableKey={getCampaignTableKey(CONTACTS_TABLE_KEY, props.campaign.id)}
          filterIdentifierModifier={`_CAMPAIGN_${props.campaign.id}`}
          baseFilters={{ campaign: props.campaign.id }}
        />
      </FBPageTableContainer>
      {hasContactRequests && (
        <FBPageTableContainer
          style={{ height: TABLE_CONTAINER_HEIGHT }}
          hide={props.selectedTab !== 'contact_requests'}
        >
          <ContactRequestsTable
            tableKey={getCampaignTableKey(
              CONTACT_REQUEST_JOB_TABLE_KEY,
              props.campaign.id
            )}
            campaignId={props.campaign.id}
          />
        </FBPageTableContainer>
      )}
      {enableRebates && (
        <FBPageTableContainer
          style={{ height: TABLE_CONTAINER_HEIGHT }}
          hide={props.selectedTab !== 'rebate_offers'}
        >
          <RebateOffersTable campaignId={props.campaign.id} />
        </FBPageTableContainer>
      )}
    </>
  )
}
