import { StagePill } from 'components/FbUI/StagePill'
import { DealHistory } from 'models/deals'
import { Product } from 'models/product'
import moment from 'moment'
import { useMemo } from 'react'
import { Spinner } from 'react-bootstrap'
import { AiOutlinePlus } from 'react-icons/ai'
import { FaQuestion } from 'react-icons/fa'
import { HiPencil } from 'react-icons/hi'
import { MdOutlineBarChart } from 'react-icons/md'
import apiService from 'services/api'
import s from 'styled-components'
import { formatTwoDecimal, formatUsdDecimal } from 'utils/formatting'

interface ExpandedRowProps {
  dealId: number
  product: Product
}

const ExpandedDealsRow = (props: ExpandedRowProps) => {
  const api = apiService()
  const { data: data, isLoading: loading } = api.useGetDealHistory({
    dealId: props.dealId,
  })

  const dataRow = useMemo(() => {
    const skipFields = [
      'modified',
      'datetime_closed',
      'last_datetime_stage_changed',
    ]

    // When the creating the first field history logs for an instance,
    // a row is created for each field with action_type === "CREATE"
    // Below is hack to prevent rendering multiple create logs.
    // Check: /server/activity/managers.py
    const createLog = data?.find((it) => it.action_type === 'CREATE')
    const initial = []
    if (createLog) {
      initial.push({
        userName: createLog?.user.first_name + ' ' + createLog?.user.last_name,
        date: createLog?.created_at,
        type: props.product?.name + ' deal',
        action: 'create',
      })
    }

    const newData = data
      ?.filter(
        (it: DealHistory) =>
          !skipFields.includes(it.field_name) && it.action_type !== 'CREATE'
      )
      .map((row: DealHistory) => {
        const newRow = {} as any
        newRow.userName = row?.user?.first_name + ' ' + row?.user?.last_name
        newRow.date = row?.created_at
        newRow.type = row.field_name.replaceAll('_', ' ')
        newRow.action = 'change'

        switch (true) {
          case 'sales_stage_id' === row.field_name:
            newRow.action = 'change'
            newRow.resultFrom = row?.previous_value
            newRow.resultTo = row?.value
            newRow.type = 'sales stage'
            break

          case 'note' === row.field_name:
            newRow.action = 'update'
            newRow.resultFrom = row?.previous_value
            newRow.resultTo = row?.value
            newRow.type = 'note'
            break

          case 'account_owner_id' === row?.field_name:
            newRow.action = 'update'
            newRow.resultFrom = row?.previous_value
            newRow.resultTo = row?.value
            newRow.type = 'account owner'
            break

          case 'monthly_volume_override' === row?.field_name:
            newRow.action = 'update'
            newRow.resultFrom = /[0-9]*\.?[0-9]+/.test(row?.previous_value)
              ? formatTwoDecimal(row?.previous_value ?? 0) + ' lbs'
              : row?.previous_value
            newRow.resultTo = /[0-9]*\.?[0-9]+/.test(row?.value)
              ? formatTwoDecimal(row?.value ?? 0) + ' lbs'
              : row?.value
            newRow.type = 'monthly volume'
            break

          case 'monthly_revenue_override' === row?.field_name:
            newRow.action = 'update'
            newRow.resultFrom = /[0-9]*\.?[0-9]+/.test(row?.previous_value)
              ? formatUsdDecimal(row?.previous_value)
              : row?.previous_value
            newRow.resultTo = /[0-9]*\.?[0-9]+/.test(row?.value)
              ? formatUsdDecimal(row?.value)
              : row?.value
            newRow.type = 'monthly revenue'
            break

          case 'close_date_override' === row?.field_name:
            newRow.action = 'update'
            newRow.resultFrom = row?.previous_value
            newRow.resultTo = row?.value
            newRow.type = 'close date'
            if (moment(newRow.resultFrom).isValid()) {
              newRow.resultFrom = moment(newRow.resultFrom).format(
                'MMM D, YYYY'
              )
            }
            if (moment(newRow.resultTo).isValid()) {
              newRow.resultTo = moment(newRow.resultTo).format('MMM D, YYYY')
            }
            break
        }

        return newRow
      })

    if (newData) {
      return [...initial, ...newData].sort((a, b) =>
        moment(b.date).diff(moment(a.date))
      )
    }
    return initial
  }, [data, loading])

  const returnIconRow = (row: any) => {
    switch (row?.action) {
      case 'create':
        return <AiOutlinePlus size={18} />
      case 'update':
        return <HiPencil size={18} />
      case 'change':
        return <MdOutlineBarChart size={18} />
      default:
        return <FaQuestion size={18} />
    }
  }

  const returnActionString = (row: any) => {
    switch (row?.action) {
      case 'create':
        return 'created'
      case 'update':
        return 'updated'
      case 'change':
        return 'changed'
      default:
        return ''
    }
  }

  const returnResultString = (row: any) => {
    let newResultFrom = row?.resultFrom
    let newResultTo = <ResultToText>{row?.resultTo}</ResultToText>

    if (row?.type === 'sales stage') {
      newResultFrom = (
        <PillResultContainer>
          <StagePill stageId={row?.resultFrom} />
        </PillResultContainer>
      )
      newResultTo = (
        <PillResultContainer>
          <StagePill stageId={row?.resultTo} />
        </PillResultContainer>
      )
    }

    if (row?.resultFrom && row?.resultTo) {
      return (
        <ResultContainer>
          from {newResultFrom} to {newResultTo}
        </ResultContainer>
      )
    } else if (!row?.resultFrom && row?.resultTo) {
      return <ResultContainer>to {newResultTo}</ResultContainer>
    } else {
      return
    }
  }

  if (loading)
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    )

  return (
    <ExpandedDealsContainer>
      {dataRow.map((row: any, index: number) => {
        return (
          <div key={index}>
            <DealContainer>
              <RowIcon>{returnIconRow(row)}</RowIcon>
              <RowName>
                {row?.userName ? row?.userName : '(Name not found)'}
              </RowName>
              <RowAction>{returnActionString(row)}</RowAction>
              <RowType>{row?.type ? row?.type : '(Type not found)'}</RowType>
              {returnResultString(row) && (
                <RowAction>{returnResultString(row)}</RowAction>
              )}
              <RowDate>
                {row?.date
                  ? 'on ' + moment(row?.date).format('MMM DD YYYY @ h:mm A')
                  : '(Date not found)'}
              </RowDate>
            </DealContainer>
            {index !== dataRow.length - 1 && <VerticalLine />}
          </div>
        )
      })}
    </ExpandedDealsContainer>
  )
}

const LoadingContainer = s.div`
    margin: 20px 0px;
    margin-left: calc(40vw + 5rem);
`

const ExpandedDealsContainer = s.div`
  padding: 24px 0px 24px 70px;
`

const DealContainer = s.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`

const RowIcon = s.span`
  width: 16px;
  height: 16px;
  color: #087443;
  margin-right: 16px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`

const RowName = s.span`
  color: #171C26;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.28px;
`

const RowAction = s.span`
  color: #464F60;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.28px;
`

const RowType = s.span`
  color: #087443;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.28px;
`

const RowDate = s.span`
  color: #687182;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const VerticalLine = s.div`
  width: 2px;
  height: 16px;
  background-color: #D5DBE5;
  margin: 8px 0px;
  margin-left: 8px;
`

const ResultContainer = s.span`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
`

const PillResultContainer = s.span`
  margin: 0 5px;
`

const ResultToText = s.span`
  font-weight: 700;
  margin: 0 4px;
`

export default ExpandedDealsRow
