import { FBPage } from 'components/FbUI/Page/FBPage'
import { useNavigate, useParams } from 'react-router-dom'
import {
  FBPageHeader,
  FBPageInnerTabsContainer,
  FBPageTableContainer,
  FBPageTitle,
  FBPageTitleRow,
} from '../../../components/FbUI/Page/styles'
import { Tabs } from '../../../components/FbUI/Tabs'
import { useNavbarStore } from '../../../components/Layout/navbar-store'
import { RebateOffersTable } from 'features/tradespend/RebateOffersTable'
import { CreateRebateOfferModal } from 'features/tradespend/CreateRebateOfferModal'
import { RebatePaymentsTab } from 'features/tradespend/RebatePaymentsTab'

const TABLE_CONTAINER_HEIGHT = 'calc(var(--base-page-table-height) - 95px)'

export function Rebates() {
  const navigate = useNavigate()
  const params = useParams()
  const selectedTab = params?.tab ?? 'offers'
  const store = useNavbarStore()

  const tabs = [
    { key: 'dashboard', title: 'Dashboard' },
    { key: 'offers', title: 'Offers' },
    { key: 'payments', title: 'Payments' },
  ]

  return (
    <FBPage>
      <FBPageHeader>
        <FBPageTitleRow>
          <FBPageTitle>Rebates</FBPageTitle>
        </FBPageTitleRow>
        <div className="flex gap-3 mx-6">
          {selectedTab === 'offers' && <CreateRebateOfferModal />}
        </div>
      </FBPageHeader>

      <FBPageInnerTabsContainer>
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={(value) => {
            const url = `/rebates/${value}`
            navigate(url)
            store.setLastRebateVisited(url)
          }}
          tabStyle={{ width: '125px' }}
        />
      </FBPageInnerTabsContainer>
      <FBPageTableContainer
        style={{ height: TABLE_CONTAINER_HEIGHT }}
        hide={selectedTab !== 'dashboard'}
      >
        {/* TODO dashboard table */}
      </FBPageTableContainer>
      <FBPageTableContainer
        style={{ height: TABLE_CONTAINER_HEIGHT }}
        hide={selectedTab !== 'offers'}
      >
        <RebateOffersTable />
      </FBPageTableContainer>
      <FBPageTableContainer
        style={{ height: TABLE_CONTAINER_HEIGHT }}
        hide={selectedTab !== 'payments'}
      >
        <RebatePaymentsTab />
      </FBPageTableContainer>
    </FBPage>
  )
}
