import { CellContext, ColumnDef } from '@tanstack/react-table'
import { FbLink } from 'components/FbUI/FbLink'
import { ContactsPreviewTable } from 'components/Tables/ContactsPreviewTable/ContactsPreviewTable'
import { MissingBadge } from 'components/Tables/ContactsTable/useContactsTableColumns'
import { FailedContact } from 'models/contacts'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { IoIosWarning } from 'react-icons/io'
import { MdOutlineEmail } from 'react-icons/md'
import BaseStep from './BaseStep'
import { useImportContactContext } from './ImportContactContext'
import * as S from './styles'

function ErrorOverlay({
  fields,
  errors,
}: {
  fields: string[]
  errors: { [key: string]: string[] }
}) {
  const fieldErrors = fields.reduce((acc, f) => {
    const fieldErrors = errors[f]
    if (fieldErrors) {
      acc = [...acc, ...fieldErrors]
    }
    return acc
  }, [] as string[])
  const uniqueErrors = [...new Set(fieldErrors)]
  const errorStr = uniqueErrors.join(', ')
  if (!errorStr) return <></>

  return (
    <OverlayTrigger placement="top" overlay={<Tooltip>{errorStr}</Tooltip>}>
      <span>
        <IoIosWarning size={18} color="#F55800" style={{ marginRight: 5 }} />
      </span>
    </OverlayTrigger>
  )
}

export function PreviewFailedStep() {
  const {
    methods: { prevStep, handleClose, nextStep },
    state: { previewData, file, isImporting },
  } = useImportContactContext()

  if (!previewData || !file) return null

  const {
    success_count: totalSuccessContacts,
    failed_count: totalContacts,
    failed_contacts_csv: csvData,
  } = previewData

  const defaultCell = (
    info: CellContext<FailedContact, any>,
    fields: string[]
  ) => {
    let value = info.getValue()
    if (!value) value = '-'
    return (
      <div>
        <ErrorOverlay fields={fields} errors={info.row.original.errors} />
        {value}
      </div>
    )
  }

  const columns = [
    {
      accessorKey: 'errors',
      header: 'Issues',
      size: 100,
      cell: (info) => {
        const errors = info.getValue()

        return (
          <div className="d-flex align-items-center">
            <IoIosWarning
              size={18}
              color="#F55800"
              style={{ marginRight: 5 }}
            />
            {Object.keys(errors).length}
          </div>
        )
      },
    },
    {
      accessorKey: 'email_work',
      header: 'Work Email Address',
      size: 350,
      cell: (info) => {
        const email = info.getValue()

        return (
          <div className="d-flex">
            <ErrorOverlay
              fields={['email_work']}
              errors={info.row.original.errors}
            />
            <MdOutlineEmail
              size={20}
              color="#D0D3DD"
              style={{ marginRight: 5 }}
            />
            {email}
          </div>
        )
      },
    },
    {
      accessorKey: 'email_personal',
      header: 'Personal Email Address',
      size: 350,
      cell: (info) => {
        const email = info.getValue()

        return (
          <div className="d-flex">
            <ErrorOverlay
              fields={['email_personal']}
              errors={info.row.original.errors}
            />
            <MdOutlineEmail
              size={20}
              color="#D0D3DD"
              style={{ marginRight: 5 }}
            />
            {email}
          </div>
        )
      },
    },
    {
      accessorKey: 'email_generic',
      header: 'Other Email Address',
      size: 350,
      cell: (info) => {
        const email = info.getValue()

        return (
          <div className="d-flex">
            <ErrorOverlay
              fields={['email_generic']}
              errors={info.row.original.errors}
            />
            <MdOutlineEmail
              size={20}
              color="#D0D3DD"
              style={{ marginRight: 5 }}
            />
            {email}
          </div>
        )
      },
    },
    {
      accessorKey: 'company',
      header: 'Company',
      size: 250,
      cell: (info) => {
        const company = info.getValue()
        if (!company)
          return (
            <MissingBadge>
              <IoIosWarning size={18} color="#F55800" />
              Missing
            </MissingBadge>
          )

        return company
      },
    },
    {
      accessorKey: 'company_domain',
      header: 'Company Domain',
      size: 250,
      cell: (info) => defaultCell(info, ['company_domain']),
    },
    {
      accessorKey: 'name',
      accessorFn: (row) => `${row.first_name ?? ''} ${row.last_name ?? ''}`,
      header: 'Name',
      size: 200,
      cell: (info) => defaultCell(info, ['first_name', 'last_name']),
    },
    {
      accessorKey: 'title',
      header: 'Title',
      size: 200,
      cell: (info) => defaultCell(info, ['title']),
    },
    {
      accessorKey: 'notes',
      header: 'Notes',
      size: 200,
      cell: (info) => {
        const notes = info.getValue()
        if (!notes) return '-'

        return (
          <span style={{ wordBreak: 'break-word' }}>
            {`${notes.slice(0, 50)}${notes.length > 50 ? '...' : ''}`}
          </span>
        )
      },
    },
    {
      accessorKey: 'url',
      header: 'Website',
      size: 350,
      cell: (info) => defaultCell(info, ['url']),
    },
    {
      accessorKey: 'linkedin_url',
      header: 'LinkedIn',
      size: 350,
      cell: (info) => defaultCell(info, ['url']),
    },
    {
      accessorKey: 'phone_number',
      header: 'Phone Number',
      size: 200,
      cell: (info) => defaultCell(info, ['phone_number']),
    },
  ] as ColumnDef<FailedContact, any>[]

  const downloadFailedContactsCsv = () => {
    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'failed_contacts.csv'
    a.click()

    window.URL.revokeObjectURL(url)
    a.remove()
  }

  return (
    <BaseStep
      handleClose={handleClose}
      onBack={() => prevStep()}
      onContinue={() => nextStep()}
      title="Update New Contacts - Review Missing"
      backButtonText="Back"
      continueButtonText="Continue"
      canContinue={totalSuccessContacts > 0}
      isContinueLoading={isImporting}
    >
      <S.Content style={{ maxHeight: 500 }}>
        <S.ContentTitle>
          {`${totalContacts} contact${totalContacts === 1 ? ' is' : 's are'} `}{' '}
          with missing fields or invalid data. Return to the previous step to
          change columns, or click complete to finish importing. You may need to
          scroll horizontally to see all columns. <br />
          <FbLink to="#" onClick={downloadFailedContactsCsv}>
            Download Failed Contacts
          </FbLink>
          {totalContacts > 50 && (
            <>
              <br />
              The following table contains the first 50 contacts that failed to
              import.
            </>
          )}
        </S.ContentTitle>
        <ContactsPreviewTable
          data={previewData.failed_contacts}
          columns={columns}
        />
      </S.Content>
    </BaseStep>
  )
}
