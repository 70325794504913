import React from 'react'
import { BiArrowToRight, BiTrash } from 'react-icons/bi'
import { FiEdit2 } from 'react-icons/fi'
import { TbListDetails } from 'react-icons/tb'
import { cn } from '../UI/cn'

interface ActionIconProps {
  style?: React.CSSProperties
  onClick: () => void
  icon?: React.ComponentType<{
    size: number
    color: string
    style: React.CSSProperties
    onClick: (e: React.MouseEvent) => void
  }>
}

const defaultIconProps = {
  size: 20,
  color: '#667085',
}

export const SeeIcon = ({
  onClick,
  style,
  icon: Icon = BiArrowToRight,
}: ActionIconProps) => {
  return (
    <Icon
      {...defaultIconProps}
      style={{ cursor: 'pointer', ...style }}
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
    />
  )
}

export const DeleteIcon = ({
  onClick,
  style,
  icon: Icon = BiTrash,
}: ActionIconProps) => {
  return (
    <Icon
      {...defaultIconProps}
      style={{ cursor: 'pointer', ...style }}
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
    />
  )
}

export const EditIcon = ({
  onClick,
  style,
  icon: Icon = FiEdit2,
}: ActionIconProps) => {
  return (
    <Icon
      {...defaultIconProps}
      style={{ cursor: 'pointer', ...style }}
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
    />
  )
}

export const ViewIcon = ({
  onClick,
  style,
  icon: Icon = TbListDetails,
}: ActionIconProps) => {
  return (
    <Icon
      {...defaultIconProps}
      style={{ cursor: 'pointer', ...style }}
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
    />
  )
}

type ActionButtonsProps = {
  onEdit?: () => void
  onDelete?: () => void
  onSee?: () => void
  className?: string
  editIcon?: React.ComponentType<{
    size: number
    color: string
    style: React.CSSProperties
    onClick: (e: React.MouseEvent) => void
  }>
  deleteIcon?: React.ComponentType<{
    size: number
    color: string
    style: React.CSSProperties
    onClick: (e: React.MouseEvent) => void
  }>
  seeIcon?: React.ComponentType<{
    size: number
    color: string
    style: React.CSSProperties
    onClick: (e: React.MouseEvent) => void
  }>
}

export function ActionButtons(props: ActionButtonsProps) {
  return (
    <div
      className={cn(
        'flex items-center justify-end pr-5 gap-2 flex-1',
        props.className
      )}
    >
      {props.onEdit && (
        <EditIcon onClick={props.onEdit} icon={props.editIcon} />
      )}
      {props.onDelete && (
        <DeleteIcon onClick={props.onDelete} icon={props.deleteIcon} />
      )}
      {props.onSee && <SeeIcon onClick={props.onSee} icon={props.seeIcon} />}
    </div>
  )
}

type JobActionButonProps = {
  onView: () => void
  className?: string
}

export function JobActionButtons(props: JobActionButonProps) {
  return (
    <div
      className={cn(
        'flex items-center justify-center pr-5 gap-2 flex-1',
        props.className
      )}
    >
      <ViewIcon onClick={props.onView} />
    </div>
  )
}
