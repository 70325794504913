import apiService from 'services/api'
import { formatInteger } from 'utils/formatting'
import { Modal } from '../../UI/Modal/Modal'
import { useDataTableContext } from 'components/DataTable'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { z } from 'zod'
import lodash from 'lodash'
import { BiTrash } from 'react-icons/bi'
import { MdOutlineLibraryAdd } from 'react-icons/md'
import AddDealsOverrideModal from './AddDealsOverrideModal'
import { useState } from 'react'

const overridesSchema = z.object({
  monthly_volume_override: z.union([
    z.number().min(0).optional(),
    z.literal(''),
  ]),
  monthly_revenue_override: z.union([
    z.number().min(0).optional(),
    z.literal(''),
  ]),
  close_date_override: z.string().optional(),
})

type DealsOverride = z.infer<typeof overridesSchema>

export default function DealsOverrideModal({
  data,
  filterAndSortParams,
  handleClose,
  idAccessor,
  show,
  tableQueryKey,
}: {
  show: boolean
  handleClose: () => void
  tableQueryKey: string[]
  idAccessor: string

  data: any[]

  filterAndSortParams?: Record<string, any>
}) {
  const queryClient = useQueryClient()
  const api = apiService()

  const [showAddOverride, setShowAddOverride] = useState(false)

  const {
    state: { totalSelectedRows, rowSelection, isAllRowsSelected },
  } = useDataTableContext()

  const unselectedRows = data.filter((_, i) => !rowSelection[i])
  const selectedRows = data.filter((_, i) => rowSelection[i])

  const { isPending, mutate } = useMutation({
    mutationFn: ({
      excludeIds,
      selectedIds,
    }: {
      overrides?: DealsOverride
      excludeIds?: string[]
      selectedIds?: string[]
      affectedRowsIndexes: number[]
    }) => {
      const body: any = {}

      body['ids'] = selectedIds
      body['exclude_ids'] = excludeIds

      // If all rows are selected, we don't need to send the chain_ids or contact_company_ids
      if (isAllRowsSelected) {
        delete body['ids']
      }

      return api.bulkEditDeal({
        deal: {
          close_date_override: null,
          monthly_revenue_override: null,
          monthly_volume_override: null,
        },
        params: filterAndSortParams,
        ...body,
      })
    },
    onSuccess: (data) => {
      if (data.status === 202) {
        toast.info(
          data.data?.message ??
            'We are processing your request, you will be notified when it is done'
        )
      } else {
        toast.success('Updated successfully')
      }
      handleClose()
    },

    onError: (err, options, context: any) => {
      context()
      toast.error(`Error updating: ${err}`)
    },
    onMutate: ({ affectedRowsIndexes }) => {
      const previousData = queryClient.getQueryData(tableQueryKey)

      queryClient.setQueryData(tableQueryKey, (old: any) => {
        const newData = lodash.cloneDeep(old)
        affectedRowsIndexes.forEach((idx) => {
          const row = newData.results?.[idx] || newData?.[idx]
          if (row) {
            row['monthly_volume_override'] = null
            row['monthly_revenue_override'] = null
            row['close_date_override'] = null
          }
        })
        return newData
      })
      return () => queryClient.setQueryData(tableQueryKey, previousData)
    },
  })

  const handleMutate = async () => {
    const excludeIds = unselectedRows?.map((r) => {
      return idAccessor.split('.').reduce((acc, key) => acc[key], r)
    })
    const selectedIds = selectedRows?.map((r) => {
      return idAccessor.split('.').reduce((acc, key) => acc[key], r)
    })

    const affectedRowsIndexes = Object.keys(rowSelection).map((key) =>
      parseInt(key)
    )

    return mutate({
      excludeIds,
      selectedIds,
      affectedRowsIndexes,
    })
  }

  return (
    <>
      <Modal
        title="Manage Overrides"
        description={
          <span>
            Do you want to <b>add</b> or <b>remove</b> overrides for all{' '}
            <b>{formatInteger(totalSelectedRows)}</b> deals?
            <br />
            <br />
            Removing any overrides will revert volume and revenue forecasts back
            to First Bite calculations.
          </span>
        }
        onOpenChange={(open) => !open && handleClose?.()}
        open={show && !showAddOverride}
        onAccept={() => setShowAddOverride(true)}
        acceptButtonText="Add"
        acceptButtonProps={{
          children: <MdOutlineLibraryAdd size={20} />,
        }}
        onCancel={handleClose}
        cancelButtonText="Cancel"
        loading={isPending}
        onAlt={handleMutate}
        altButtonProps={{
          children: <BiTrash size={20} />,
        }}
        altButtonText="Remove"
      />
      <AddDealsOverrideModal
        show={showAddOverride}
        handleClose={() => {
          setShowAddOverride(false)
          handleClose()
        }}
        tableQueryKey={tableQueryKey}
        idAccessor={idAccessor}
        data={data}
        filterAndSortParams={filterAndSortParams}
      />
    </>
  )
}
