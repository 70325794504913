import { createFilterColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { filterStoreRepo } from 'components/Filters/FilterStore'
import FilterModal, {
  FilterGroup,
} from 'components/Filters/components/FilterModal'
import { FilterIdentifier } from 'models/saved_view'
import { CAMPAIGNS_COLUMNS } from './campaignsColumns'
import { SelectFilter } from 'components/Filters/fields/SelectFilter'
import { useCategorySubcategoryOptions } from 'hooks/useCategorySubcategoryFilterOptions'
import apiService from 'services/api'
import { RangeFilter } from 'components/Filters/fields/RangeFilter'
import { DateRangeFilter } from 'components/Filters/fields/DateRangeFilter'

// Define campaign columns (to be filled based on your needs)

export function CampaignsFilterset({
  filterIdentifier,
}: {
  filterIdentifier: FilterIdentifier
}) {
  const api = apiService()
  const campaignsFilterStore = filterStoreRepo.getStore(filterIdentifier)
  const campaignFilterHelper = createFilterColumnHelper({
    columns: CAMPAIGNS_COLUMNS,
  })
  const { data: productsOptions } = api.useGetProductsOptions()
  const { subCategoriesOptions } = useCategorySubcategoryOptions({
    filterStore: campaignsFilterStore,
  })

  return (
    <FilterModal identifier={filterIdentifier} store={campaignsFilterStore}>
      <FilterGroup title="Campaign Attributes">
        <SelectFilter
          {...campaignFilterHelper.retrieveProps('company_type')}
          filterStore={campaignsFilterStore}
          options={subCategoriesOptions}
        />
        <SelectFilter
          {...campaignFilterHelper.retrieveProps('product')}
          filterStore={campaignsFilterStore}
          options={productsOptions as any}
        />
        <RangeFilter
          {...campaignFilterHelper.retrieveProps('contact_company_count')}
          store={campaignsFilterStore}
        />
        <RangeFilter
          {...campaignFilterHelper.retrieveProps('deal_count')}
          store={campaignsFilterStore}
        />
        <RangeFilter
          {...campaignFilterHelper.retrieveProps('contact_count')}
          store={campaignsFilterStore}
        />
        <RangeFilter
          {...campaignFilterHelper.retrieveProps('days_since_last_update')}
          store={campaignsFilterStore}
        />
        <DateRangeFilter
          {...campaignFilterHelper.retrieveProps('created')}
          filterStore={campaignsFilterStore}
        />
        <DateRangeFilter
          {...campaignFilterHelper.retrieveProps('modified')}
          filterStore={campaignsFilterStore}
        />
      </FilterGroup>
    </FilterModal>
  )
}
