import apiService from 'services/api'
import { downloadFile } from 'utils/csvDownloader'
import ExportModal, { ExportModalProps } from './ExportModal'
import { CHAIN_COLUMNS } from 'components/DataExpansionComponents/Chains/chainColumnsInfo'
import { createExportColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { getCommonChainProxyExportColumns } from 'components/DataExpansionComponents/Common/commonExportColumns'

export default function ChainExportModal(props: ExportModalProps) {
  const api = apiService()

  const exportChains = async ({
    filter,
    selectedColumns,
    selectedProductId,
    exportType,
    controller,
  }: {
    filter: Record<string, unknown>
    selectedColumns: string[]
    selectedProductId?: number
    exportType: 'csv' | 'xlsx'
    controller?: AbortController
  }) => {
    const res = await api.downloadChainsCsvV2(
      {
        ...filter,
        product_id: selectedProductId,
      },
      selectedColumns,
      exportType,
      props.selectedIds,
      props.excludeIds,
      controller?.signal
    )

    downloadFile(
      res,
      `First Bite Export: Chains - ${new Date().toLocaleDateString()}.${exportType}`
    )
  }

  const chainColumnHelper = createExportColumnHelper({
    columns: CHAIN_COLUMNS,
  })

  const columns = [
    {
      title: 'Opportunity Attributes',
      elements: [
        chainColumnHelper.retrieveColumn('chain'),
        chainColumnHelper.retrieveColumn('count'),
        chainColumnHelper.retrieveColumn('ingredients_matrix'),
        chainColumnHelper.retrieveColumn('menu_length'),
        chainColumnHelper.retrieveColumn('menu_matches'),
        chainColumnHelper.retrieveColumn('cuisine_50'),
        chainColumnHelper.retrieveColumn('expense_category'),
        chainColumnHelper.retrieveColumn('velocity_group'),
        chainColumnHelper.retrieveColumn('distributors'),
        chainColumnHelper.retrieveColumn('domain'),
        chainColumnHelper.retrieveColumn('city_summary'),
        chainColumnHelper.retrieveColumn('dma_summary'),
        chainColumnHelper.retrieveColumn('state_summary'),
        chainColumnHelper.retrieveColumn('zip_summary'),
        chainColumnHelper.retrieveColumn('country_summary'),
        chainColumnHelper.retrieveColumn('is_chain'),
      ],
    },
    {
      title: 'Reputation Insights',
      elements: [
        chainColumnHelper.retrieveColumn('rating'),
        chainColumnHelper.retrieveColumn('reviews_count'),
        chainColumnHelper.retrieveColumn('instagram_url'),
        chainColumnHelper.retrieveColumn('instagram_followers'),
        chainColumnHelper.retrieveColumn('instagram_following'),
        chainColumnHelper.retrieveColumn('instagram_posts'),
        chainColumnHelper.retrieveColumn('instagram_text_percentile'),
        chainColumnHelper.retrieveColumn('reputation_data'),
        chainColumnHelper.retrieveColumn('michelin_stars_count'),
      ],
    },
    {
      title: 'Predicted Values and Deals',
      elements: [
        chainColumnHelper.retrieveColumn('total_ltv'),
        chainColumnHelper.retrieveColumn('brand_ltv'),
        chainColumnHelper.retrieveColumn('revenue_ltv'),
        chainColumnHelper.retrieveColumn('one_year_total_value'),
        chainColumnHelper.retrieveColumn('tabo'),
        chainColumnHelper.retrieveColumn('taro'),
        chainColumnHelper.retrieveColumn('pounds_per_year'),
        ...getCommonChainProxyExportColumns(),
      ],
    },
  ]

  return (
    <ExportModal
      title="Select Columns for Restaurant Chains Download"
      subTitle="Download up to 10,000 chains at a time, with any of the following columns. Any filters already applied will remain active."
      submitText="Export Chains"
      columns={columns}
      exportAPIAction={exportChains}
      {...props}
    />
  )
}
