import { CellEventFormatterProps } from '../../utils'
import { GreenBold } from '../CellEventFormatter'
import { DefaultUpdateEvent } from './DefaultUpdateEvent'
import apiService from 'services/api'
import styled from 'styled-components/macro'

export const CampaignUpdateEvent = ({ event }: CellEventFormatterProps) => {
  const api = apiService()
  const { data: products } = api.useGetProducts()

  // Use default formatter for description
  if (event.field_name === 'description') {
    return <DefaultUpdateEvent event={event} />
  }

  // Custom formatter for product
  if (event.field_name === 'product_id') {
    const currentProduct = products?.find((p) => p.id === Number(event.value))
    const previousProduct = event.changes?.[0]?.previous_value
      ? products?.find((p) => p.id === Number(event.changes[0].previous_value))
      : null

    return (
      <>
        changed <GreenBold>product</GreenBold>{' '}
        {previousProduct && (
          <>
            from <b>{previousProduct.name}</b>{' '}
          </>
        )}
        to <b>{currentProduct?.name ?? 'none'}</b>
      </>
    )
  }

  // Custom formatter for color
  if (event.field_name === 'color') {
    const previousColor = event.changes?.[0]?.previous_value
    const currentColor = event.value
    return (
      <>
        changed <GreenBold>color</GreenBold>{' '}
        {previousColor && (
          <>
            from <ColorCircle color={previousColor} />{' '}
          </>
        )}
        to <ColorCircle color={currentColor || '#000000'} />
      </>
    )
  }

  // Fallback to default formatter for other fields
  return <DefaultUpdateEvent event={event} />
}

interface ColorCircleProps {
  color: string
}

const ColorCircle = styled.span<ColorCircleProps>`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  vertical-align: middle;
  margin: 0 4px;
  border: 1px solid #e2e2e2;
`
