import * as React from 'react'
import { Calendar as CalendarIcon } from 'lucide-react'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import { PopoverContent, PopoverTrigger, Popover } from '../UI/combobox/popover'
import FbButton from '../FbUI/FbButton'
import { cn } from '../UI/cn'
import { Calendar } from '../UI/calendar'
import { format } from 'date-fns'

interface DatePickerWithRangeProps<T extends FieldValues> {
  control: Control<T>
  name: Path<T>
  label?: string
  optional?: boolean
  className?: string
}

export function DateRangePicker<T extends FieldValues>({
  control,
  name,
  label,
  optional = false,
  className,
}: DatePickerWithRangeProps<T>) {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control })

  return (
    <div className="flex flex-col mt-3 w-full">
      {label && (
        <label
          htmlFor={name}
          className="text-base font-medium text-gray-800 select-none mb-2"
        >
          {label}
          {optional && (
            <span className="text-sm italic font-normal"> - optional</span>
          )}
        </label>
      )}
      <div className={cn('grid gap-2', className)}>
        <Popover>
          <PopoverTrigger asChild>
            <FbButton
              id={name}
              className={cn(
                'w-[300px] justify-start text-left font-normal',
                !value && 'text-muted-foreground'
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {value?.from ? (
                value.to ? (
                  <>
                    {format(value.from, 'LLL dd, y')} -{' '}
                    {format(value.to, 'LLL dd, y')}
                  </>
                ) : (
                  format(value.from, 'LLL dd, y')
                )
              ) : (
                <span>Pick a date</span>
              )}
            </FbButton>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={value?.from}
              selected={value}
              onSelect={onChange}
              numberOfMonths={2}
            />
          </PopoverContent>
        </Popover>
      </div>
      {error && <p className="text-red-500 text-sm mt-1">{error.message}</p>}
    </div>
  )
}
