import FbButton from 'components/FbUI/FbButton'
import { ReactNode } from 'react'

interface BaseStepProps {
  children: ReactNode
  title: string
  description?: string
  handleClose: () => void
  onBack?: () => void
  onContinue: () => void
  backButtonText?: string
  continueButtonText?: string
  cancelButtonText?: string
  isContinueLoading?: boolean
  currentStep?: number
  totalSteps?: number
  continueDisabled?: boolean
}

import {
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/UI/Modal/ModalPrimitive'
import styled from 'styled-components/macro'

interface BaseStepProps {
  children: ReactNode
  title: string
  description?: string
  handleClose: () => void
  onBack?: () => void
  onContinue: () => void
  backButtonText?: string
  continueButtonText?: string
  cancelButtonText?: string
  isContinueLoading?: boolean
  currentStep?: number
  totalSteps?: number
  continueDisabled?: boolean
}

export default function BaseStep({
  children,
  title,
  description,
  handleClose,
  onBack,
  onContinue,
  backButtonText = 'Back',
  continueButtonText = 'Continue',
  cancelButtonText = 'Cancel',
  isContinueLoading = false,
  currentStep = 0,
  totalSteps = 2,
  continueDisabled = false,
}: BaseStepProps) {
  return (
    <>
      <DialogHeader>
        <DialogTitle>{title}</DialogTitle>
        {description && <DialogDescription>{description}</DialogDescription>}
      </DialogHeader>
      <div className="max-h-[70vh] overflow-y-auto overflow-x-hidden max-w-full w-full">
        <ModalBodyContent>{children}</ModalBodyContent>
      </div>
      <StepIndicators>
        {Array.from({ length: totalSteps }).map((_, index) => (
          <StepDot key={index} $active={index === currentStep} />
        ))}
      </StepIndicators>
      <FilterModalFooter>
        <FbButtonModal variant="secondary" onClick={onBack || handleClose}>
          {onBack ? backButtonText : cancelButtonText}
        </FbButtonModal>
        <FbButtonModal
          variant="primary"
          onClick={onContinue}
          loading={isContinueLoading}
          disabled={continueDisabled}
        >
          {continueButtonText}
        </FbButtonModal>
      </FilterModalFooter>
    </>
  )
}

const FilterModalFooter = styled.div`
  display: flex;
  gap: 2px 16px;
`

const FbButtonModal = styled(FbButton)`
  flex: 1;
`

const ModalBodyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const StepIndicators = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 6px;
  margin-bottom: 6px;
`

const StepDot = styled.div<{ $active: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => (props.$active ? '#000' : '#E5E7EB')};
`
