import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/UI/BaseDropdown/dropdown-base'
import { cn } from 'components/UI/cn'
import {
  ArrowDownNarrowWide,
  ArrowUpDown,
  ArrowUpNarrowWide,
  Triangle,
} from 'lucide-react'

type SortOption = 'overrides' | 'asc' | 'desc' | null

interface CuisineSortDropdownProps {
  selected: SortOption
  onSelect: (value: SortOption) => void
}

export function CuisineSortDropdown({
  selected,
  onSelect,
}: CuisineSortDropdownProps) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className={cn(
          'flex items-center gap-2 px-3 py-2 text-sm text-gray-700 rounded-3xl',
          'hover:bg-[#d6e4dd]',
          'border-transparent',
          'data-[state=open]:bg-[#d6e4dd]'
        )}
      >
        <ArrowUpDown className="h-4 w-4" />
        <span>Sort</span>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-48 p-1">
        <DropdownMenuItem
          onClick={() => onSelect('overrides')}
          className={cn(
            'flex items-center gap-2 px-2 py-1.5 text-sm rounded-md',
            'hover:bg-[#d6e4dd]',
            selected === 'overrides' && 'bg-[#d6e4dd]'
          )}
        >
          <Triangle className="h-4 w-4" />
          <span>Overrides</span>
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => onSelect('asc')}
          className={cn(
            'flex items-center gap-2 px-2 py-1.5 text-sm rounded-md',
            'hover:bg-[#d6e4dd]',
            selected === 'asc' && 'bg-[#d6e4dd]'
          )}
        >
          <ArrowUpNarrowWide className="h-4 w-4" />
          <span>Sort ascending</span>
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => onSelect('desc')}
          className={cn(
            'flex items-center gap-2 px-2 py-1.5 text-sm rounded-md',
            'hover:bg-[#d6e4dd]',
            selected === 'desc' && 'bg-[#d6e4dd]'
          )}
        >
          <ArrowDownNarrowWide className="h-4 w-4" />
          <span>Sort descending</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
