import { format } from 'date-fns'
import { Modal } from 'components/UI/Modal/Modal'
import { DataTable } from '../../components/DataTable'
import { DataTableContainer } from '../../components/DataTable/UI'
import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import apiService from '../../services/api'
import { RebateClaimDetail } from './tradespend_api'

interface RebateEnrollmentDetailsModalProps {
  open: boolean
  onClose: () => void
  enrollmentId: number
}

export function RebateEnrollmentDetailsModal({
  open,
  onClose,
  enrollmentId,
}: RebateEnrollmentDetailsModalProps) {
  const api = apiService()
  const { data: enrollmentDetails, isFetching } =
    api.useGetRebateEnrollmentDetails(enrollmentId)

  const claimColumns = useMemo<ColumnDef<RebateClaimDetail, any>[]>(
    () => [
      {
        accessorKey: 'created',
        header: 'Created',
        size: 150,
        cell: (info) => format(new Date(info.getValue()), 'MMM d, yyyy'),
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 150,
      },
      {
        accessorKey: 'num_units',
        header: 'Units',
        size: 120,
        cell: (info) => {
          const units = info.getValue()
          return units !== null
            ? units.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '-'
        },
      },
      {
        accessorKey: 'payout_amount',
        header: 'Payout Amount',
        size: 120,
        cell: (info) => {
          const amount = info.getValue()
          return amount !== null
            ? `$${amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
            : '-'
        },
      },
      {
        accessorKey: 'operator_invoice_url',
        header: 'Operator Invoice',
        size: 150,
        cell: (info) => {
          const url = info.getValue()
          return url ? (
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800"
            >
              View Invoice
            </a>
          ) : null
        },
      },
      {
        accessorKey: 'manufacturer_invoice_url',
        header: 'Manufacturer Invoice',
        size: 150,
        cell: (info) => {
          const url = info.getValue()
          return url ? (
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800"
            >
              View Invoice
            </a>
          ) : null
        },
      },
    ],
    []
  )

  return (
    <Modal
      size="2xl"
      open={open}
      onOpenChange={onClose}
      title={
        <p className="flex items-center gap-2">
          Enrollment Details
          {enrollmentDetails && (
            <span className="font-light">{enrollmentDetails.company_name}</span>
          )}
        </p>
      }
      childrenContainerClassName="max-h-[70vh] overflow-y-auto overflow-x-hidden max-w-full w-full"
      dialogFooterClassName="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2"
    >
      {enrollmentDetails && (
        <div className="space-y-4">
          <div className="grid grid-cols-3 gap-4">
            <div>
              <h3 className="text-lg font-semibold mb-2">
                Enrollment Information
              </h3>
              <p>
                <span className="font-bold">User:</span>{' '}
                {enrollmentDetails.tradespend_user_name}
              </p>
              <p>
                <span className="font-bold">Company:</span>{' '}
                {enrollmentDetails.company_name}
              </p>
              <p>
                <span className="font-bold">Enrolled On:</span>{' '}
                {format(new Date(enrollmentDetails.created), 'MMM d, yyyy')}
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-2">Claims Summary</h3>
              <p>
                <span className="font-bold">Total Claims:</span>{' '}
                {enrollmentDetails.total_claims}
              </p>
              <p>
                <span className="font-bold">Pending Validation:</span>{' '}
                {enrollmentDetails.pending_validation_claims}
              </p>
              <p>
                <span className="font-bold">Invalid Claims:</span>{' '}
                {enrollmentDetails.invalid_claims}
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-2">Payment Status</h3>
              <p>
                <span className="font-bold">Paid Claims:</span>{' '}
                {enrollmentDetails.paid_claims}
              </p>
              <p>
                <span className="font-bold">Unpaid Valid Claims:</span>{' '}
                {enrollmentDetails.unpaid_valid_claims}
              </p>
            </div>
          </div>

          <hr className="h-px my-4 bg-gray-300 border-0 dark:bg-gray-700" />

          <div>
            <h3 className="text-lg font-semibold mb-2">Claims History</h3>
            <DataTableContainer>
              <DataTable
                loading={isFetching}
                height={400}
                data={enrollmentDetails.claims}
                columns={claimColumns}
                tableKey="enrollment-claims"
              />
            </DataTableContainer>
          </div>
        </div>
      )}
    </Modal>
  )
}
