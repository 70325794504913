import { zodResolver } from '@hookform/resolvers/zod'
import to from 'await-to-js'
import Select from 'components/FormUtils/Select'
import lodash from 'lodash'
import { useEffect, useMemo } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { AiOutlineWarning } from 'react-icons/ai'
import { useImmer } from 'use-immer'
import {
  extensionZodValidator,
  phoneNumberZodValidor,
} from 'utils/zodValidators'
import { z } from 'zod'
import {
  ContactCompany,
  ContactCompanyCreateRequest,
} from '../../../models/companies'
import {
  ContactCreateRequest,
  ContactResponse,
  Department,
  DepartmentLabels,
  Seniority,
  SeniorityLabels,
} from '../../../models/contacts'
import apiService from '../../../services/api'
import { CONTACT_TITLES } from '../../../utils/constants'
import { handleFormError } from '../../../utils/handleFormError'
import { CompanyAutocomplete } from '../../CompanyAutocomplete'
import { CreatableTagSelector } from '../../CreatableTagSelector'
import { FbLink } from '../../FbUI/FbLink'
import { Checkbox } from '../../FormUtils/Checkbox'
import CreatableSelect, { SelectOption } from '../../FormUtils/CreatableSelect'
import { PhoneInput } from '../../FormUtils/PhoneInput'
import { TextareaInput } from '../../FormUtils/TextareaInput'
import { TextInput } from '../../FormUtils/TextInput'
import { Modal } from '../../UI/Modal/Modal'
import { FormSelect } from '../../UI/Select/FormSelect'
import * as S from './ContactModal.styles'

export interface ContactDefaultValues extends ContactCreateRequest {
  company_domain?: string
  consent_to_contact: boolean
}

export default function ContactModal({
  show,
  handleClose,
  onSubmitSuccess,
  submitApiAction,
  contactToEdit,
  confirmButtonText = 'Create',
  forCompany,
}: {
  show: boolean
  handleClose: () => void
  onSubmitSuccess?: () => void
  submitApiAction: (values: ContactDefaultValues) => Promise<unknown>
  contactToEdit?: ContactResponse
  confirmButtonText?: string
  forCompany?: ContactCompany
}) {
  const newContactFormShape = z
    .object({
      first_name: z.string().refine((v) => v, {
        message: 'Missing first name',
      }),
      last_name: z.string().refine((v) => v, {
        message: 'Missing last name',
      }),
      contact_company: z.object({
        name: z.string(),
        id: z.number(),
        domain: z.string().nullable().optional(),
      }),
      consent_to_contact: z.boolean().refine((v) => v, {
        message: 'Please confirm you are allowed to contact this person.',
      }),
      company_domain: forCompany
        ? z.any().optional() // Ignore domain for existing companies
        : z.union([
            z.literal(''),
            z
              .string()
              .trim()
              .refine(
                (v) => {
                  if (!v) return true
                  // Match regex for domain https://stackoverflow.com/questions/10306690/what-is-a-regular-expression-which-will-match-a-valid-domain-name-without-a-subd
                  const domainRegex = new RegExp(
                    /^(((?!-))(xn--|_)?[a-z0-9-]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9][a-z0-9]{0,60}|[a-z0-9-]{1,30}\.[a-z]{2,})$/
                  )
                  // Test if domain is valid and has at least one dot
                  return domainRegex.test(v) && v.includes('.')
                },
                {
                  message: 'Enter domain as: domain.com',
                }
              ),
          ]),
      category_id: z.number().optional(),
      sub_category_id: z.number().optional(),
      email_work: z
        .union([z.string().email(), z.literal('')])
        .nullable()
        .optional(),
      email_personal: z
        .union([z.string().email(), z.literal('')])
        .nullable()
        .optional(),
      email_generic: z
        .union([z.string().email(), z.literal('')])
        .nullable()
        .optional(),
      url: z.union([
        z.literal(''),
        z.string().trim().url('Enter url as: https://www.domain.com'),
      ]),
      linkedin_url: z
        .union([
          z.literal(''),
          z.null(),
          z
            .string()
            .trim()
            .url('URL should start with: https://www.linkedin.com/')
            .refine(
              (v) => {
                if (!v) {
                  return true
                }

                if (!v.startsWith('https://www.linkedin.com/')) {
                  return false
                }

                return true
              },
              { message: 'URL should start with: https://www.linkedin.com/' }
            ),
        ])
        .nullable()
        .optional(),
      notes: z.string().optional(),
      title: z
        .string()
        .optional()
        .nullable()
        .transform((v) => v || ''),
      tags: z.array(z.number()).optional(),
      extension: extensionZodValidator,
      phone_number: phoneNumberZodValidor,
      seniority: z.nativeEnum(Seniority).nullable().optional(),
      department: z.nativeEnum(Department).nullable().optional(),
    })
    .partial()
    .superRefine((v, ctx) => {
      if (!v) return

      const isNewCompany = v.contact_company?.id === -1

      if (isNewCompany) {
        if (!v.company_domain) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Domain is required to create a new company',
            path: ['company_domain'],
          })
        }
        if (!v.category_id) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Company category is required for new companies',
            path: ['category_id'],
          })
        }
        if (!v.sub_category_id) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Company type is required for new companies',
            path: ['sub_category_id'],
          })
        }
      }

      // Ensure at least one email is provided
      if (!v.email_work && !v.email_personal && !v.email_generic) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'At least one email address is required',
          path: ['email_work'],
        })
      }
    })

  const api = apiService()
  const { data: categories } = api.useGetCompanyCategories()
  const { data: tagsOptions } = api.useGetTagsOptions(false, 'contacts')
  const companyCategories = useMemo(
    () => categories?.company_categories || [],
    [categories]
  )

  const [warnings, setWarnings] = useImmer<{
    email_work?: string
    email_personal?: string
    email_generic?: string
    company_domain?: string
  }>({})

  const defaultValues = (): ContactDefaultValues => {
    if (!contactToEdit) {
      return {
        first_name: '',
        last_name: '',
        consent_to_contact: false,
        url: '',
        linkedin_url: '',
        notes: '',
        title: '',
        seniority: undefined,
        department: undefined,
        tags: [],
        contact_company: {} as ContactDefaultValues['contact_company'],
        company_domain: '',
        email_work: '',
        email_personal: '',
        email_generic: '',
        phone_number: '',
        extension: '',
      }
    }

    return {
      ...contactToEdit,
      contact_company: {} as ContactCompanyCreateRequest,
      company_domain: contactToEdit?.contact_company?.domain,
      tags: contactToEdit?.tags?.map((tag) => tag.id),
    } as ContactDefaultValues
  }

  const initialCompany = useMemo(() => {
    if (!companyCategories) return undefined
    const contact_company = forCompany ?? contactToEdit?.contact_company
    if (!contact_company) return undefined
    const category = companyCategories.find(
      (category) => category.name === contact_company?.category
    )
    const subCategory = category?.sub_categories.find(
      (subCategory) => subCategory.name === contact_company?.sub_category?.name
    )

    const clonedCompany = lodash.cloneDeep(contact_company)

    return {
      ...clonedCompany,
      category: category?.id,
      sub_category: subCategory?.id,
    } as ContactCompany
  }, [contactToEdit, forCompany, companyCategories])

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
    setValue,
    setError,
    trigger,
    reset,
    watch,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
    resolver: zodResolver(newContactFormShape),
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldUnregister: false,
  })

  useEffect(() => {
    // Validate all fields on mount if updating
    if (contactToEdit) {
      trigger()
    }
  }, [])

  useEffect(() => {
    // Fill in form with existing contact data
    if (!initialCompany) return
    setValue('contact_company', initialCompany as ContactCompanyCreateRequest, {
      shouldValidate: false,
      shouldDirty: false,
      shouldTouch: false,
    })
    setValue('company_domain', initialCompany?.domain ?? '', {
      shouldValidate: false,
      shouldDirty: false,
      shouldTouch: false,
    })
    setValue(
      'category_id',
      // eslint-disable-next-line prettier/prettier
      initialCompany?.category ? parseInt(initialCompany.category) : undefined,
      {
        shouldValidate: false,
        shouldDirty: false,
        shouldTouch: false,
      }
    )
    setValue(
      'sub_category_id',
      initialCompany?.sub_category
        ? initialCompany.sub_category?.id
        : undefined,
      {
        shouldValidate: false,
        shouldDirty: false,
        shouldTouch: false,
      }
    )
  }, [initialCompany])

  const _handleClose = () => {
    reset()
    handleClose()
  }

  const watchCompany = watch('contact_company')

  const isNewCompany = useMemo(() => {
    const contact_company = watchCompany
    // If contact_company is not defined, or it's an empty object, it's not a new contact_company
    if (!contact_company || Object.keys(contact_company).length === 0)
      return false
    return contact_company?.id === -1
  }, [watchCompany])

  useEffect(() => {
    const contact_company = watchCompany
    if (contact_company?.domain) {
      setValue('company_domain', contact_company?.domain)
      trigger('company_domain')
    } else if (!contact_company) {
      setValue('company_domain', '')
    }
    if (contact_company?.category) {
      setValue('category_id', contact_company.category)
    }
    if (contact_company?.sub_category) {
      setValue('sub_category_id', contact_company.sub_category)
    }
  }, [watchCompany])

  const watchCompanyAndDomain = useWatch({
    control,
    name: ['contact_company', 'company_domain'],
  })

  useEffect(() => {
    const existingCompanyWithNoDomainWarning = (
      contact_company: ContactCompany,
      company_domain?: string
    ) => {
      const isNewCompany = !(contact_company?.id || contact_company?.chain)
      if (!isNewCompany && !company_domain && !forCompany) {
        setWarnings((draft) => {
          draft.company_domain =
            'Missing domain. Add a domain to ensure accuracy across systems.'
        })
        return true
      }
      return false
    }

    const companyDomainBeingOverwrittenWarning = (
      contact_company: ContactCompany,
      company_domain?: string
    ) => {
      const bothDomainsDefined = contact_company?.domain && company_domain
      if (bothDomainsDefined) {
        if (contact_company.domain !== company_domain) {
          setWarnings((draft) => {
            draft.company_domain =
              'Domain does not match selected company. Match domains to ensure accuracy across systems, or continue to override.'
          })
          return true
        }
      }
      return false
    }

    if (errors.company_domain) return

    const contact_company = watchCompanyAndDomain[0] as ContactCompany
    const company_domain = watchCompanyAndDomain[1]

    if (companyDomainBeingOverwrittenWarning(contact_company, company_domain))
      return

    if (existingCompanyWithNoDomainWarning(contact_company, company_domain))
      return

    setWarnings((draft) => {
      delete draft.company_domain
    })
  }, [watchCompanyAndDomain, errors, forCompany])

  const categoryOptions =
    companyCategories?.map((category) => ({
      label: category.name,
      value: category.id,
    })) || []

  const watchCompanyCategory = watch('category_id')

  const typeOptions = useMemo(() => {
    const category = watchCompanyCategory
    if (!category) return []

    const categoryObj = companyCategories?.find((c) => c.id === category)
    if (!categoryObj) return []

    return categoryObj.sub_categories.map(
      (subCategory) =>
        ({
          label: subCategory.name,
          value: subCategory.id,
        }) as SelectOption
    )
  }, [watchCompanyCategory])

  const createNewCategory = (category: string): Promise<SelectOption> => {
    return new Promise((resolve) => {
      resolve({
        label: category,
        value: category,
      })
    })
  }

  const contactTitles = useMemo(() => {
    const defaultTitles = [...CONTACT_TITLES]
    if (defaultTitles.includes(contactToEdit?.title as string)) {
      return defaultTitles
    }
    return [...defaultTitles, contactToEdit?.title as string]
  }, [contactToEdit?.title])

  const onSubmit = handleSubmit(
    async (values) => {
      // Transform only the email fields from empty strings to null
      const transformedEmails = {
        email_work: values.email_work === '' ? null : values.email_work,
        email_personal:
          values.email_personal === '' ? null : values.email_personal,
        email_generic:
          values.email_generic === '' ? null : values.email_generic,
      }

      // Merge transformed emails with the rest of the form values
      const transformedValues: ContactDefaultValues = {
        ...values,
        ...transformedEmails,
      }

      const [err] = await to(submitApiAction(transformedValues))
      if (err) {
        console.warn(err)
        handleFormError(err, setError)
        return
      }

      _handleClose()
      onSubmitSuccess?.()
    },
    (err) => console.warn(err)
  )

  return (
    <Modal
      open={show}
      onOpenChange={(open) => !open && _handleClose()}
      title={contactToEdit ? 'Edit Contact' : 'New Contact'}
      description={
        <>
          Contacts match to companies based on their domain. See{' '}
          <FbLink to="/terms-of-use" target="_blank">
            Terms of Use
          </FbLink>
          .
        </>
      }
      loading={isSubmitting}
      onAccept={onSubmit}
      acceptButtonText={confirmButtonText}
      size={'lg'}
      blockAccept={!isValid}
      footerChildren={
        <Checkbox control={control} name="consent_to_contact">
          Do you have consent to contact this person?
        </Checkbox>
      }
    >
      <div className={'flex items-start w-full gap-2'}>
        <TextInput
          label={'First Name'}
          control={control}
          name="first_name"
          placeholder="First Name"
        />
        <TextInput
          control={control}
          name="last_name"
          placeholder="Last Name"
          label={'Last Name'}
        />
      </div>
      <div className={'flex items-start w-full gap-2'}>
        <CompanyAutocomplete
          disabled={!!forCompany}
          control={control}
          name="contact_company"
          placeholder="Start typing company name"
          className={'mt-[10px]'}
          height={'33px'}
          initialCompany={initialCompany}
        />
        <div className={'flex-1'}>
          <TextInput
            disabled={!!forCompany}
            label={'Company domain'}
            control={control}
            name="company_domain"
            placeholder="company.com"
            className={`h-[33px] ${warnings.company_domain ? 'input-warning' : ''}`}
          />
        </div>
        {(warnings.email_work ||
          warnings.email_personal ||
          warnings.email_generic) && (
          <S.Warning>
            <AiOutlineWarning size={20} />
            <div>
              {warnings.email_work ||
                warnings.email_personal ||
                warnings.email_generic}
            </div>
          </S.Warning>
        )}
      </div>
      {warnings.company_domain && (
        <S.Warning>
          <AiOutlineWarning size={20} />
          <div>{warnings.company_domain}</div>
        </S.Warning>
      )}
      {isNewCompany && (
        <div>
          <div className="mt-3 mb-1 text-base font-medium text-gray-800 select-none">
            Company Category and Type
          </div>
          <div className="flex items-start gap-2">
            <div className="flex-1 w-full">
              <FormSelect
                control={control}
                name="category_id"
                buttonLabel="Company Category"
                items={categoryOptions}
                buttonClassName="w-full"
              />
            </div>
            <div className="flex-1 w-full">
              <FormSelect
                control={control}
                name="sub_category_id"
                buttonLabel="Company Type"
                items={typeOptions}
                buttonClassName="w-full"
              />
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col gap-2">
        <div className="flex items-start gap-2">
          <TextInput
            label="Work Email"
            control={control}
            name="email_work"
            placeholder="work@company.com"
            className={warnings.email_work ? 'input-warning' : undefined}
          />
          <TextInput
            label="Personal Email"
            control={control}
            name="email_personal"
            placeholder="personal@example.com"
            className={warnings.email_personal ? 'input-warning' : undefined}
          />
          <TextInput
            label="Other Email"
            control={control}
            name="email_generic"
            placeholder="info@company.com"
            className={warnings.email_generic ? 'input-warning' : undefined}
          />
        </div>
        {(warnings.email_work ||
          warnings.email_personal ||
          warnings.email_generic) && (
          <S.Warning>
            <AiOutlineWarning size={20} />
            <div>
              {warnings.email_work ||
                warnings.email_personal ||
                warnings.email_generic}
            </div>
          </S.Warning>
        )}
      </div>

      <PhoneInput
        name="phone_number"
        control={control}
        label="Phone number"
        extensionName="extension"
        optional
      />
      <div className={'flex items-start gap-2'}>
        <TextInput
          control={control}
          name="url"
          label="Website"
          placeholder="https://www.example.com/"
          optional
        />
        <TextInput
          control={control}
          name="linkedin_url"
          label="LinkedIn"
          placeholder="https://www.linkedin.com/"
          optional
        />
      </div>
      <div>
        <div className="text-lg mt-3 font-medium text-gray-800">
          Contact Type
        </div>
        <div className={'flex items-start gap-2'}>
          <CreatableSelect
            label={'Title'}
            optional
            control={control}
            name="title"
            placeholder="Title"
            options={contactTitles.map((title) => ({
              label: title,
              value: title,
            }))}
            onCreateOption={createNewCategory}
            className={'flex-1 mt-0'}
          />
          <CreatableTagSelector
            className={'flex-1 mt-0'}
            name="tags"
            control={control}
            placeholder="Labels"
            options={tagsOptions}
            setError={setError}
            tagParent="contacts"
            label={'Labels'}
            optional
          />
        </div>
      </div>
      {!!contactToEdit && (
        <>
          <div className={'flex items-start gap-2'}>
            <Select
              className={'flex-1'}
              control={control}
              name="seniority"
              label="Seniority"
              placeholder="Select Seniority"
              options={[
                { value: null, label: 'Select Seniority' },
                ...Object.entries(SeniorityLabels).map(([value, label]) => ({
                  value: parseInt(value),
                  label,
                })),
              ]}
              optional
            />
            <Select
              className={'flex-1'}
              control={control}
              name="department"
              label="Department"
              placeholder="Select Department"
              options={[
                { value: null, label: 'Select Department' },
                ...Object.entries(DepartmentLabels).map(([value, label]) => ({
                  value: parseInt(value),
                  label,
                })),
              ]}
              optional
            />
          </div>
        </>
      )}
      <TextareaInput
        control={control}
        name="notes"
        label="Notes"
        placeholder="Notes"
        optional
      />
    </Modal>
  )
}
